<div class="facility-information">
  <p class="font-24">{{ "applications.APPLICATION_OPTIONS" | translate }}</p>
  <!-- <p class="font-14 red-text">
    {{ "applications.CHOOSE_ONE_CATEGORY" | translate }}
  </p> -->
  <form [formGroup]="form">
    <div class="form-control-div">
      <!-- <label id="example-radio-group-label" class="font-18"
        >{{ "facility.FACILITY_TYPE_TEXT" | translate }}*</label
      > -->
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="status"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let type of applicationTypeOptions"
          [value]="type.Name"
        >
          {{ type.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <fieldset *ngIf="applicationType === 'Sustainability'">
      <!-- <div class="form-control-div">
        <label class="font-18"
          >{{ "applications.CCDF_BONUS_QUESTION" | translate }}*</label
        >
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="ccdf_bonus_question"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of yesNoOptions"
            [value]="type.name"
          >
            {{ type.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div> -->
      <div
        *ngIf="form.value.voucher_participant === 'Yes'"
        class="form-control-div mb-1"
      >
        <mat-checkbox class="font-18" formControlName="ccdf_checkbox"
          >{{ "applications.CCDF_CHECKBOX" | translate }}*</mat-checkbox
        >
      </div>
      <!-- <div class="form-control-div">
        <label class="font-18"
          >{{
            "applications.BETTER_BEGINNINGS_BONUS_QUESTION" | translate
          }}*</label
        >
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="better_beginnings_bonus_question"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of betterBeginningsBonusQOptions"
            [value]="type.name"
          >
            {{ type.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div> -->
    </fieldset>
  </form>
  <!-- <span class="button-div mt-2">
    <button
      class="btn btn-success"
      (click)="saveAndNext()"
      [disabled]="isFormInvalid()"
    >
      {{ "common.SAVE_AND_CONTINUE" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      routerLink="/"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->
</div>
