import {
  Component,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { FieldListItem, FormActionData } from "src/app/models/form-action-data";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { PopupModel } from "src/app/modules/dashboards/models/popup";
import { CommonService } from "src/app/modules/dashboards/services";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { Constants } from "src/app/shared/constants";

@Component({
  selector: "app-create-ticket",
  templateUrl: "./create-ticket.component.html",
  styleUrls: ["./create-ticket.component.css"],
  providers: [CommonService]
})
export class CreateTicketComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal: any;
  @Input("successFn") successFn: Function = function () {};
  @Input("template") template: TemplateRef<any>;
  @Input("appId") appId: number = -1;

  projectSpecificData: any = this.projectSpecificService.getProjectSpecificData();
  ticketForm: FormGroup;
  modalConfig: PopupModel;
  ticketTypes: Array<any> = [];
  applications: Array<any> = [];
  applicantType: string;

  constructor(
    private formBuilder: FormBuilder,
    private ignatiusService: IgnatiusService,
    private toastr: ToastrService,
    private ngbModal: NgbModal,
    private common: CommonService,
    private projectSpecificService: ProjectSpecificService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.applicantType = this.getApplicantType();
    this.initForm();
    this.fetch();
  }

  /**
   * Fetch data from backend
   */
  fetch = () => {
    // fetch dropdowns
    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.commThreadsData.TicketTypeMCID.toString()
      )
      .subscribe((data) => {
        this.ticketTypes = data;
      });

    if (this.appId === -1)
      // fetch applications if app id doesnt exists
      this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.applicationsData.TableId,
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Key: this.projectSpecificData.applicationsData.ApplicantType,
                  },
                  OperationType: "is equal",
                  Value: this.applicantType,
                },
              ],
            },
          ],
        })
        .subscribe((data) => {
          this.applications = data;
        });
  };

  /**
   * Init form
   */
  initForm = () => {
    this.ticketForm = this.formBuilder.group({
      subject: [null, Validators.required],
      details: [null, Validators.required],
      file_doc_1: [null],
      file_doc_1_url: [null],
      file_doc_2: [null],
      file_doc_2_url: [null],
      file_doc_3: [null],
      file_doc_3_url: [null],
      file_doc_4: [null],
      file_doc_4_url: [null],
      file_doc_5: [null],
      file_doc_5_url: [null],
      ticket_type: ["", Validators.required],
      related_applications: ["", Validators.required],
    });

    if (this.appId !== -1)
      this.ticketForm.patchValue({
        related_applications: this.appId,
      });
  };

  /**
   * Load file
   */
  loadFile = async (file: any, nameField: string, urlField: string) => {
    const { filesize, filestr, filename } = await this.common.getFileData(file);

    if (filesize > Constants.TWENTY_MB_IN_BYTE) {
      this.toastr.error("Maximum 20MB file size exceeded", "Error");
      this.ticketForm.patchValue({
        [nameField]: null,
        [urlField]: null,
      });
      return;
    }

    this.ticketForm.patchValue({
      [nameField]: filename,
      [urlField]: filestr,
    });
  };

  /**
   * Submit helpdesk
   */
  submitTicket = async () => {
    if (this.ticketForm.invalid) return;
    try {
      this.modalConfig.busy = true;
      const tableId = this.projectSpecificData.commThreadsData.TableId;

      const recordFAD = new FormActionData(
        "",
        tableId,
        null,
        new Array<FieldListItem>()
      );

      for (const key in this.ticketForm.value) {
        if (
          key.includes("file_doc_") &&
          !key.includes("_url") &&
          this.ticketForm.value[key]
        ) {
          recordFAD.fieldsList.push(
            new FieldListItem(
              key,
              this.ticketForm.value[key],
              this.ticketForm.value[`${key}_url`]
            )
          );
        } else if (!key.includes("file_doc_") && this.ticketForm.value[key]) {
          recordFAD.fieldsList.push(
            new FieldListItem(key, this.ticketForm.value[key], "")
          );
        }
      }

      await this.ignatiusService.postData(recordFAD).toPromise();
      this.toastr.success(this.translate.instant("MESSAGES.CREATE_TICKET_SUCCESS"), this.translate.instant("applications.SUCCESS"));
      await this.successFn();
      this.initForm();
      this.ngbModal.dismissAll();
    } catch (error) {
      this.toastr.error(this.translate.instant("MESSAGES.CREATE_TICKET_ERROR"), this.translate.instant("applications.ERROR"));
    }
    this.modalConfig.busy = false;
  };

  /**
   * Close modal
   */
  close = () => {
    this.ngbModal.dismissAll();
  };

  /**
   * @returns applicant type
   */
  getApplicantType = () => {
    const permissionTypeData = this.projectSpecificService.getPermissionType();
    if (JSON.stringify(permissionTypeData).toString().includes("Landlord"))
      return "Landlord";
    return "Renter";
  };

  /**
   * @returns display name on the basis of applicant type
   */
  getDisplayName = (application: any) => {
    if (this.applicantType === "Renter")
      return `${application.contact_first_name} ${application.contact_last_name}`;
    return `${application.business_name}`;
  };

  /**
   * Click listener
   */
  @HostListener("click", ["$event"]) click(event) {
    event.preventDefault();
    this.modalConfig = new PopupModel();
    this.modalConfig.settings.windowClass = "create-ticket";
    this.ngbModal.open(this.modal, this.modalConfig.settings);
  }
}
