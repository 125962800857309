<div class="child-count-step">
  <p class="font-24">
    {{ "facility.ENRICHMENT_GRANT_CAL" | translate }}
  </p>
  <p class="font-18">
    {{ "facility.ENRICHMENT_GRANT_CAL_TEXT" | translate }}
  </p>
  <!-- <p class="font-14 red-text">
    {{ "applications.PRE_POPULATED_TEXT" | translate }}
  </p> -->
  <form [formGroup]="form">
    <div class="form-control-div">
      <label class="font-18">{{ "applications.FACILITY_NAME" | translate }}</label>
      <input
        type="text"
        class="w-50 form-control"
        formControlName="facility_name"
        disabled
      />
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.TYPE" | translate }}</label>
      <input
        type="text"
        class="w-50 form-control"
        formControlName="facility_type"
        disabled=
      />
    </div>
    <hr />
    <div class="form-control-div">
      <label class="font-18">{{ "facility.TOTAL_ENRICHMENT_GRANT_AMOUNT" | translate }}</label>
      <input
        type="text"
        class="w-50 form-control"
        formControlName="total_enrichment_grant_amount"
        disabled
        [value]="form?.get('total_enrichment_grant_amount')?.value | currency:'USD'"
      />
    </div>
  </form>
  <!-- <span class="button-div mt-2">
    <button *ngIf="!isApplicationSubmitted()" class="btn btn-success" [disabled]="form.invalid" (click)="goToNextStep()">
      {{ "common.SAVE_AND_CONTINUE" | translate }}
    </button>
    <button *ngIf="isApplicationSubmitted()" class="btn btn-success"  (click)="goToNextStep()">
      {{ "actions.NEXT" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      (click)="goBack()"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->
</div>
