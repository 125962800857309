import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { SaveConfirmationComponent } from "src/app/modules/dashboards/components/save-confirmation/save-confirmation.component";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { ProjectSpecificService } from "src/app/services/project-specific.service";

let fieldNameWithCapacityValidation = [
  "infant_count",
  "toddler_count",
  "preschool_count",
  "school_aged_count",
  "infant_precovid",
  "toddler_precovid",
  "preschool_precovid",
  "school_aged_precovid",
];
@Component({
  selector: "app-facility-child-count",
  templateUrl: "./facility-child-count.component.html",
  styleUrls: ["./facility-child-count.component.css"],
})
export class FacilityChildCountComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Input("facilityDetails") facilityDetails: any = {};
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();

  form: FormGroup = this.fb.group({
    infant_count: ["", [Validators.required, Validators.min(0)]],
    toddler_count: ["", [Validators.required, Validators.min(0)]],
    preschool_count: ["", [Validators.required, Validators.min(0)]],
    school_aged_count: ["", [Validators.required, Validators.min(0)]],
    early_head_start: ["", [Validators.required, Validators.min(0)]],
    head_start: ["", [Validators.required, Validators.min(0)]],
    arkansas_better_chance: ["", [Validators.required, Validators.min(0)]],
    medicaid_eidt: ["", [Validators.required, Validators.min(0)]],
    infant_precovid: ["", [Validators.required, Validators.min(0)]],
    toddler_precovid: ["", [Validators.required, Validators.min(0)]],
    preschool_precovid: ["", [Validators.required, Validators.min(0)]],
    school_aged_precovid: ["", [Validators.required, Validators.min(0)]],
  });

  countErrorMessage = "";

  constructor(
    private fb: FormBuilder,
    private ignatiusService: IgnatiusService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private router: Router,
    private modal: NgbModal,
    private projectSpecificService: ProjectSpecificService
  ) {}

  ngOnInit() {}

  goBack = () => {
    this.back.emit();
  };

  ngOnChanges(changes: SimpleChanges) {
    this.facilityDetails = changes.facilityDetails.currentValue;
    if (this.facilityDetails) {
      this.form.setValue({
        infant_count: this.facilityDetails.infant_count,
        toddler_count: this.facilityDetails.toddler_count,
        preschool_count: this.facilityDetails.preschool_count,
        school_aged_count: this.facilityDetails.school_aged_count,
        early_head_start: this.facilityDetails.early_head_start,
        head_start: this.facilityDetails.head_start,
        arkansas_better_chance: this.facilityDetails.arkansas_better_chance,
        medicaid_eidt: this.facilityDetails.medicaid_eidt,
        infant_precovid: this.facilityDetails.infant_precovid,
        toddler_precovid: this.facilityDetails.toddler_precovid,
        preschool_precovid: this.facilityDetails.preschool_precovid,
        school_aged_precovid: this.facilityDetails.school_aged_precovid,
      });

      if (this.isFacilitySaved()) {
        this.disableInputField();
      }

      if (this.getFacilityType() === "Out of School Time") {
        this.disableFieldForOutOfSchool();
      }
    }
  }

  getFacilityType = (): string => {
    if (this.facilityDetails && this.facilityDetails.facility_type)
      return this.facilityDetails.facility_type;
    else return "";
  };

  setCapacityValidator = () => {
    for (let i = 0; i < fieldNameWithCapacityValidation.length; i = i + 1) {
      this.form.controls[fieldNameWithCapacityValidation[i]].setValidators([
        Validators.max(this.facilityDetails.pp_facility_capacity),
        Validators.min(0),
      ]);
    }
  };

  disableFieldForOutOfSchool = () => {
    this.form.controls["infant_count"].disable();
    this.form.controls["toddler_count"].disable();
    this.form.controls["preschool_count"].disable();
    this.form.controls["infant_precovid"].disable();
    this.form.controls["toddler_precovid"].disable();
    this.form.controls["preschool_precovid"].disable();
  };

  disableInputField = () => {
    this.form.controls["infant_count"].disable();
    this.form.controls["toddler_count"].disable();
    this.form.controls["preschool_count"].disable();
    this.form.controls["school_aged_count"].disable();
    this.form.controls["early_head_start"].disable();
    this.form.controls["head_start"].disable();
    this.form.controls["arkansas_better_chance"].disable();
    this.form.controls["medicaid_eidt"].disable();
    this.form.controls["infant_precovid"].disable();
    this.form.controls["toddler_precovid"].disable();
    this.form.controls["preschool_precovid"].disable();
    this.form.controls["school_aged_precovid"].disable();
  };

  openConfirmationPopUp = (): void => {
    if (this.isAllCountValid()) {
      const modal = this.modal.open(SaveConfirmationComponent, {
        windowClass: "modal-custom",
        centered: true,
      });

      modal.componentInstance.confirmationTextKey =
        "facility.FACILITY_SUBMIT_CONFIRMATION_TEXT";
      modal.componentInstance.optionSelected.subscribe((isSubmit) => {
        this.saveFacility(isSubmit);
      });
    }
  };

  isAllCountValid = (): boolean => {
    switch (this.getFacilityType()) {
      case "Child Care Center":
        return this.isChildCareCenterCountValidaton();
      case "Out of School Time":
        return this.isOutOfSchoolValidaton();
      case "Licensed Child Care Family Home":
        return this.isLicensedChildCareFamilyHome();
      case "Registered Child Care Family Home":
        return this.isRegisteredChildCareFamilyHome();
      default:
        return true;
    }
  };

  saveFacility = (isFinalSubmit: boolean): void => {
    this.spinner.show();
    let payload = this.getPayload(isFinalSubmit);
    let redId = new Where(this.facilityDetails.id);
    let reqBody = new FormActionData(
      "",
      this.projectSpecificData.facility.TableId,
      redId,
      payload
    );
    this.ignatiusService.putData(reqBody).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.router.navigate(["/"]);
        this.modal.dismissAll();
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error(
          this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
          this.translate.instant("applications.ERROR")
        );
      }
    );
  };

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  getPayload = (isFinalSubmit) => {
    let formData = { ...this.form.value };
    let payload = [];

    for (let i in formData) {
      payload.push(new FieldListItem(i, formData[i], ""));
    }

    if (isFinalSubmit)
      payload.push(new FieldListItem("facility_status", "Saved", ""));
    if (!isFinalSubmit)
      payload.push(new FieldListItem("facility_status", "Open", ""));

    return payload;
  };

  isChildCareCenterCountValidaton = (): boolean => {
    const formValues = { ...this.form.value };

    if (
      this.numberToString(formValues.infant_count) +
        this.numberToString(formValues.toddler_count) >
      this.numberToString(this.facilityDetails.pp_infant_toddler)
    ) {
      this.countErrorMessage =
        "Count entered exceeds the registered count for the facility";
      return false;
    } else if (
      this.numberToString(formValues.preschool_count) >
      this.numberToString(this.facilityDetails.pp_preschool)
    ) {
      this.countErrorMessage =
        "Count entered exceeds the registered count for the facility";
      return false;
    } else if (
      this.numberToString(formValues.school_aged_count) >
      this.numberToString(this.facilityDetails.pp_school_age) +
        this.numberToString(this.facilityDetails.pp_preschool) -
        this.numberToString(formValues.preschool_count)
    ) {
      this.countErrorMessage =
        "Count entered exceeds the registered count for the facility";
      return false;
    } else {
      this.countErrorMessage = "";
      return true;
    }
  };

  isOutOfSchoolValidaton = (): boolean => {
    const formValues = { ...this.form.value };

    if (
      this.numberToString(formValues.school_aged_count) >
      this.numberToString(this.facilityDetails.pp_facility_capacity)
    ) {
      this.countErrorMessage =
        "Count entered exceeds the registered count for the facility";
      return false;
    } else {
      this.countErrorMessage = "";
      return true;
    }
  };

  isLicensedChildCareFamilyHome = (): boolean => {
    const formValues = { ...this.form.value };
    if (
      this.numberToString(formValues.infant_count) +
        this.numberToString(formValues.toddler_count) +
        this.numberToString(formValues.preschool_count) +
        this.numberToString(formValues.school_aged_count) >
      this.numberToString(this.facilityDetails.pp_facility_capacity)
    ) {
      this.countErrorMessage =
        "Count entered exceeds the registered count for the facility";
      return false;
    } else {
      this.countErrorMessage = "";
      return true;
    }
  };

  isRegisteredChildCareFamilyHome = (): boolean => {
    const formValues = { ...this.form.value };
    if (
      this.numberToString(formValues.infant_count) +
        this.numberToString(formValues.toddler_count) +
        this.numberToString(formValues.preschool_count) +
        this.numberToString(formValues.school_aged_count) >
      this.numberToString(this.facilityDetails.pp_facility_capacity)
    ) {
      this.countErrorMessage =
        "Count entered exceeds the registered count for the facility";
      return false;
    } else {
      this.countErrorMessage = "";
      return true;
    }
  };

  numberToString = (value: number | string): number => {
    if (typeof value === "number") return value;
    else if (typeof value === "string") {
      return Number(value);
    }
  };
}
