<div class="facility-information">
  <p class="font-24">{{ "facility.INFORMATION" | translate }}</p>
  <p class="font-14 red-text">
    {{ "common.REQUIRED_FIELD_MESSAGE" | translate }}
  </p>
  <form [formGroup]="form">
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "applications.OWNER_OPERATOR_RACE" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="race"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let race of raceOptions"
          [value]="race.Name"
        >
          {{ race.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "applications.OWNER_OPERATOR_ETHNICITY" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="ethnicity"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let ethnicity of ethnicityOptions"
          [value]="ethnicity.Name"
          [disabled]="isFacilitySaved()"
        >
          {{ ethnicity.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "applications.OWNER_OPERATOR_GENDER" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="gender"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let gender of genderOptions"
          [value]="gender.Name"
        >
          {{ gender.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "facility.CURRENT_STATUS" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="current_status"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let currentStatus of currentStatusOptions"
          [value]="currentStatus.Name"
        >
          {{ currentStatus.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "facility.SPECIAL_NEEDS" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="special_needs"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let specialNeed of specialNeedsOptions"
          [value]="specialNeed.Name"
        >
          {{ specialNeed.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "facility.ANNUAL_BUDGET" | translate }}*</label
      >
      <input
        type="text"
        class="w-25 form-control"
        formControlName="operating_budget"
        prefix="$ "
        mask="separator.2"
        thousandSeparator=","
        (input)="maxOperatingBudget()"
      />
      <label class="text-danger font-14" *ngIf="maxBudgetError.length > 0">{{
        "facility.OPERATING_BUDGET_MAX" | translate
      }}</label>
    </div>
  </form>
  <p class="red-text" *ngIf="!isAnnualOperatingBudgetDecimalValid()">
    {{ "common.ONLY_TWO_DECIMAL_PLACE_ALLOWED" | translate }}
  </p>
  <!-- <span class="button-div mt-2">
    <button
      class="btn btn-success"
      (click)="saveAndNext()"
      [disabled]="
        (!this.isFacilitySaved() &&
          (!isAnnualOperatingBudgetDecimalValid() || form.invalid)) ||
        maxBudgetError.length > 0
      "
    >
      {{ "common.SAVE_AND_CONTINUE" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      (click)="goBack()"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->
</div>
