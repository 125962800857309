import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

export interface HasUnsavedChanges {
  hasUnsavedChanges(): boolean;
}

@Injectable()
export class HasUnsavedChangesGuard implements CanDeactivate<HasUnsavedChanges> {
  canDeactivate(component: HasUnsavedChanges): boolean {
    if (component.hasUnsavedChanges && component.hasUnsavedChanges()) {
      return confirm('Are you sure you want to discard all changes?');
    }
    return true;
  }
}
