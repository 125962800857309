export class User {
    constructor(
        public access_token: string,
        public expires: Date,
        public userName: string,
        public fullName: string,
        public refreshToken: string
    ){}

    get isTokenValid():boolean {
        return new Date() < this.expires;
    }
}
