<div class="card pt-3" #home>
  <mat-tab-group
    style="margin-top: 50px"
    (selectedTabChange)="tabChange($event)"
    [selectedIndex]="currentTab"
  >
    <mat-tab [disabled]="true">
      <ng-template mat-tab-label>
        <div class="d-flex align-items-center justify-content-start">
          <div class="tab-label-container">
            <span>
              <ng-container *ngIf="applicationData.id"
                ><span class="font-18">1</span></ng-container
              >
            </span>
          </div>
          <span class="ml-2 font-16">{{
            "applications.APPLICATION_OPTIONS" | translate
          }}</span>
        </div>
      </ng-template>
      <div class="m-3">
        <ng-container>
          <app-application-options
            [facilityDetails]="facilityDetails[0]"
            (next)="nextStep($event)"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
          ></app-application-options>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab [disabled]="true">
      <ng-template mat-tab-label>
        <div class="d-flex align-items-center">
          <div class="tab-label-container">
            <span>
              <ng-container *ngIf="applicationData.id">
                <span class="font-18">2</span></ng-container
              >
            </span>
          </div>
          
          <span class="ml-2 font-16" *ngIf="applicationType !== 'Enrichment Grant'">
            {{ "applications.GRANT_CALCULATOR" | translate }}
          </span>
          <span class="ml-2 font-16" *ngIf="applicationType === 'Enrichment Grant'">
            {{ "applications.GRANT_INFO" | translate }}</span
          >
        </div>
      </ng-template>
      <div class="m-3">
        <ng-container *ngIf="applicationType === 'Quality Improvement'">
          <app-quality-improvement-calculator
            [facilityDetails]="facilityDetails[0]"
            (next)="nextStep()"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
          ></app-quality-improvement-calculator>
        </ng-container>
        <ng-container *ngIf="applicationType === 'Operational Payments'">
          <app-operational-payment-calculator
            [facilityDetails]="facilityDetails[0]"
            (next)="nextStep()"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
          ></app-operational-payment-calculator>
        </ng-container>
        <ng-container *ngIf="applicationType === 'Sustainability'">
          <app-sustainability-calculator
            [facilityDetails]="facilityDetails[0]"
            (next)="nextStep()"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
          ></app-sustainability-calculator>
        </ng-container>
        <ng-container *ngIf="applicationType === 'Child Care Expansion'">
          <app-child-care-expansion-calculator
            [facilityDetails]="facilityDetails[0]"
            (next)="nextStep($event)"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
          ></app-child-care-expansion-calculator>
        </ng-container>
        <ng-container *ngIf="applicationType === 'Startup Grant'">
          <app-startup-grant-calculator
            [facilityDetails]="facilityDetails[0]"
            (next)="nextStep($event)"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
          ></app-startup-grant-calculator>
        </ng-container>
        <ng-container *ngIf="applicationType === 'Maintenance Grant'">
          <app-bonus-grant-calculator
            [facilityDetails]="facilityDetails[0]"
            (next)="nextStep($event)"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
          ></app-bonus-grant-calculator>
        </ng-container>
        <ng-container *ngIf="applicationType === 'Enrichment Grant'">
          <app-enrichment-grant-calculator
            [facilityDetails]="facilityDetails[0]"
            (next)="nextStep($event)"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
          ></app-enrichment-grant-calculator>
        </ng-container>
      </div>
    </mat-tab>
    <ng-container *ngIf="applicationType !== 'Enrichment Grant'">
      <mat-tab [disabled]="true">
        <ng-template mat-tab-label>
          <div class="d-flex align-items-center justify-content-start">
            <div class="tab-label-container">
              <span>
                <ng-container *ngIf="applicationData.id"
                  ><span class="font-18">3</span></ng-container
                >
              </span>
            </div>
            
              <span class="ml-2 font-16">{{
                "applications.PROPOSED_BUDGET" | translate
              }}</span>
          </div>
        </ng-template>
        <div class="m-3">
          <app-proposed
            *ngIf="applicationType"
            (next)="nextStep($event)"
            (back)="goToPreviousStep()"
            [applicationType]="applicationType"
            [application]="applicationDetails[0]"
            [facility]="facilityDetails[0]"
            [expense]="expenseDetails[0]"
          ></app-proposed>
        </div>
      </mat-tab>
    </ng-container>
    <mat-tab [disabled]="true">
      <ng-template mat-tab-label>
        <div class="d-flex align-items-center justify-content-start">
          <div class="tab-label-container">
            <span>
              <ng-container *ngIf="applicationData.id && applicationType !== 'Enrichment Grant'"><span class="font-18">4</span></ng-container>
              <ng-container *ngIf="applicationData.id && applicationType === 'Enrichment Grant'"><span class="font-18">3</span></ng-container>
            </span>
          </div>
          <span class="ml-2 font-16">
            {{ "applications.CERTIFICATIONS" | translate }}</span
          >
        </div>
      </ng-template>
      <div class="m-3">
        <ng-container>
          <app-certifications
            [facilityDetails]="facilityDetails[0]"
            (back)="goToPreviousStep()"
            [applicationDetails]="applicationDetails[0]"
            [isPrimaryUser]="isPrimaryUser()"
          ></app-certifications>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
