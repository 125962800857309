import { Injectable } from "@angular/core";
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { RouteDataService } from "src/app/services/route-data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { forkJoin, Observable, of } from "rxjs";
import { ResolverService } from "src/app/services/resolver.service";
import { ProjectSpecificData } from "src/app/models/project-specific-data";

@Injectable({
  providedIn: "root",
})
export class DashboardResolver implements Resolve<Object[]> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Object[] | import("rxjs").Observable<Object[]> | Promise<Object[]> {
    this.projectSpecificData =
      this.projectSpecificService.getProjectSpecificData();
    const subjectTableName = this.routeDataService.getData(
      route,
      "subjectTableName"
    );
    let observables = new Array<Observable<Object[]>>();

    switch (subjectTableName) {
      case "application-list":
        observables = this.getApplicationListObservables();
        break;
      case "application-edit-landlord":
      case "application-edit-renter":
        // observables = this.resolverService.getObservables(route.paramMap.get("id"));
        break;
    }

    this.spinner.show();
    return forkJoin(observables);
  }

  constructor(
    private spinner: NgxSpinnerService,
    private ignatiusService: IgnatiusService,
    private routeDataService: RouteDataService,
    private projectSpecificService: ProjectSpecificService,
    private resolverService: ResolverService
  ) {}

  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();

  getApplicationListObservables() {
    const observables: Array<Observable<Object[]>> = [
      //--------------- [0] ---------------//
      this.ignatiusService.getQueryReportObservable(
        this.projectSpecificData.appData,
        { ApplicationTableId: this.projectSpecificData.facility.TableId }
      ),
    ];
    return observables;
  }
}
