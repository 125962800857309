<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper"
    [ngClass]="{'show-sidebar': showMobileMenu}"
    [dir]="options.dir"
    [attr.data-theme]="options.theme"
    [attr.data-layout]="options.layout"
    [attr.data-sidebartype]="options.sidebartype"
    [attr.data-sidebar-position]="options.sidebarpos"
    [attr.data-header-position]="options.headerpos"
    [attr.data-boxed-layout]="options.boxed">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
    <!-- ============================================================== -->
    <header class="topbar">
        <nav class="d-flex navbar-light top-navbar navbar-expand-md" [ngClass]="{'prod-bg': isProduction, 'dev-bg': !isProduction}"
            [ngClass]="(options.navbarbg=='skin5')?'navbar-light':'navbar-light'">
            <div class="navbar-header" style="width: 0px !important;" 
                [ngClass]="(expandLogo)?'expand-logo':''"
                >
                <!-- This is for the sidebar toggle which is visible on mobile only -->
                <!-- <a (click)="showMobileMenu = !showMobileMenu"
                    class="nav-toggler waves-effect waves-light d-block d-md-none">
                    <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
                </a> -->
                <!-- ============================================================== -->
                <!-- Logo -->
                <!-- ============================================================== -->
                <!-- <a class="navbar-brand"
                    routerLink="/">
                    <img src="/assets/images/LA-logos.png"
                        height="55"
                        class="d-inline-block align-top mt-1"
                        alt=""> 
                    <span style="font-size:.1px;"></span>
                </a> -->
                <!-- ============================================================== -->
                <!-- End Logo -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Toggle which is visible on mobile only -->
                <!-- ============================================================== -->
                <a class="topbartoggler d-block d-md-none waves-effect waves-light position-fixed text-dark"
                    (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent">
                    <i class="ti-more"></i>
                </a>
            </div>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <div class="navbar-collapse collapse"
                id="navbarSupportedContent"
                [attr.data-navbarbg]="options.navbarbg"
                [ngClass]="{'prod-bg': isProduction, 'dev-bg': !isProduction}"
                [ngbCollapse]="!isCollapsed">
                <app-navigation (toggleSidebar)="toggleSidebarType()"
                    class="w-100">
                </app-navigation>
            </div>
        </nav>
    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->

    
    <!-- <aside class="left-sidebar"
        [attr.data-sidebarbg]="options.sidebarbg"
        (mouseover)="Logo()"
        (mouseout)="Logo()"> -->

        <!-- Sidebar scroll-->

        <!-- <div class="scroll-sidebar">
            <app-sidebar></app-sidebar>
        </div> -->

        <!-- End Sidebar scroll-->
    <!-- </aside> -->

    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper" style="margin-top: 15px;">
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->

        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
                <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <!-- <footer class="footer text-center">
            Business Interruption Grant Resource Center<br>
            (888) 722-1554<br>
            November 9th, 2020 - November 25th, 2020<br>
            Monday - Friday<br>
            7 AM to 7 PM CST
            <div *ngIf="false">
                All Rights Reserved by IAF Consulting, LLC. Designed and Developed by
                <a href="https://iafconsult.com">IAF Consulting, LLC</a>.
            </div>
        </footer> -->
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>