import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IgnatiusService } from 'src/app/services/ignatius.service';

@Component({
  selector: 'app-bonus-grant-calculator',
  templateUrl: './bonus-grant-calculator.component.html',
  styleUrls: ['./bonus-grant-calculator.component.css']
})
export class BonusGrantCalculatorComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  @Input("facilityDetails") facilityDetails: any = {};
  @Input("applicationDetails") applicationDetails: any = {};

  form: FormGroup = this.fb.group({
    facility_type: [""],
    capacity: [""],
    total_bonus_grant_amount: [""],
  });
  constructor(private fb: FormBuilder,private ignatiusService:IgnatiusService) {}

  ngOnInit() {}

  isApplicationSubmitted = (): boolean => {
    if (
      this.applicationDetails &&
      (this.applicationDetails.status === "Submitted" ||
        this.applicationDetails.status === "Approved" ||
        this.applicationDetails.status === "Denied" ||
        this.applicationDetails.status === "Ineligible" ||
        this.applicationDetails.status === "Paid") ||
        this.ignatiusService.isMaxFunded
    )
      return true;
    else return false;
  };

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityDetails && changes.facilityDetails.currentValue)
      this.facilityDetails = changes.facilityDetails.currentValue;
    if (changes.applicationDetails && changes.applicationDetails.currentValue)
      this.applicationDetails = changes.applicationDetails.currentValue;

    if (this.applicationDetails) {
      this.form.setValue({
        facility_type: this.facilityDetails.facility_type,
        capacity: this.facilityDetails.pp_facility_capacity,
        total_bonus_grant_amount: this.applicationDetails.total_bonus_grant_amount,
      });
    }
  }

  goToNextStep = () => {
    this.next.emit();
  };

  goBack = () => {
    this.back.emit();
  };
}
