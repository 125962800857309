<i *ngIf="!template && applications.length" class="fa fa-plus-circle"></i>
<ng-container *ngIf="applications.length">
  <ng-container *ngTemplateOutlet="template"></ng-container>
</ng-container>
<ng-template #modal let-modal>
  <div class="modal-header bg-light">
    <span class="modal-title">
      <h4 class="modal-title" id="modal-basic-title">
        {{ "applications.CREATE_TICKET" | translate }}
      </h4>
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="ticketForm" (ngSubmit)="submitTicket()">
      <div class="form-group">
        <div class="row">
          <div class="col-md">
            <label>{{ "applications.TICKET_TYPE" | translate }}</label>
            <select class="form-control" formControlName="ticket_type">
              <option value="" disabled>
                - {{ "applications.SELECT" | translate }} -
              </option>
              <option *ngFor="let item of ticketTypes" [value]="item.Value">
                {{ item.Name | transform | translate }}
              </option>
            </select>
          </div>
          <div class="col-md" *ngIf="appId === -1">
            <label>{{ "applications.APPLICATION" | translate }}</label>
            <select class="form-control" formControlName="related_applications">
              <option value="" disabled>
                - {{ "applications.SELECT" | translate }} -
              </option>
              <option *ngFor="let item of applications" [value]="item.id">
                {{ getDisplayName(item) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ "applications.SUBJECT" | translate }}</label>
        <input type="text" class="form-control" formControlName="subject" />
      </div>
      <div class="form-group">
        <label>{{ "applications.DETAILS" | translate }}</label>
        <textarea
          class="form-control"
          rows="5"
          formControlName="details"
        ></textarea>
      </div>

      <fieldset
        [disabled]="
          ticketForm.value.ticket_type !== 'Technical Support' &&
          ticketForm.value.ticket_type !== 'Response to Case Manager Request'
        "
        *ngIf="
          ticketForm.value.ticket_type === 'Technical Support' ||
          ticketForm.value.ticket_type === 'Response to Case Manager Request'
        "
      >
        <div class="form-group">
          <label>{{ "applications.DOCUMENTS" | translate }}</label>
          <div>{{ "applications.FILE_LIMIT_SIZE" | translate }}</div>
          <input
            type="file"
            class="form-control border-0"
            (change)="
              loadFile($event.target.files[0], 'file_doc_1', 'file_doc_1_url')
            "
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="
              loadFile($event.target.files[0], 'file_doc_2', 'file_doc_2_url')
            "
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="
              loadFile($event.target.files[0], 'file_doc_3', 'file_doc_3_url')
            "
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="
              loadFile($event.target.files[0], 'file_doc_4', 'file_doc_4_url')
            "
          />
          <input
            type="file"
            class="form-control border-0"
            (change)="
              loadFile($event.target.files[0], 'file_doc_5', 'file_doc_5_url')
            "
          />
        </div>
      </fieldset>

      <div>
        <button
          *ngIf="!modalConfig.busy"
          type="submit"
          class="btn background-third-whitetext"
          [disabled]="ticketForm.invalid"
        >
          {{ "applications.SUBMIT" | translate }}
        </button>
        <button
          *ngIf="modalConfig.busy"
          type="button"
          class="btn background-third-whitetext"
        >
          <strong
            ><i class="fa fa-cog fa-spin"></i>
            {{ "applications.PLEASE_WAIT" | translate }}</strong
          >
        </button>
        <button type="button" (click)="close()" class="btn btn-dark">
          {{ "applications.CANCEL" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
