<nav class="sidebar-nav">
    <ul id="sidebarnav">

        <!-- First level menu -->
        <li class="sidebar-item"
            [ngClass]="[sidebarnavItem.ddclass]"
            [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <!-- <div class="nav-small-cap"
                *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div> -->
            <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]"
                *ngIf="showTab(sidebarnavItem.id)"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false"
                class="collapse first-level"
                *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item"
                    *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">

                    <a class="sidebar-link"
                        [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]"
                        *ngIf="showTab(sidebarnavSubItem.id)"
                        (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</nav>