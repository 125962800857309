import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { PopupModel } from "src/app/modules/dashboards/models/popup";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { Constants } from "../../constants";

/**
 * @param icon to view
 * @param recordId record id to delete
 * @param tableId table id of record
 * @param modalConfig modal config which is currently open
 * @param fields of which value is to be empty
 * @param tryFn function to execute when try executed successfully
 * @param catchFn function to execute when try failed
 * @param finallyFn function to execute anyway
 * @param tryArgs pass args to try function
 */

@Component({
  selector: "app-file-delete",
  templateUrl: "./file-delete.component.html",
  styleUrls: ["./file-delete.component.css"],
})
export class FileDeleteComponent implements OnInit {
  @Input("icon") icon: string = "fa-times-circle";
  @Input("recordId") recordId: number;
  @Input("tableId") tableId: string;
  @Input("modalConfig") modalConfig: PopupModel;
  @Input("fields") fields: Array<string>;
  @Input("tryFn") tryFn: Function = function () {};
  @Input("catchFn") catchFn: Function = function () {};
  @Input("finallyFn") finallyFn: Function = function () {};
  @Input("tryArgs") tryArgs: Array<any> = [];
  @Input("disabled") disabled: boolean = false;

  constructor(
    private toastr: ToastrService,
    private ignatiusService: IgnatiusService,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  /**
   * Delete the file
   */
  deleteFile = async () => {
    if (this.disabled) return;
    const recordFAD = new FormActionData(
      "",
      this.tableId,
      new Where(this.recordId),
      new Array<FieldListItem>()
    );

    this.fields.forEach((field) => {
      recordFAD.fieldsList.push(new FieldListItem(field, "", ""));
    });

    this.submit(recordFAD);
  };

  /**
   * Submit request
   */
  submit = async (recordFAD: FormActionData) => {
    try {
      this.modalConfig.busy = true;
      await this.ignatiusService.putData(recordFAD).toPromise();
      await this.tryFn(this.tryArgs);
      this.toastr.success(this.translate.instant("MESSAGES.FILE_DELETE_SUCCESS"), this.translate.instant("applications.SUCCESS"));
    } catch (error) {
      await this.catchFn();
      this.toastr.error(this.translate.instant("MESSAGES.FILE_DELETE_ERROR"), this.translate.instant("applications.ERROR"));
    } finally {
      await this.finallyFn();
      this.modalConfig.busy = false;
    }
  };
}
