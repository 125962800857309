import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { race } from "rxjs-compat/operator/race";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";

@Component({
  selector: "app-operational-status",
  templateUrl: "./operational-status.component.html",
  styleUrls: ["./operational-status.component.css"],
})
export class OperationalStatusComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  form: FormGroup = this.fb.group({
    facility_type: [""],
    licensed_march_11: [""],
    current_status: [""],
    non_traditional_hours: [""],
    special_needs: [""],
    operating_budget: [""],
  });

  facilityOptions: Array<any> = [];
  licensedBefore11Options: Array<any> = [];
  currentStatusOptions: Array<any> = [];
  nonTraditionHoursOptions: Array<any> = [];
  specialNeedsOptions: Array<any> = [];
  @Input("facilityDetails") facilityDetails: any = {};
  project = this.projectSpecificService.getProjectSpecificData();

  constructor(
    private fb: FormBuilder,
    private ignatiusService: IgnatiusService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private projectSpecificService: ProjectSpecificService,
  ) { }

  ngOnInit() {
    this.ignatiusService.getDropdownValues("23336").subscribe((res: any) => {
      this.facilityOptions = res;
    });

    this.ignatiusService.getDropdownValues("23176").subscribe((res: any) => {
      this.licensedBefore11Options = res;
    });


    this.ignatiusService.getDropdownValues("23177").subscribe((res: any) => {
      this.currentStatusOptions = res;
    });

    this.ignatiusService.getDropdownValues("23178").subscribe((res: any) => {
      this.nonTraditionHoursOptions = res;
    });


    this.ignatiusService.getDropdownValues("23179").subscribe((res: any) => {
      this.specialNeedsOptions = res;
    });
  }

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  goBack = () => {
    this.back.emit();
  };

  async saveAndNext() {
    if (this.isFacilitySaved()) {
      this.goToNextStep();
    } else {
      this.spinner.show();
      let payload = this.getPayload();
      let redId = new Where(this.facilityDetails.id);
      const tableKey = this.ignatiusService.getReportKey(
        this.project.appData,
        "Facility"

      )
      let reqBody = new FormActionData("", tableKey, redId, payload);
      this.ignatiusService.putData(reqBody).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.goToNextStep();
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error(
            this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
            this.translate.instant("applications.ERROR")
          );
        }
      );
    }
  }

  goToNextStep = () => {
    this.next.emit();
  };

  getPayload = () => {
    let formData = { ...this.form.value };
    let payload = [];

    for (let i in formData) {
      payload.push(new FieldListItem(i, formData[i], ""));
    }
    return payload;
  };

  disableInputField = () => {
    this.form.controls["operating_budget"].disable();
  };

  ngOnChanges(changes: SimpleChanges) {
    this.facilityDetails = changes.facilityDetails.currentValue;
    if (this.facilityDetails) {
      this.form.setValue({
        facility_type: this.facilityDetails.facility_type,
        licensed_march_11: this.facilityDetails.licensed_march_11,
        current_status: this.facilityDetails.current_status,
        non_traditional_hours: this.facilityDetails.non_traditional_hours,
        special_needs: this.facilityDetails.special_needs,
        operating_budget: this.facilityDetails.operating_budget,
      });

      if (this.isFacilitySaved()) {
        this.disableInputField();
      }
    }
  }
}
