<h3 class="mb-4">{{ "applications.SAVE_AND_EXIT" | translate }}</h3>
<div class="row" *ngIf="displayAnnual">
  <div class="col" >
    <h5 class="font-weight-bold text-left">
      {{ "DROPDOWNS.ANNUAL_VERIFY" | translate }}
    </h5>
    <p>$ {{ annualIncome.toLocaleString() }}</p>
  </div>
</div>
<div class="row" *ngIf="displayMonthly">
  <div class="col">
    <h5 class="font-weight-bold text-left">
      {{ "DROPDOWNS.MONTHLY_VERIFY" | translate }}
    </h5>
    <div class="row">
      <div class="col-md mb-3">
        <p class="m-0">{{ "applications.MONTH_1_AMOUNT" | translate }}</p>
        $ {{ monthly1Income.toLocaleString() }}
      </div>
      <div class="col-md">
        <p class="m-0">{{ "applications.MONTH_2_AMOUNT" | translate }}</p>
        $ {{ monthly2Income.toLocaleString() }}
      </div>
    </div>
  </div>
</div>

<div class="div mt-3">
  <p class="text-muted">
    ** {{ "applications.HH_CONFIRM_SAVE_COMMENT" | translate }}
  </p>
</div>

<div class="mt-3">
  <button class="btn background-third-whitetext" (click)="close(true)">
    {{ "applications.SAVE" | translate }}
  </button>
  <button class="btn btn-dark" (click)="close()">
    {{ "applications.CANCEL" | translate }}
  </button>
</div>
