// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl: 'https://devapi.ignatius.io',
//   deploymentUrl: 'erap-ri-dev.herokuapp.com', 
//   applicationId: 142,
//   applicantRole: 740,
//   landlordRole: 741,
//   captchaSiteKey: '6LcW7r0aAAAAAEzxHREtw_ln-hBN5j7DHIOMXziJ',
// };


export const environment = {
  production: false,
  apiUrl: 'https://api.ignatius.io',
  // deploymentUrl: 'ark-dccece.herokuapp.com', 
  deploymentUrl: 'arkansas-childcaregrant.com',
  applicationId: 198,
  applicationKey: 'ljli4k2a4',
  applicantRole: 'vi1sm0ca1',
  applicantRoleId: 804,
  landlordRole: '6azslnwa4',
  captchaSiteKey: '6LeX0EUaAAAAACGS6j7J9JZ4Q1qLCZqq5leeyMYS',
  client_id: 'ARKDCCECE',
  captchaSiteKeyV3: "6Lc2Z7UdAAAAAL8HwkjdbWS1OsNKBv2aUvlN1clV",
};

// export const environment = {
//   production: false,
//   // apiUrl: 'https://devapi.ignatius.io',
//   apiUrl: 'https://devapi.ignatius.io',
//   deploymentUrl: 'ark-dccece-dev.herokuapp.com', 
//   applicationId: 857,
//   applicationKey: 'he0n2iba2',
//   applicantRole: 'cwzxjxaw2',
//   applicantRoleId: 570,
//   landlordRole: '6azslnwa4',
//   // captchaSiteKey: '6LcjW2UaAAAAALHVLhCPoLTCDcrsRDFJTd5CFmlF',
//   captchaSiteKey: '6LeX0EUaAAAAACGS6j7J9JZ4Q1qLCZqq5leeyMYS',
//   client_id: 'ARKDCCECE'
// };
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
