import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { environment } from "src/environments/environment";

const expenseFieldNames = [
  "new_staff",
  "substitutes",
  "minor_renovations",
  "furniture",
  "curriculum_resources",
  "parent_involvement",
  "professional_development",
  "screenings",
  "technology",
  "security_systems",
  "mental_health_support",
  "other_re",
];
@Component({
  selector: "app-proposed-budget",
  templateUrl: "./proposed-budget.component.html",
  styleUrls: ["./proposed-budget.component.css"],
})
export class ProposedBudgetComponent implements OnInit {
  @Input("facilityDetails") facilityDetails: any = {};
  @Input("applicationDetails") applicationDetails: any = {};
  @Input("expenseDetails") expenseDetails: any = {};
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  form: FormGroup = this.fb.group({
    subgrant_amount: [0, [Validators.required, Validators.min(0)]],
    new_staff: [0, [Validators.required, Validators.min(0)]],
    substitutes: [0, [Validators.required, Validators.min(0)]],
    minor_renovations: [0, [Validators.required, Validators.min(0)]],
    furniture: [0, [Validators.required, Validators.min(0)]],
    curriculum_resources: [0, [Validators.required, Validators.min(0)]],
    parent_involvement: [0, [Validators.required, Validators.min(0)]],
    professional_development: [0, [Validators.required, Validators.min(0)]],
    screenings: [0, [Validators.required, Validators.min(0)]],
    technology: [0, [Validators.required, Validators.min(0)]],
    security_systems: [0, [Validators.required, Validators.min(0)]],
    mental_health_support: [0, [Validators.required, Validators.min(0)]],
    other_re: [0, [Validators.required, Validators.min(0)]],
    explanation_box: [""],
    total_alloted_grant_amount: [0, Validators.required],
    remaining_grant_amount: [0, [Validators.required, Validators.min(0)]],
  });

  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();
  constructor(
    private fb: FormBuilder,
    private ignatiusService: IgnatiusService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private router: Router,
    private projectSpecificService: ProjectSpecificService
  ) {}

  ngOnInit() {}

  isApplicationSubmitted = (): boolean => {
    if (
      this.applicationDetails &&
      (this.applicationDetails.status === "Submitted" ||
        this.applicationDetails.status === "Approved" ||
        this.applicationDetails.status === "Denied" ||
        this.applicationDetails.status === "Ineligible" ||
        this.applicationDetails.status === "Paid") ||
        this.ignatiusService.isMaxFunded
    )
      return true;
    else return false;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityDetails && changes.facilityDetails.currentValue)
      this.facilityDetails = changes.facilityDetails.currentValue;
    if (changes.applicationDetails && changes.applicationDetails.currentValue)
      this.applicationDetails = changes.applicationDetails.currentValue;

    if (changes.expenseDetails && changes.expenseDetails.currentValue)
      this.expenseDetails = changes.expenseDetails.currentValue;

    if (this.applicationDetails) {
      this.form.patchValue({
        subgrant_amount: this.applicationDetails.quality_total_grant_amount,
      });
    }

    if (this.expenseDetails) {
      this.form.patchValue({
        new_staff: this.expenseDetails.new_staff,
        substitutes: this.expenseDetails.substitutes,
        minor_renovations: this.expenseDetails.minor_renovations,
        furniture: this.expenseDetails.furniture,
        curriculum_resources: this.expenseDetails.curriculum_resources,
        parent_involvement: this.expenseDetails.parent_involvement,
        professional_development: this.expenseDetails.professional_development,
        screenings: this.expenseDetails.screenings,
        technology: this.expenseDetails.technology,
        security_systems: this.expenseDetails.security_systems,
        mental_health_support: this.expenseDetails.mental_health_support,
        other_re: this.expenseDetails.other_re,
        explanation_box: this.expenseDetails.explanation_box,
      });

      if (this.isApplicationSubmitted()) {
        this.disableAllFields();
      }
    }
  }

  disableAllFields = () => {
    for (let i = 0; i < expenseFieldNames.length; i = i + 1) {
      this.form.controls[expenseFieldNames[i]].disable();
    }
  };

  getCurrentTotalExpense = () => {
    let sum = 0;
    for (let i = 0; i < expenseFieldNames.length; i = i + 1) {
      sum = sum + +this.form.controls[expenseFieldNames[i]].value;
    }
    return sum;
  };

  saveExpensePayload = () => {
    let payload = [];

    for (let i = 0; i < expenseFieldNames.length; i = i + 1) {
      payload.push(
        new FieldListItem(
          expenseFieldNames[i],
          this.form.controls[expenseFieldNames[i]].value,
          ""
        )
      );
    }

    if (this.form.value.other_re > 0)
      payload.push(
        new FieldListItem(
          "explanation_box",
          this.form.controls["explanation_box"].value,
          ""
        )
      );
    else {
      payload.push(new FieldListItem("explanation_box", "", ""));
    }

    return payload;
  };

  saveExpense = () => {
    this.spinner.show();
    let payload = this.saveExpensePayload();
    if (this.expenseDetails && this.expenseDetails.id) {
      let redId = new Where(this.expenseDetails.id);
      let reqBody = new FormActionData(
        "",
        this.projectSpecificData.expenseData.TableId,
        redId,
        payload
      );
      this.ignatiusService.putData(reqBody).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.next.emit(true);
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error(
            this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
            this.translate.instant("applications.ERROR")
          );
        }
      );
    } else {
      payload.push(
        new FieldListItem("related_application", this.applicationDetails.id, "")
      );
      let postPayload = {
        ApplicationTableId: this.projectSpecificData.expenseData.TableId,
        ApplicationId: environment.applicationId,
        FieldsList: payload,
      };
      this.ignatiusService.postData(postPayload).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.next.emit(true);
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error(
            this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
            this.translate.instant("applications.ERROR")
          );
        }
      );
    }
  };

  goToNextStep = () => {
    this.next.emit();
  };

  goBack = () => {
    this.back.emit();
  };
}
