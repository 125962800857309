<div class="save-confirmation">
    <div class="heading">
      <h2>{{ "common.IMPORTANT" | translate }}</h2>
      <i (click)="closeModal()" class="fa fa-times"></i>
    </div>
    <p>{{ 'applications.SAVE_APPLICATION_CONFIRMATION_TEXT' | translate }}</p>
    <div class="button-div">
      <button class="btn btn-success" (click)="onOptionSelected(true)">
        {{ "common.SUBMIT_NOW" | translate }}
      </button>
      <p class="save-for-later" (click)="onOptionSelected(false)">
        {{ "facility.GO_BACK" | translate }}
      </p>
    </div>
  </div>
  