<div class="save-confirmation">
  <div class="heading">
    <h2>{{ "common.IMPORTANT" | translate }}</h2>
    <i (click)="closeModal()" class="fa fa-times"></i>
  </div>
  <p>{{ confirmationTextKey | translate }}</p>
  <div class="button-div">
    <button class="btn btn-success" (click)="onOptionSelected(true)">
      {{ "common.SUBMIT_NOW" | translate }}
    </button>
    <p class="save-for-later" (click)="onOptionSelected(false)">
      {{ "common.SAVE_FOR_LATER" | translate }}
    </p>
  </div>
</div>
