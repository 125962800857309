import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { environment } from "src/environments/environment";
import { BugetConfirmationPopupComponent } from "../../buget-confirmation-popup/buget-confirmation-popup.component";
import {
  expansionFundRows,
  operationalFundRows,
  sustainabilityFundRows,
  qualityFundRows,
} from "./proposed.constants";

@Component({
  selector: "app-proposed",
  templateUrl: "./proposed.component.html",
  styleUrls: ["./proposed.component.css"],
})
export class ProposedComponent implements OnInit, OnChanges {
  @Input("applicationType") applicationType: string | null = null;
  @Input("application") application: any = null;
  @Input("facility") facility: any = null;
  @Input("expense") expense: any = null;
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();

  form!: FormGroup;
  isRemain_and_total_equal = false;
  isChildCareExpansion = false;

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private ignatius: IgnatiusService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private projectSpecificService: ProjectSpecificService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    this.isChildCareExpansion = window.location.href.includes(
      "child-care-expansion"
    );
    if (!this.isChildCareExpansion) {
      this.isChildCareExpansion =
        this.applicationType === "Startup Grant" ||
        this.applicationType === "Maintenance Grant" ||
        this.applicationType === "Enrichment Grant" 
          ? true
          : false;
    }
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.facility && changes.facility.currentValue)
      this.facility = changes.facility.currentValue;
    if (changes.application && changes.application.currentValue)
      this.application = changes.application.currentValue;
    if (changes.expense && changes.expense.currentValue)
      this.expense = changes.expense.currentValue;
    if (this.application && this.application.status) this.disableAllFields();
    if (this.application)
      this.form.patchValue({
        ...this.application,
      });
    if (this.facility)
      this.form.patchValue({
        ...this.facility,
      });
    if (this.expense)
      this.form.patchValue({
        ...this.expense,
      });
    if (
      this.form &&
      this.form.controls.remaining_grant_amount &&
      this.application
    ) {
      this.form.controls.remaining_grant_amount.setValue(
        this.application.total_subgrant_amount
      );
      this.calcSum(null);
    }
    if (this.applicationType === "Sustainability" && this.form) {
      this.form.controls["total_sustainability_grant_amount"].setValue(
        this.grantAmount
      );
      this.form.controls.remaining_grant_amount.setValue(this.grantAmount);
      this.calcSum(null);
    }
  }

  get grantAmount() {
    if (this.application.ccdf_checkbox === "True") {
      return (
        10000 +
        Number(this.application.sustainability_grant_amount) +
        Number(this.application.better_beginnings_bonus_amount)
      );
    } else {
      return (
        Number(this.application.sustainability_grant_amount) +
        Number(this.application.better_beginnings_bonus_amount)
      );
    }
  }

  buildForm = () => {
    const group = {};
    for (let row of this.rows) group[row.formControlName] = [null];
    group["explanation_box"] = [null];
    if (!this.isChildCareExpansion)
      group["expenditure_march11"] = [null, Validators.required];
    group["remaining_grant_amount"] = [null, Validators.min(0)];
    group["total_subgrant_amount"] = [null];
    group["total_startup_grant_amount"] = [null];
    group["total_bonus_grant_amount"] = [null];
    group["total_sustainability_grant_amount"] = [null];
    this.form = this.fb.group(group);
    // attach listener to calculate on value changes
    this.rows.map((e) => {
      this.form.controls[e.formControlName].valueChanges.subscribe((value) => {
        this.calcSum(value);
      });
    });
    // update explaination box according to other_re
    this.form.controls.other_re.valueChanges.subscribe((value) => {
      if (!+value) this.form.controls.explanation_box.setValue("");
    });
  };

  /**
   * Calculate sum of table fields
   */
  calcSum = (values: Object) => {
    let amount = 0;
    this.isRemain_and_total_equal = false;
    this.rows
      .map((e) => e.formControlName)
      .forEach((e) => {
        amount += +(this.form.controls[e].value || 0);
      });
    if (
      this.applicationType !== "Startup Grant" &&
      this.applicationType !== "Sustainability" &&
      this.applicationType !== "Maintenance Grant" &&
      this.applicationType !== "Enrichment Grant"
    )
      return this.form.controls.remaining_grant_amount.setValue(
        +this.form.controls.total_subgrant_amount.value - amount
      );
    if (this.applicationType === "Sustainability")
      return this.form.controls.remaining_grant_amount.setValue(
        +this.form.controls.total_sustainability_grant_amount.value - amount
      );
    if (this.applicationType === "Startup Grant") {
      return this.form.controls.remaining_grant_amount.setValue(
        +this.form.controls.total_startup_grant_amount.value - amount
      );
    }
    if (this.applicationType === "Maintenance Grant") {
      return this.form.controls.remaining_grant_amount.setValue(
        +this.form.controls.total_bonus_grant_amount.value - amount
      );
    }
    if (this.applicationType === "Enrichment Grant") {
      return this.form.controls.remaining_grant_amount.setValue(
        +this.form.controls.total_enrichment_grant_amount.value - amount
      );
    }
  };

  onSubmit = () => {
    this.spinner.show();
    if (this.expense && this.expense.id) this.onEditSubmit();
    else this.onAddSubmit();
  };

  // for post request
  onAddSubmit = () => {
    const fieldList = this.expenseFieldList;
    fieldList.push(
      new FieldListItem("related_application", this.application.id, "")
    );
    const payload = {
      ApplicationTableId: this.projectSpecificData.expenseData.TableId,
      ApplicationId: environment.applicationId,
      FieldsList: fieldList,
    };
    this.ignatius.postData(payload).subscribe((res: any) => {
      this.spinner.hide();
      this.next.emit(true);
      this.modal.dismissAll();
    }, this.handleError);
  };

  // for put request
  onEditSubmit = () => {
    const payload = new FormActionData(
      "",
      this.projectSpecificData.expenseData.TableId,
      new Where(this.expense.id),
      this.expenseFieldList
    );
    this.ignatius.putData(payload).subscribe((res: any) => {
      this.spinner.hide();
      this.modal.dismissAll();
      this.next.emit(true);
    }, this.handleError);
  };

  /**
   * Handle API error
   */
  handleError = (error: any) => {
    this.spinner.hide();
    this.modal.dismissAll();
    this.toastr.error(
      this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
      this.translate.instant("applications.ERROR")
    );
  };

  get expenseFieldList() {
    return Object.entries(this.form.value).map(
      (field) =>
        new FieldListItem(
          field[0],
          field[1] === null ? "" : (field[1] as string),
          ""
        )
    );
  }

  get rows() {
    switch (this.applicationType) {
      case "Operational Payments":
        return operationalFundRows;
      case "Sustainability":
        return sustainabilityFundRows;
      case "Child Care Expansion":
        return expansionFundRows;
      case "Startup Grant":
        return operationalFundRows;
      case "Maintenance Grant":
        return operationalFundRows;
      case "Enrichment Grant":
        return operationalFundRows;
      default:
      case "Quality Improvement":
        return qualityFundRows;
    }
  }

  get tableTitle() {
    switch (this.applicationType) {
      case "Operational Payments":
        return "applications.PROPOSED_BUDGET_OPERATIONAL_FUNDS";
      case "Sustainability":
        return "applications.PROPOSED_BUDGET_SUSTAINABILITY";
      case "Child Care Expansion":
        return "applications.PROPOSED_BUDGET_CHILD_EXP_FUNDS";
      case "Startup Grant":
        return "applications.PROPOSED_BUDGET_FOR_STARTUP_GRANT_FUNDS";
      case "Maintenance Grant":
        return "applications.PROPOSED_BUDGET_FOR_BONUS_GRANT_FUNDS";
      case "Enrichment Grant":
        return "applications.PROPOSED_BUDGET_FOR_ENRICHMENT_GRANT_FUNDS";
      case "Quality Improvement":
        return "applications.USE_OF_QUAL_FUNDS";
      default:
        return "applications.USE_OF_QUAL_FUNDS";
    }
  }

  get totalAmountTitle() {
    switch (this.applicationType) {
      case "Operational Payments":
        return "applications.TOTAL_OPERATIONAL_AMOUNT";
      case "Sustainability":
        return "applications.TOTAL_SUSTAINABILITY_AMOUNT";
      case "Child Care Expansion":
        return "applications.TOTAL_EXPANSION_AMOUNT";
      case "Startup Grant":
        return "applications.TOTAL_STARTUP_GRANT_AMOUNT";
      case "Maintenance Grant":
        return "applications.TOTAL_BONUS_GRANT_AMOUNT";
      case "Enrichment Grant":
        return "applications.TOTAL_ENRICHMENT_GRANT_AMOUNT"
      case "Quality Improvement":
        return "applications.TOTAL_QUALITY_SUBGRANT_AMOUNT";
      default:
        return "applications.TOTAL_QUALITY_SUBGRANT_AMOUNT";
    }
  }

  openConfirmationPopUp = (): void => {
    const remain_amount = this.form.value.remaining_grant_amount;
    let total_amount;
    if (this.applicationType === "Startup Grant") {
      total_amount = this.form.value.total_startup_grant_amount;
    } else if (this.applicationType === "Maintenance Grant") {
      total_amount = this.form.value.total_bonus_grant_amount;
    } else if (this.applicationType === "Enrichment Grant") {
      total_amount = this.form.value.total_enrichment_grant_amount;
    } else {
      total_amount = this.form.value.total_subgrant_amount;
    }
    if (Number(remain_amount) !== Number(total_amount)) {
      this.isRemain_and_total_equal = false;
      if (this.form.value.remaining_grant_amount === 0) this.onSubmit();
      else {
        const modal = this.modal.open(BugetConfirmationPopupComponent, {
          windowClass: "modal-custom",
          centered: true,
        });

        modal.componentInstance.explation = this.application.grant_box;

        modal.componentInstance.optionSelected.subscribe(
          async (isSubmit: any) => {
            if (isSubmit.isSubmit) {
              await this.saveExplanation(isSubmit.value);
              this.onSubmit();
            } else {
              this.modal.dismissAll();
            }
          }
        );
      }
    } else {
      this.isRemain_and_total_equal = true;
    }
  };

  saveExplanation = async (params: string) => {
    this.spinner.show();
    const payload: FormActionData = {
      reportId: "",
      applicationTableId: this.projectSpecificData.applicationData.TableId,
      fieldsList: [
        {
          name: "grant_box",
          value: params,
          base64EncodedFile: "",
        },
      ],
      where: {
        rid: this.application.id,
      },
    };
    this.ignatius.putData(payload).subscribe((res) => {
      this.spinner.hide();
    });
  };

  isExplanationValid = () => {
    if (!+this.form.controls.other_re.value) return false;
    else {
      if (!this.form.controls.explanation_box.value) return true;
    }
  };

  moveToNext = () => {
    this.next.emit();
  };

  goBack = () => {
    this.back.emit();
  };

  status: Array<string> = [
    "Submitted",
    "Approved",
    "Denied",
    "Paid",
    "Ineligible",
  ];

  disableAllFields = () => {
    if (new RegExp(this.status.join("|")).test(this.application.status))
      for (let i in this.form.controls) {
        this.form.controls[i].disable();
      }
  };
}
