<div class="facility-verfication">
  <span class="font-24">{{ "facility.DETAILS" | translate }}</span>
  <span class="font-14 mt-3"
    >{{ "facility.NAME" | translate }}:
    <span class="bold-text">{{
      facilityDetails ? facilityDetails.facility_name : "--"
    }}</span></span
  >
  <span class="font-14"
    >{{ "facility.TYPE" | translate }}:
    <span class="bold-text">{{
      facilityDetails ? facilityDetails.facility_type : "--"
    }}</span></span
  >
  <span class="font-14">
    <ng-container *ngIf="startup_validation === 'True'">
      {{ "facility.PROGRAM_LICENSED_AFTER" | translate }}:
    </ng-container>
    <ng-container *ngIf="startup_validation !== 'True'">
      {{ "facility.PROGRAM_LICENSED" | translate }}:
    </ng-container>
    <span class="bold-text">{{
      facilityDetails ? facilityDetails.licensed_march_11 : "--"
    }}</span></span
  >
  <!-- <span class="font-14"
    >{{ "facility.ID_NUMBER" | translate }}:
    <span class="bold-text">{{
      facilityDetails ? facilityDetails.id : ""
    }}</span></span
  > -->
  <span class="font-14"
    >{{ "facility.FACILITY_ADDRESS" | translate }}:
    <span class="bold-text">{{
      facilityDetails ? facilityDetails.physical_address_1 : "--"
    }}</span></span
  >
  <span class="font-14"
    >{{ "facility.FACILITY_ADDRESS_2" | translate }}:
    <span class="bold-text">{{
      facilityDetails ? facilityDetails.physical_address_2 : "--"
    }}</span></span
  >
  <span class="font-14"
    >{{ "facility.CITY" | translate }}:
    <span class="bold-text">{{
      facilityDetails ? facilityDetails.physical_city : "--"
    }}</span></span
  >
  <span class="font-14"
    >{{ "facility.ZIP_CODE" | translate }}:
    <span class="bold-text">{{
      facilityDetails ? facilityDetails.physical_zipcode : "--"
    }}</span></span
  >
  <hr />
  <div class="facility-verify">
    <span class="font-24">{{ "facility.VERIFICATION" | translate }}</span>
    <p class="font-14">
      {{ "facility.ENTER_FACILITY_NUMBER" | translate }}
    </p>
    <form [formGroup]="form">
      <input
        type="text"
        formControlName="FacilityNumber"
        class="w-50 form-control"
      />
    </form>
    <p style="color: red" class="mt-1" *ngIf="notMatched">
      {{ "facility.FACILITY_NUMBER_NOT_MATCHED_ERROR_TEXT" | translate }}
    </p>
    <!-- <span class="button-div mt-2">
      <button
        class="btn btn-success"
        (click)="checkFacilityNumber()"
        [disabled]="form.invalid"
      >
        {{ "applications.CONFIRM" | translate }}
      </button>
      <p
        style="color: rgb(19, 96, 197); cursor: pointer"
        class="ml-3 mt-3"
        routerLink="/"
      >
        <u>{{ "facility.GO_BACK" | translate }}</u>
      </p>
    </span> -->
  </div>
</div>
