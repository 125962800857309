import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-save-confirmation",
  templateUrl: "./save-confirmation.component.html",
  styleUrls: ["./save-confirmation.component.css"],
})
export class SaveConfirmationComponent implements OnInit {
  @Input("confirmationText") confirmationTextKey = "";
  @Output("optionSelected") optionSelected = new EventEmitter();

  constructor(private modal: NgbModal) {}

  ngOnInit() {}

  onOptionSelected = (isSubmit: boolean) => {
    this.optionSelected.emit(isSubmit);
  };

  closeModal = () => {
    this.modal.dismissAll();
  };
}
