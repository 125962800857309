import {
  Component,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ContentChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopupModel } from "src/app/modules/dashboards/models/popup";

@Component({
  selector: "app-info-pop",
  templateUrl: "./info-pop.component.html",
  styleUrls: ["./info-pop.component.css"],
})
export class InfoPopComponent implements OnInit {
  @ViewChild("popup", { static: true }) popup: any;
  @Input("template") template: TemplateRef<any>;
  @Input("visible") visible: boolean = false;
  @Input("message") message: string = "applications.CLICK_TO_VIEW_DETAILS";
  @Input("classes") classes:string = 'fa fa-info-circle'

  modelConfig: PopupModel;

  constructor(private ngbModal: NgbModal) {}

  ngOnInit() {}

  /**
   * Close modal
   */
  close = () => {
    this.ngbModal.dismissAll();
  };

  /**
   * Click listener
   */
  @HostListener("click", ["$event"]) click(event) {
    event.preventDefault();
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "info-comp";
    this.ngbModal.open(this.popup, this.modelConfig.settings);
  }
}
