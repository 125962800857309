import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private applicationsData: Array<any> = [];
  applications = new BehaviorSubject(this.applicationsData);
  dropdowns: any = {};

  constructor() {}

  /**
   * Cache application data
   * Emits subject when new data is stored
   */
  cacheApplications = (applications) => {
    this.applicationsData = applications;
    this.applications.next(this.applicationsData);
  };
}
