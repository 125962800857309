import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { ChildCareExpansionCalcService } from "./child-care-expansion-calc.service";

const fieldName = [
  "expansion_fch_capacity",
  "expansion_infant_capacity",
  "expansion_toddler_capacity",
  "expansion_sa_capacity",
  "expansion_staff_salary_capacity",
  "question1",
  "question2",
  "question3",
  "question4",
  "question5",
  "question6",
  "question7",
  "q8_attachment",
  "q9_attachment",
];
@Component({
  selector: "app-child-care-expansion-calculator",
  templateUrl: "./child-care-expansion-calculator.component.html",
  styleUrls: ["./child-care-expansion-calculator.component.css"],
})
export class ChildCareExpansionCalculatorComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  @Input("facilityDetails") facilityDetails: any = {};
  @Input("applicationDetails") applicationDetails: any = {};
  isChildCareExpansion = false;
  disableFamilyCare = false;
  disableInfant = false;
  disableTodler = false;
  disableSchoolAge = false;
  disableStaffSalary = false;
  beginningsQualityOptions: Array<any> = [];
  yesNoOptions: Array<any> = [
    { Name: "No", Value: "No" },
    { Name: "Yes", Value: "Yes" },
  ];
  base64q8 = "";
  base64q9 = "";
  base64q10 = "";
  q8FileName = "";
  q9FileName = "";
  q10FileName = "";
  q8Url = "";
  q9Url = "";
  q10Url = "";
  q8Name = "";
  q9Name = "";
  q10Name = "";

  viewMode: boolean = true;
  
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();

  total_expansion_grant_amount = new FormControl(0);

  form: FormGroup = this.fb.group({
    expansion_fch_capacity: [0, [Validators.min(0)]],
    expansion_infant_capacity: [0, [Validators.min(0)]],
    expansion_toddler_capacity: [0, [Validators.min(0)]],
    expansion_sa_capacity: [0, [Validators.min(0)]],
    expansion_staff_salary_capacity: [0, [Validators.min(0)]],
    question1: [""],
    question2: [""],
    question3: [""],
    question4: [""],
    question5: [0, [Validators.min(0), Validators.max(7)]],
    question6: [""],
    question7: [0, [Validators.min(0), Validators.max(5)]],
    // question8: [""],
    q8_attachment: [""],
    // question9: [""],
    q9_attachment: [""],
    // question10: [""],
    // q10_attachment: [""],
  });
  constructor(
    private fb: FormBuilder,
    public ignatiusService: IgnatiusService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private childCareCalService: ChildCareExpansionCalcService,
    private projectSpecificService: ProjectSpecificService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isChildCareExpansion = window.location.href.includes(
      "child-care-expansion"
    );
    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.applicationData.Question2FieldId.toString()
      )
      .subscribe((res: any) => {
        this.beginningsQualityOptions = res;
      });
  }

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  isApplicationSubmitted = (): boolean => {
    if (
      (this.applicationDetails &&
        (this.applicationDetails.status === "Submitted" ||
          this.applicationDetails.status === "Approved" ||
          this.applicationDetails.status === "Denied" ||
          this.applicationDetails.status === "Ineligible" ||
          this.applicationDetails.status === "Paid")) ||
      this.ignatiusService.isMaxFunded
    ) {
      return true;
    } else {
      return false;
    }
  };

  disableAllField = () => {
    for (let i = 0; i < fieldName.length; i = i + 1) {
      this.form.controls[fieldName[i]].disable();
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityDetails && changes.facilityDetails.currentValue)
      this.facilityDetails = changes.facilityDetails.currentValue;
    if (changes.applicationDetails && changes.applicationDetails.currentValue)
      this.applicationDetails = changes.applicationDetails.currentValue;

    if (this.applicationDetails) {
      this.form.setValue({
        expansion_fch_capacity: this.applicationDetails.expansion_fch_capacity,
        expansion_infant_capacity:
          this.applicationDetails.expansion_infant_capacity,
        expansion_toddler_capacity:
          this.applicationDetails.expansion_toddler_capacity,
        expansion_sa_capacity: this.applicationDetails.expansion_sa_capacity,
        expansion_staff_salary_capacity:
          this.applicationDetails.expansion_staff_salary_capacity,
        question1: this.applicationDetails.question1,
        question2: this.applicationDetails.question2,
        question3: this.applicationDetails.question3,
        question4: this.applicationDetails.question4,
        question5: this.applicationDetails.question5,
        question6: this.applicationDetails.question6,
        question7: this.applicationDetails.question7,
        // question8: this.applicationDetails.question8,
        // question9: this.applicationDetails.question9,
        // question10: this.applicationDetails.question10,
        q8_attachment: this.applicationDetails.q8_attachment,
        q9_attachment: this.applicationDetails.q9_attachment,
        // q10_attachment: this.applicationDetails.q10_attachment,
      });
      this.q8Url = this.applicationDetails.q8_attachment_url;
      this.q8Name = this.applicationDetails.q8_attachment;
      this.q9Url = this.applicationDetails.q9_attachment_url;
      this.q9Name = this.applicationDetails.q9_attachment;
      // this.q10Url = this.applicationDetails.q10_attachment_url;
      // this.q10Name = this.applicationDetails.q10_attachment;

      if (this.isApplicationSubmitted()) {
        this.disableAllField();
      }
    }
    if (this.facilityDetails && this.isChildCareExpansion) {
      this.childCareExpansionFields();
    }
  }

  fileControls = ["q8_attachment", "q9_attachment", "q10_attachment"];

  getSavePayload = () => {
    let formValues = { ...this.form.value };
    let payload = [];

    for (let i in formValues) {
      if (
        !new RegExp(this.fileControls.join("|")).test(i) &&
        this.form.value[i]
      )
        payload.push(new FieldListItem(i, formValues[i], ""));
    }
    if (this.base64q8)
      payload.push(
        new FieldListItem("q8_attachment", this.q8FileName, this.base64q8)
      );
    if (this.base64q9)
      payload.push(
        new FieldListItem("q9_attachment", this.q9FileName, this.base64q9)
      );
    if (this.base64q10)
      payload.push(
        new FieldListItem("q10_attachment", this.q10FileName, this.base64q10)
      );
    return payload;
  };

  saveFacilityDetails = async () => {
    this.spinner.show();
    let payload = await this.getSavePayload();
    let redId = new Where(this.applicationDetails.id);
    let reqBody = new FormActionData(
      "",
      this.projectSpecificData.applicationData.TableId,
      redId,
      payload
    );
    this.ignatiusService.putData(reqBody).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.next.emit(true);
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error(
          this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
          this.translate.instant("applications.ERROR")
        );
      }
    );
  };

  familyCareHomeAmount = (): number => {
    if (this.applicationDetails)
      return this.childCareCalService.getFamilyCareHomeAmount(
        this.form.controls.expansion_fch_capacity.value
      );
    else return 0;
  };

  infantAmount = (): number => {
    if (this.applicationDetails)
      return this.childCareCalService.getInfantAmount(
        this.form.controls.expansion_infant_capacity.value
      );
    else return 0;
  };

  toddlerAmount = (): number => {
    if (this.applicationDetails)
      return this.childCareCalService.getToddlerAmount(
        this.form.controls.expansion_toddler_capacity.value
      );
    else return 0;
  };

  schoolAgedAmount = (): number => {
    if (this.applicationDetails)
      return this.childCareCalService.getSchoolAgedAmount(
        this.form.controls.expansion_sa_capacity.value
      );
    else return 0;
  };

  staffSalaryAmount = (): number => {
    if (this.applicationDetails)
      return this.childCareCalService.getStaffSalaryAmount(
        this.form.controls.expansion_staff_salary_capacity.value
      );
    else return 0;
  };

  getTotalGrantAmount = (): string => {
    const totalGrantAmount =
      this.familyCareHomeAmount() +
      this.infantAmount() +
      this.toddlerAmount() +
      this.schoolAgedAmount() +
      this.staffSalaryAmount();

    return totalGrantAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  nextTab = () => {
    this.next.emit(false);
  };

  goBack = () => {
    this.back.emit();
  };

  handleUpload(event, docType: string) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    let base64String = "";
    if (docType === "q8_attachment") {
      reader.onload = () => {
        base64String = reader.result.toString().split(",")[1];
        this.base64q8 = base64String;
        this.q8FileName = file.name;
      };
    } else if (docType === "q9_attachment") {
      reader.onload = () => {
        base64String = reader.result.toString().split(",")[1];
        this.base64q9 = base64String;
        this.q9FileName = file.name;
      };
    }
    //  else if (docType === "q10_attachment") {
    //   reader.onload = () => {
    //     base64String = reader.result.toString().split(",")[1];
    //     this.base64q10 = base64String;
    //     this.q10FileName = file.name;
    //   };
    // }
  }

  clearSelectedFile = (controlName: string) => {
    this.form.controls[controlName].setValue("");
    if (controlName === "q8_attachment") {
      this.base64q8 = "";
      this.q8FileName = "";
    } else if (controlName === "q9_attachment") {
      this.base64q9 = "";
      this.q9FileName = "";
    } else if (controlName === "q10_attachment") {
      this.base64q10 = "";
      this.q10FileName = "";
    }
  };

  childCareExpansionFields = () => {
    if (this.isChildCareExpansion) {
      if (this.facilityDetails.facility_type === "Out of School Time") {
        this.disableFamilyCare = true;
        this.disableInfant = true;
        this.disableTodler = true;
      }
      if (
        this.facilityDetails.facility_type ===
          "Licensed Child Care Family Home" ||
        this.facilityDetails.facility_type ===
          "Registered Child Care Family Home"
      ) {
        this.disableInfant = true;
        this.disableTodler = true;
        this.disableSchoolAge = true;
      }
      if (this.facilityDetails.facility_type === "Child Care Center") {
        this.disableFamilyCare = true;
      }
    }
  };

  isInvalidChildCare = () => {
    if (this.isChildCareExpansion) {
      if (this.form.value.question4 == "Yes") {
        this.form.controls["question5"].setValidators([
          Validators.required,
          Validators.max(7),
          Validators.min(0),
        ]);
        this.form.controls["question5"].updateValueAndValidity();
        if (
          Number(this.form.value.question5) < 0 ||
          Number(this.form.value.question5) > 7
        )
          return true;
      } else {
        this.form.controls["question5"].setValidators(null);
        this.form.controls["question5"].setValidators([
          Validators.max(7),
          Validators.min(0),
        ]);
        this.form.controls["question5"].updateValueAndValidity();
      }
      if (this.form.value.question6 == "Yes") {
        this.form.controls["question7"].setValidators([
          Validators.required,
          Validators.max(5),
          Validators.min(0),
        ]);
        this.form.controls["question7"].updateValueAndValidity();
        if (
          Number(this.form.value.question5) < 0 ||
          Number(this.form.value.question5) > 5
        )
          return true;
      } else {
        this.form.controls["question7"].setValidators(null);
        this.form.controls["question7"].setValidators([
          Validators.max(5),
          Validators.min(0),
        ]);
        this.form.controls["question7"].updateValueAndValidity();
      }
      if (this.q8FileName === "" && this.q8Name === "") return true;
      if (this.q9FileName === "" && this.q9Name === "") return true;
      // if (this.form.value.question10 == "Yes") {
      //   if (this.q10FileName === "" && this.q10Name === "") return true;
      // }
    }
    return false;
  };
}
