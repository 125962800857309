import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { CacheService } from "./cache.service";
import { IgnatiusService } from "./ignatius.service";
import { ProjectSpecificService } from "./project-specific.service";

@Injectable({
  providedIn: "root",
})
export class ResolverService {
  projectSpecificData = this.projectSpecificService.getProjectSpecificData();

  constructor(
    private ignatiusService: IgnatiusService,
    private projectSpecificService: ProjectSpecificService,
    private cacheService: CacheService,
    private spinner: NgxSpinnerService
  ) {}

  /**
   * Execute resolver for data
   */
  // executeResolver = (appId) =>
  //   new Promise((res) => {
  //     forkJoin(this.getObservables(appId)).subscribe((data) => {
  //       res(data);
  //     });
  //   });

  /**
   * Get required observables
   * These are valid for both edit_renter and edit_landlord
   * @returns observables
   */
  // getObservables = (appId) => {
  //   // this.spinner.show();
  //   const observables: Array<Observable<Object[]>> = [
  //     //--------------- [0] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.applicationsData.BusinessEntityTypeMCID.toString()
  //     ),
  //     //--------------- [1] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.expensesData.ExpenseTypeMCID.toString()
  //     ),
  //     //--------------- [2] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.householdData.HouseholdNumberMCID.toString()
  //     ),
  //     //--------------- [3] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.householdData.EmploymentStatusMCID.toString()
  //     ),
  //     //--------------- [4] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.applicationsData.ParishMCID.toString()
  //     ),
  //     //--------------- [5] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.householdData.RaceMCID.toString()
  //     ),
  //     //--------------- [6] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.householdData.GenderMCID.toString()
  //     ),
  //     //--------------- [7] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.householdData.IncomeGrossAmountTypeMCID.toString()
  //     ),
  //     //--------------- [8] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.expensesData.InArrearsMCID.toString()
  //     ),
  //     //--------------- [9] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.applicationsData.PropertyTypeMCID.toString()
  //     ),
  //     //--------------- [10] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.householdData.RelationToApplicant.toString()
  //     ),
  //     //--------------- [11] ---------------//
  //     this.getDropdownValues(
  //       this.projectSpecificData.propertiesData.PropertyTypeMCID.toString()
  //     ),
  //     //--------------- [12] DETERMINE ELIGI ---------------//
  //     this.ignatiusService.getTargetTableObservable(
  //       this.projectSpecificData.appData,
  //       appId,
  //       this.projectSpecificData.applicationsData.TableId as number,
  //       this.projectSpecificData.applicationsData.RecordIdFieldId as number
  //     ),
  //   ];

  //   return observables;
  // };

  /**
   * Get dropdown values from ignatius service
   * @param id
   */
  getDropdownValues = (id: string): Observable<Object[]> => {
    if (this.cacheService.dropdowns[id])
      return of(this.cacheService.dropdowns[id]);
    const obs = this.ignatiusService.getDropdownValues(id);
    obs.subscribe((data) => {
      this.cacheService.dropdowns[id] = data;
    });
    return obs;
  };
}
