import { Injectable, KeyValueDiffers } from "@angular/core";
import { FormArray } from "@angular/forms";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { Constants } from "src/app/shared/constants";
import { MustMatch, startEndDateValidator } from "src/app/shared/validators";

@Injectable()
export class ApplicationForms {
  startEndDateValidator = startEndDateValidator;
  constructor(
    private formBuilder: FormBuilder,
    private projectSpecificService: ProjectSpecificService
  ) {}

  setupBusinessContactsForm(obj: any = {}): FormGroup {
    return new FormGroup({
      contact_first_name: new FormControl(
        obj.contact_first_name || "",
        Validators.required
      ),
      contact_last_name: new FormControl(
        obj.contact_last_name || "",
        Validators.required
      ),
      contact_mi: new FormControl(obj.contact_mi || ""),
      contact_email: new FormControl(obj.contact_email, [
        Validators.required,
        Validators.pattern(Constants.EMAIL_PATTERN),
      ]),
      contact_phone: new FormControl(
        obj.contact_phone || "",
        Validators.required
      ),
      ll_date_of_birth: new FormControl(
        obj.ll_date_of_birth || "",
        Validators.required
      ),
      ll_race: new FormControl(obj.ll_race || 0, Validators.required),
      ll_gender: new FormControl(obj.ll_gender || 0, Validators.required),
    });
  }

  setupBusinessDetailsForm(obj: any = {}): FormGroup {
    return new FormGroup({
      physical_street: new FormControl(obj.physical_street || ""),
      physical_street_2: new FormControl(obj.physical_street_2 || ""),
      physical_city: new FormControl(obj.physical_city || ""),
      physical_state: new FormControl(obj.physical_state || ""),
      physical_zip_code: new FormControl(obj.physical_zip_code || ""),
      physical_longitude: new FormControl(obj.physical_longitude || ""),
      physical_latitude: new FormControl(obj.physical_latitude || ""),
      mailing_street: new FormControl(obj.mailing_street || ""),
      mailing_street_2: new FormControl(obj.mailing_street_2 || ""),
      mailing_city: new FormControl(obj.mailing_city || ""),
      mailing_state: new FormControl(obj.mailing_state || ""),
      mailing_zip_code: new FormControl(obj.mailing_zip_code || ""),
      mailing_longitude: new FormControl(obj.mailing_longitude || ""),
      mailing_latitude: new FormControl(obj.mailing_latitude || ""),
      file_w9: new FormControl(""),
      file_name_w9: new FormControl(""),
      minority_or_women_owned: new FormControl(
        obj.minority_or_women_owned || ""
      ),
      minority_or_women_owned_registered: new FormControl(
        obj.minority_or_women_owned_registered || ""
      ),
    });
  }

  setupReceivedFundsForm(obj: any = {}): FormGroup {
    return new FormGroup({
      received_funds: new FormControl(
        obj.received_funds || "",
        Validators.required
      ),
      received_funds_ppp: new FormControl(obj.received_funds_ppp || ""),
      received_funds_sba: new FormControl(obj.received_funds_sba || ""),
      received_funds_edc: new FormControl(obj.received_funds_edc || ""),
      received_funds_any: new FormControl(obj.received_funds_any || ""),
      received_funds_donations: new FormControl(
        obj.received_funds_donations || ""
      ),
      received_funds_eidl_grant: new FormControl(
        obj.received_funds_eidl_grant || ""
      ),
      file_funds_any: new FormControl(obj.file_funds_any || ""),
      file_funds_edc: new FormControl(obj.file_funds_edc || ""),
      file_funds_ppp: new FormControl(obj.file_funds_ppp || ""),
      file_funds_sba: new FormControl(obj.file_funds_sba || ""),
      file_funds_donations: new FormControl(obj.file_funds_donations || ""),
      file_funds_eidl_grant: new FormControl(obj.file_funds_eidl_grant || ""),
      received_funds_ppp_amount: new FormControl(
        obj.received_funds_ppp_amount || "0",
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_sba_amount: new FormControl(
        obj.received_funds_sba_amount || "0",
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_edc_amount: new FormControl(
        obj.received_funds_edc_amount || "0",
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_any_amount: new FormControl(
        obj.received_funds_any_amount || "0",
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_eidl_grant_amount: new FormControl(
        obj.received_funds_eidl_grant_amount || "0",
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      received_funds_donations_amount: new FormControl(
        obj.received_funds_donations_amount || "0",
        [Validators.pattern(Constants.AMOUNT_PATTERN), Validators.required]
      ),
      file_funds_any_name: new FormControl(obj.file_funds_any_name || ""),
      file_funds_edc_name: new FormControl(obj.file_funds_edc_name || ""),
      file_funds_ppp_name: new FormControl(obj.file_funds_ppp_name || ""),
      file_funds_sba_name: new FormControl(obj.file_funds_sba_name || ""),
      file_funds_donations_name: new FormControl(
        obj.file_funds_donations_name || ""
      ),
      file_funds_eidl_grant_name: new FormControl(
        obj.file_funds_eidl_grant_name || ""
      ),
    });
  }

  setupAcknowledgementsForm(obj: any = {}): FormGroup {
    const userData = this.projectSpecificService.getUserData();
    const certify = Boolean(obj.certify && obj.certify === "True");
    const certify_2 = Boolean(obj.certify_2 && obj.certify_2 === "True");
    const certify_3 = Boolean(obj.certify_3 && obj.certify_3 === "True");
    const certify_4 = Boolean(obj.certify_4 && obj.certify_4 === "True");
    const certify_5 = Boolean(obj.certify_5 && obj.certify_5 === "True");
    const certify_6 = Boolean(obj.certify_6 && obj.certify_6 === "True");
    const certify_7 = Boolean(obj.certify_7 && obj.certify_7 === "True");
    const certify_8 = Boolean(obj.certify_8 && obj.certify_8 === "True");
    const ack = Boolean(obj.ack && obj.ack === "True");
    const ack_2 = Boolean(obj.ack_2 && obj.ack_2 === "True");
    const ack_3 = Boolean(obj.ack_3 && obj.ack_3 === "True");
    const ack_4 = Boolean(obj.ack_4 && obj.ack_4 === "True");
    const ack_5 = Boolean(obj.ack_5 && obj.ack_5 === "True");

    return new FormGroup({
      certifier_name: new FormControl(
        obj.certifier_name || "",
        Validators.required
      ),
      certifier_title: new FormControl(
        obj.certifier_title || "",
        Validators.required
      ),
      file_drivers_license: new FormControl(""),
      file_drivers_license_name: new FormControl(""),
      certified_by: new FormControl(userData.userName),
      certified_dt: new FormControl(new Date()),
      ack: new FormControl(ack, Validators.required),
      ack_2: new FormControl(ack_2, Validators.required),
      ack_3: new FormControl(ack_3, Validators.required),
      ack_4: new FormControl(ack_4, Validators.required),
      ack_5: new FormControl(ack_5, Validators.required),
      certify: new FormControl(certify, Validators.required),
      certify_2: new FormControl(certify_2, Validators.required),
      certify_3: new FormControl(certify_3, Validators.required),
      certify_4: new FormControl(certify_4, Validators.required),
      certify_5: new FormControl(certify_5, Validators.required),
      certify_6: new FormControl(certify_6, Validators.required),
      certify_7: new FormControl(certify_7, Validators.required),
      certify_8: new FormControl(certify_8, Validators.required),
    });
  }

  setupPropertyForm(obj: any = {}): FormGroup {
    return this.formBuilder.group({
      property_name: new FormControl(
        obj.property_name || "",
        Validators.required
      ),
      property_type: new FormControl(
        obj.property_type || "",
        Validators.required
      ),
      related_applications: new FormControl(obj.related_applications || ""),
      property_description: new FormControl(
        obj.property_description || "",
        Validators.required
      ),
      file_deed_or_taxes: new FormControl(obj.file_deed_or_taxes || ""),
      file_deed_or_taxes_name: new FormControl(
        obj.file_deed_or_taxes_name || ""
      ),
      file_deed_or_taxes_url: new FormControl(obj.file_deed_or_taxes_url || ""),
      rid: new FormControl(obj.id || ""),
      property_street: new FormControl(obj.property_street || ""),
      property_street_2: new FormControl(obj.property_street_2 || ""),
      property_city: new FormControl(obj.property_city || ""),
      property_state: new FormControl(obj.property_state || ""),
      property_zip_code: new FormControl(obj.property_zip_code || ""),
      property_longitude: new FormControl(obj.property_longitude || ""),
      property_latitude: new FormControl(obj.property_latitude || ""),
    });
  }

  setupRentersForm(obj: any = {}): FormGroup {
    return this.formBuilder.group({
      first_name: new FormControl(obj.first_name || "", Validators.required),
      middle_name: new FormControl(obj.middle_name || ""),
      last_name: new FormControl(obj.last_name || "", Validators.required),
      unit_number: new FormControl(obj.unit_number || ""),
      monthly_rent: new FormControl(obj.monthly_rent || ""),
      amount_in_arrears: new FormControl(obj.amount_in_arrears || ""),
      related_applications: new FormControl(obj.related_applications || ""),
      file_lease: new FormControl(obj.file_lease || ""),
      file_lease_name: new FormControl(obj.file_lease_name || ""),
      file_lease_url: new FormControl(obj.file_lease_url || ""),
      related_properties: new FormControl(
        obj.related_properties || "",
        Validators.required
      ),
      rid: new FormControl(obj.id || ""),
    });
  }

  setupBankDetailsForm(obj: any = {}): FormGroup {
    return this.formBuilder.group({
      ach_or_check: new FormControl(obj.ach_or_check || ""),
      check_payee_name: new FormControl(obj.check_payee_name || ""),
    });
  }

  setupApproveRenterForm(rentId, renters: any = {}): FormGroup {
    for (const iterator of renters) {
      if (rentId === iterator.id) {
        return new FormGroup({
          id: new FormControl(rentId),
          contact_first_name: new FormControl(iterator.contact_first_name),
          contact_mi: new FormControl(iterator.contact_mi),
          contact_last_name: new FormControl(iterator.contact_last_name),
          physical_street: new FormControl(iterator.physical_street),
          physical_street_2: new FormControl(iterator.physical_street_2),
          physical_city: new FormControl(iterator.physical_city),
          physical_state: new FormControl(iterator.physical_state),
          physical_zip_code: new FormControl(iterator.physical_zip_code),
          parish: new FormControl(iterator.parish),
          contact_email: new FormControl(iterator.contact_email),
          contact_phone: new FormControl(iterator.contact_phone),
          confirmation_number: new FormControl(iterator.confirmation_number),
          monthly_rent: new FormControl(iterator.total_monthly_rent),
          past_due_amount: new FormControl(iterator.total_rent_past_due),
          ll_confirmation_number: new FormControl("", Validators.required),
          ll_monthly_rent: new FormControl("", Validators.required),
          ll_amount_past_due: new FormControl("", Validators.required),
          ll_other_fed_fund_received: new FormControl("", Validators.required),
          verification: new FormControl(false, Validators.required),
          landlord_status: new FormControl("Request Sent"),
          landlord_id: new FormControl("", Validators.required),
          property_id: new FormControl("", Validators.required),
          file_lease_ll: new FormControl(""),
          file_name_lease_ll: new FormControl(""),
        });
      }
    }
  }

  setupInviteRenterForm(email, appId): FormGroup {
    return new FormGroup({
      landlord_email: new FormControl(email, [
        Validators.required,
        Validators.pattern(Constants.EMAIL_PATTERN),
      ]),
      renter_email: new FormControl("", [
        Validators.required,
        Validators.pattern(Constants.EMAIL_PATTERN),
      ]),
      related_applications: new FormControl("", [Validators.required]),
    });
  }

  setupTicketForm(email, appId): FormGroup {
    return new FormGroup({
      subject: new FormControl("", [Validators.required]),
      details: new FormControl("", [Validators.required]),
      related_applications: new FormControl(appId, [Validators.required]),
      type: new FormControl("Ticket", [Validators.required]),
      status: new FormControl("Open", [Validators.required]),
      applicant_send_to: new FormControl(email, [
        Validators.required,
        Validators.pattern(Constants.EMAIL_PATTERN),
      ]),
    });
  }

  // -------------------- RENTER FORMS ----------------

  setupHouseholdForm(
    householdNumber,
    household: Array<any>,
    applicationHousehold: Array<any>,
    applicationId: string
  ) {
    const formDataArray = new FormArray([]);
    var found = false;

    for (const iterator of household) {
      let existingHH: any = null;

      // console.log(householdNumber)
      // console.log((applicationHousehold));

      //alert(householdNumber)
      for (const innerIterator of applicationHousehold) {
        // console.log(innerIterator)
        if (!householdNumber) {
          householdNumber = "";
        }
        if (
          householdNumber.toString() ===
          innerIterator.household_number.toString().replace("Household ", "")
        ) {
          found = true;
          existingHH = Object.assign({}, innerIterator);
          break;
        }
        if (found) {
          break;
        }
      }

      // for (const innerIterator of applicationHousehold) {
      //     existingHH = Object.assign({}, innerIterator);
      //     break;
      // }

      const household_number = existingHH ? existingHH.household_number : "";
      const first_name = existingHH ? existingHH.first_name : "";
      const middle_initial = existingHH ? existingHH.middle_initial : "";
      const last_name = existingHH ? existingHH.last_name : "";
      const date_of_birth = existingHH ? existingHH.date_of_birth : "";
      const last_date_worked = existingHH ? existingHH.last_date_worked : "";
      const employment_status = existingHH ? existingHH.employment_status : 0;
      const race_ethnicity = existingHH ? existingHH.race_ethnicity : 0;
      const gender = existingHH ? existingHH.gender : 0;
      const income_gross = existingHH
        ? Boolean(existingHH.income_gross === "True")
        : "False";
      const income_gross_amount = existingHH
        ? existingHH.income_gross_amount
        : 0;
      const income_gross_amount_2 = existingHH
        ? existingHH.income_gross_amount_2
        : 0;
      const income_gross_amount_type = existingHH
        ? existingHH.income_gross_amount_type
        : 0;
      const income_gross_file_url = existingHH
        ? existingHH.income_gross_file_url
        : "";
      const income_gross_file_name = existingHH
        ? existingHH.income_gross_file
        : "";
      const income_gross_file_2_url = existingHH
        ? existingHH.income_gross_file_2_url
        : "";
      const income_gross_file_2_name = existingHH
        ? existingHH.income_gross_file_2
        : "";
      const income_gross_file_3_url = existingHH
        ? existingHH.income_gross_file_3_url
        : "";
      const income_gross_file_3_name = existingHH
        ? existingHH.income_gross_file_3
        : "";
      const income_gross_file_4_url = existingHH
        ? existingHH.income_gross_file_4_url
        : "";
      const income_gross_file_4_name = existingHH
        ? existingHH.income_gross_file_4
        : "";
      const income_gross_file_5_url = existingHH
        ? existingHH.income_gross_file_5_url
        : "";
      const income_gross_file_5_name = existingHH
        ? existingHH.income_gross_file_5
        : "";
      const income_in_lieu = existingHH
        ? Boolean(existingHH.income_in_lieu === "True")
        : "False";
      const income_in_lieu_amount = existingHH
        ? existingHH.income_in_lieu_amount
        : 0;
      const income_in_lieu_file_url = existingHH
        ? existingHH.income_in_lieu_file_url
        : "";
      const income_in_lieu_of_earnings_file_name = existingHH
        ? existingHH.income_in_lieu_file
        : "";
      const income_pymts_annts = existingHH
        ? Boolean(existingHH.income_pymts_annts === "True")
        : "False";
      const income_pymts_annts_amount = existingHH
        ? existingHH.income_pymts_annts_amount
        : 0;
      const income_pymts_annts_file_url = existingHH
        ? existingHH.income_pymts_annts_file_url
        : "";
      const income_payments_annuities_file_name = existingHH
        ? existingHH.income_pymts_annts_file
        : "";
      const income_government = existingHH
        ? Boolean(existingHH.income_government === "True")
        : "False";
      const income_government_amount = existingHH
        ? existingHH.income_government_amount
        : 0;
      const income_government_file_url = existingHH
        ? existingHH.income_government_file_url
        : "";
      const income_government_file_name = existingHH
        ? existingHH.income_government_file
        : "";
      const income_alimony = existingHH
        ? Boolean(existingHH.income_alimony === "True")
        : "False";
      const income_alimony_amount = existingHH
        ? existingHH.income_alimony_amount
        : 0;
      const income_alimony_file_url = existingHH
        ? existingHH.income_alimony_file_url
        : "";
      const income_alimony_file_name = existingHH
        ? existingHH.income_alimony_file
        : "";
      const income_other = existingHH
        ? Boolean(existingHH.income_other === "True")
        : "False";
      const income_other_amount = existingHH
        ? existingHH.income_other_amount
        : 0;
      const income_other_file_url = existingHH
        ? existingHH.income_other_file_url
        : "";
      const income_other_file_name = existingHH
        ? existingHH.income_other_file
        : "";
      const used = existingHH ? true : false;
      const id = existingHH ? existingHH.id : null;
      const relation_to_applicant = existingHH
        ? existingHH.relation_to_applicant || 0
        : 0;
      const socsecnbr = existingHH ? existingHH.socsecnbr : "";
      const income_gross_no_file = existingHH
        ? Boolean(existingHH.income_gross_no_file === "True")
        : "False";
      const status = existingHH
        ? Boolean(existingHH.status === "True")
        : "False";
      const income_gig_economy = existingHH
        ? Boolean(existingHH.income_gig_economy === "True")
        : "False";
      const income_gig_economy_amount = existingHH
        ? existingHH.income_gig_economy_amount
        : 0;
      const income_gig_economy_file_url = existingHH
        ? existingHH.income_gig_economy_file_url
        : "";
      const income_gig_economy_file_name = existingHH
        ? existingHH.income_gig_economy_file
        : "";
      const income_secondary = existingHH
        ? Boolean(existingHH.income_secondary === "True")
        : "False";
      const income_secondary_amount = existingHH
        ? existingHH.income_secondary_amount
        : 0;
      const income_secondary_file_url = existingHH
        ? existingHH.income_secondary_file_url
        : "";
      const income_secondary_file_name = existingHH
        ? existingHH.income_secondary_file
        : "";
      //const url = existingHH ? existingHH.document_file_url : '';
      //const exstDocName = existingHH ? existingHH.document_file : '';

      const controls = new FormGroup({
        id: new FormControl(id),
        household_number: new FormControl(householdNumber),
        related_applications: new FormControl(applicationId),
        first_name: new FormControl(first_name),
        middle_initial: new FormControl(middle_initial),
        last_name: new FormControl(last_name),
        date_of_birth: new FormControl(date_of_birth),
        employment_status: new FormControl(
          employment_status ? employment_status : 0
        ),
        race_ethnicity: new FormControl(race_ethnicity ? race_ethnicity : 0),
        gender: new FormControl(gender ? gender : 0),
        last_date_worked: new FormControl(last_date_worked), //Validators.pattern(Constants.AMOUNT_PATTERN)),
        income_gross: new FormControl(income_gross),
        income_gross_amount: new FormControl(
          income_gross_amount,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_gross_amount_2: new FormControl(
          income_gross_amount_2,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_gross_amount_type: new FormControl(
          income_gross_amount_type ? income_gross_amount_type : 0
        ),
        income_gross_file: new FormControl(""),
        income_gross_file_url: new FormControl(income_gross_file_url),
        income_gross_file_name: new FormControl(income_gross_file_name),
        income_gross_file_2: new FormControl(""),
        income_gross_file_2_url: new FormControl(income_gross_file_2_url),
        income_gross_file_2_name: new FormControl(income_gross_file_2_name),
        income_gross_file_3: new FormControl(""),
        income_gross_file_3_url: new FormControl(income_gross_file_3_url),
        income_gross_file_3_name: new FormControl(income_gross_file_3_name),
        income_gross_file_4: new FormControl(""),
        income_gross_file_4_url: new FormControl(income_gross_file_4_url),
        income_gross_file_4_name: new FormControl(income_gross_file_4_name),
        income_gross_file_5: new FormControl(""),
        income_gross_file_5_url: new FormControl(income_gross_file_5_url),
        income_gross_file_5_name: new FormControl(income_gross_file_5_name),
        income_in_lieu: new FormControl(income_in_lieu),
        income_in_lieu_amount: new FormControl(
          income_in_lieu_amount,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_in_lieu_file: new FormControl(""),
        income_in_lieu_file_url: new FormControl(income_in_lieu_file_url),
        income_in_lieu_of_earnings_file_name: new FormControl(
          income_in_lieu_of_earnings_file_name
        ),
        income_pymts_annts: new FormControl(income_pymts_annts),
        income_pymts_annts_amount: new FormControl(
          income_pymts_annts_amount,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_pymts_annts_file: new FormControl(""),
        income_pymts_annts_file_url: new FormControl(
          income_pymts_annts_file_url
        ),
        income_payments_annuities_file_name: new FormControl(
          income_payments_annuities_file_name
        ),
        income_government: new FormControl(income_government),
        income_government_amount: new FormControl(
          income_government_amount,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_government_file: new FormControl(""),
        income_government_file_url: new FormControl(income_government_file_url),
        income_government_file_name: new FormControl(
          income_government_file_name
        ),
        income_alimony: new FormControl(income_alimony),
        income_alimony_amount: new FormControl(
          income_alimony_amount,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_alimony_file: new FormControl(""),
        income_alimony_file_url: new FormControl(income_alimony_file_url),
        income_alimony_file_name: new FormControl(income_alimony_file_name),
        income_other: new FormControl(income_other),
        income_other_amount: new FormControl(
          income_other_amount,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_other_file: new FormControl(""),
        income_other_file_url: new FormControl(income_other_file_url),
        income_other_file_name: new FormControl(income_other_file_name),
        income_gig_economy: new FormControl(income_gig_economy),
        income_gig_economy_amount: new FormControl(
          income_gig_economy_amount,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_gig_economy_file: new FormControl(""),
        income_gig_economy_file_url: new FormControl(
          income_gig_economy_file_url
        ),
        income_gig_economy_file_name: new FormControl(
          income_gig_economy_file_name
        ),
        income_secondary: new FormControl(income_secondary),
        income_secondary_amount: new FormControl(
          income_secondary_amount,
          Validators.pattern(Constants.AMOUNT_PATTERN)
        ),
        income_secondary_file: new FormControl(""),
        income_secondary_file_url: new FormControl(income_secondary_file_url),
        income_secondary_file_name: new FormControl(income_secondary_file_name),
        used: new FormControl(used),
        relation_to_applicant: new FormControl(relation_to_applicant),
        socsecnbr: new FormControl(socsecnbr),
        income_gross_no_file: new FormControl(income_gross_no_file),
        status: new FormControl(status),
      });
      formDataArray.push(controls);
    }

    return new FormGroup({
      household: formDataArray,
    });
  }
  setupHouseholdAddForm(
    householdNumber,
    household: Array<any>,
    applicationId: string
  ) {
    return new FormGroup({
      id: new FormControl(0),
      household_number: new FormControl(householdNumber),
      related_applications: new FormControl(applicationId),
      first_name: new FormControl(""),
      middle_initial: new FormControl(""),
      last_name: new FormControl(""),
      date_of_birth: new FormControl(null),
      employment_status: new FormControl(0),
      race_ethnicity: new FormControl(0),
      gender: new FormControl(0),
      last_date_worked: new FormControl(null), //Validators.pattern(Constants.AMOUNT_PATTERN)),
      income_gross: new FormControl(false),
      income_gross_amount: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_gross_amount_2: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_gross_amount_type: new FormControl(0),
      income_gross_file: new FormControl(""),
      income_gross_file_url: new FormControl(""),
      income_gross_file_name: new FormControl(""),
      income_gross_file_2: new FormControl(""),
      income_gross_file_2_url: new FormControl(""),
      income_gross_file_2_name: new FormControl(""),
      income_gross_file_3: new FormControl(""),
      income_gross_file_3_url: new FormControl(""),
      income_gross_file_3_name: new FormControl(""),
      income_gross_file_4: new FormControl(""),
      income_gross_file_4_url: new FormControl(""),
      income_gross_file_4_name: new FormControl(""),
      income_gross_file_5: new FormControl(""),
      income_gross_file_5_url: new FormControl(""),
      income_gross_file_5_name: new FormControl(""),
      income_in_lieu: new FormControl(false),
      income_in_lieu_amount: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_in_lieu_file: new FormControl(""),
      income_in_lieu_file_url: new FormControl(""),
      income_in_lieu_of_earnings_file_name: new FormControl(""),
      income_pymts_annts: new FormControl(false),
      income_pymts_annts_amount: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_pymts_annts_file: new FormControl(""),
      income_payments_annuities_url: new FormControl(""),
      income_payments_annuities_file_name: new FormControl(""),
      income_government: new FormControl(false),
      income_government_amount: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_government_file: new FormControl(""),
      income_government_file_url: new FormControl(""),
      income_government_file_name: new FormControl(""),
      income_alimony: new FormControl(false),
      income_alimony_amount: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_alimony_file: new FormControl(""),
      income_alimony_file_url: new FormControl(""),
      income_alimony_file_name: new FormControl(""),
      income_other: new FormControl(false),
      income_other_amount: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_other_file: new FormControl(""),
      income_other_file_url: new FormControl(""),
      income_other_file_name: new FormControl(""),
      income_gig_economy: new FormControl(false),
      income_gig_economy_amount: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_gig_economy_file: new FormControl(""),
      income_gig_economy_file_url: new FormControl(""),
      income_gig_economy_file_name: new FormControl(""),
      income_secondary: new FormControl(false),
      income_secondary_amount: new FormControl(
        0,
        Validators.pattern(Constants.AMOUNT_PATTERN)
      ),
      income_secondary_file: new FormControl(""),
      income_secondary_file_url: new FormControl(""),
      income_secondary_file_name: new FormControl(""),
      used: new FormControl(true),
      relation_to_applicant: new FormControl(0),
      socsecnbr: new FormControl(""),
      income_gross_no_file: new FormControl(),
      status: new FormControl(),
    });
  }
  setupHouseholdDeleteForm(
    householdNumber,
    household: Array<any>,
    applicationHousehold: Array<any>,
    applicationId: string
  ) {
    const formDataArray = new FormArray([]);
    var found = false;

    for (const iterator of household) {
      let existingHH: any = null;

      for (const innerIterator of applicationHousehold) {
        if (
          householdNumber.toString() ===
          innerIterator.household_number.toString().replace("Household ", "")
        ) {
          found = true;
          existingHH = Object.assign({}, innerIterator);
          break;
        }
        if (found) {
          break;
        }
      }

      const first_name = existingHH ? existingHH.first_name : "";
      const middle_initial = existingHH ? existingHH.middle_initial : "";
      const last_name = existingHH ? existingHH.last_name : "";
      const date_of_birth = existingHH ? existingHH.date_of_birth : "";
      const employment_status = existingHH ? existingHH.employment_status : 0;
      const race_ethnicity = existingHH ? existingHH.race_ethnicity : 0;
      const gender = existingHH ? existingHH.gender : 0;
      const id = existingHH ? existingHH.id : null;

      const controls = new FormGroup({
        id: new FormControl(id),
        related_applications: new FormControl(applicationId),
        first_name: new FormControl(first_name),
        middle_initial: new FormControl(middle_initial),
        last_name: new FormControl(last_name),
        date_of_birth: new FormControl(date_of_birth),
        employment_status: new FormControl(employment_status),
        race_ethnicity: new FormControl(race_ethnicity),
        gender: new FormControl(gender),
      });
      formDataArray.push(controls);
    }

    return new FormGroup({
      household: formDataArray,
    });
  }

  setupExpensesForm(
    expenseNumber,
    expenses: Array<any>,
    applicationExpenses: Array<any>,
    applicationId: string
  ) {
    const formDataArray = new FormArray([]);
    var found = false;

    for (const iterator of applicationExpenses) {
      let existingExp: any = null;

      for (const innerIterator of applicationExpenses) {
        if (expenseNumber.toString() === innerIterator.id) {
          found = true;
          existingExp = Object.assign({}, innerIterator);
          break;
        }
        if (found) {
          break;
        }
      }

      const expense_type = existingExp ? existingExp.expense_type : "";
      const in_arrears = existingExp ? existingExp.in_arrears : "";
      const amount_monthly = existingExp ? existingExp.amount_monthly : 0;
      const lease_start_date = existingExp ? existingExp.lease_start_date : "";
      const lease_end_date = existingExp ? existingExp.lease_end_date : "";
      const months_in_arrears = existingExp ? existingExp.months_in_arrears : 0;
      const total_arrears = existingExp ? existingExp.total_arrears : 0;
      //const amount_past_due = existingExp ? existingExp.amount_past_due : 0;
      //const provider = existingExp ? existingExp.provider : '';
      //const name_on_account = existingExp ? existingExp.name_on_account : '';
      //const account_number = existingExp ? existingExp.account_number : '';
      //const provider_zip_code = existingExp ? existingExp.provider_zip_code : '';
      const used = existingExp ? true : false;
      const id = existingExp ? existingExp.id : null;
      const url = existingExp ? existingExp.document_file_url : "";
      const exstDocName = existingExp ? existingExp.document_file : "";
      const past_due_amt_apr_2020 = existingExp
        ? existingExp.past_due_amt_apr_2020
        : 0;
      const past_due_amt_may_2020 = existingExp
        ? existingExp.past_due_amt_may_2020
        : 0;
      const past_due_amt_jun_2020 = existingExp
        ? existingExp.past_due_amt_jun_2020
        : 0;
      const past_due_amt_jul_2020 = existingExp
        ? existingExp.past_due_amt_jul_2020
        : 0;
      const past_due_amt_aug_2020 = existingExp
        ? existingExp.past_due_amt_aug_2020
        : 0;
      const past_due_amt_sep_2020 = existingExp
        ? existingExp.past_due_amt_sep_2020
        : 0;
      const past_due_amt_oct_2020 = existingExp
        ? existingExp.past_due_amt_oct_2020
        : 0;
      const past_due_amt_nov_2020 = existingExp
        ? existingExp.past_due_amt_nov_2020
        : 0;
      const past_due_amt_dec_2020 = existingExp
        ? existingExp.past_due_amt_dec_2020
        : 0;
      const past_due_amt_jan_2021 = existingExp
        ? existingExp.past_due_amt_jan_2021
        : 0;
      const past_due_amt_feb_2021 = existingExp
        ? existingExp.past_due_amt_feb_2021
        : 0;
      const past_due_amt_mar_2021 = existingExp
        ? existingExp.past_due_amt_mar_2021
        : 0;
      const past_due_amt_apr_2021 = existingExp
        ? existingExp.past_due_amt_apr_2021
        : 0;
      const previously_received_funds = existingExp
        ? existingExp.previously_received_funds
        : 0;
      //const past_due_amt_may_2021 = existingExp ? existingExp.past_due_amt_may_2021 : 0;
      // const month_type_april_2020 = existingExp ? existingExp.month_type_april_2020 : 0;
      // const month_type_may_2020 = existingExp ? existingExp.month_type_may_2020 : 0;
      // const month_type_june_2020 = existingExp ? existingExp.month_type_june_2020 : 0;
      // const month_type_july_2020 = existingExp ? existingExp.month_type_july_2020 : 0;
      // const month_type_august_2020 = existingExp ? existingExp.month_type_august_2020 : 0;
      // const month_type_september_2020 = existingExp ? existingExp.month_type_september_2020 : 0;
      // const month_type_october_2020 = existingExp ? existingExp.month_type_october_2020 : 0;
      // const month_type_november_2020 = existingExp ? existingExp.month_type_november_2020 : 0;
      // const month_type_december_2020 = existingExp ? existingExp.month_type_december_2020 : 0;
      // const month_type_january_2021 = existingExp ? existingExp.month_type_january_2021 : 0;
      // const month_type_february_2021 = existingExp ? existingExp.month_type_february_2021 : 0;
      // const month_type_march_2021 = existingExp ? existingExp.month_type_march_2021 : 0;
      //const month_type_april_2021 = existingExp ? existingExp.month_type_april_2021 : 0;
      //const month_type_may_2021 = existingExp ? existingExp.month_type_may_2021 : 0;

      const controls = new FormGroup(
        {
          id: new FormControl(id),
          related_applications: new FormControl(applicationId),
          expense_type: new FormControl(expense_type, Validators.required),
          in_arrears: new FormControl(in_arrears || "", Validators.required),
          amount_monthly: new FormControl(
            amount_monthly,
            Validators.pattern(Constants.AMOUNT_PATTERN)
          ),
          lease_start_date: new FormControl(lease_start_date),
          lease_end_date: new FormControl(lease_end_date),
          months_in_arrears: new FormControl(
            months_in_arrears,
            Validators.pattern(Constants.AMOUNT_PATTERN)
          ),
          total_arrears: new FormControl(
            total_arrears,
            Validators.pattern(Constants.AMOUNT_PATTERN)
          ),
          //amount_past_due: new FormControl(amount_past_due, Validators.pattern(Constants.AMOUNT_PATTERN)),
          //provider: new FormControl(provider),
          //name_on_account: new FormControl(name_on_account),
          //account_number: new FormControl(account_number),
          //provider_zip_code: new FormControl(provider_zip_code),
          document_file: new FormControl(""),
          document_file_url: new FormControl(url),
          document_name: new FormControl(exstDocName),
          used: new FormControl(used),
          past_due_amt_apr_2020: new FormControl(past_due_amt_apr_2020),
          past_due_amt_may_2020: new FormControl(past_due_amt_may_2020),
          past_due_amt_jun_2020: new FormControl(past_due_amt_jun_2020),
          past_due_amt_jul_2020: new FormControl(past_due_amt_jul_2020),
          past_due_amt_aug_2020: new FormControl(past_due_amt_aug_2020),
          past_due_amt_sep_2020: new FormControl(past_due_amt_sep_2020),
          past_due_amt_oct_2020: new FormControl(past_due_amt_oct_2020),
          past_due_amt_nov_2020: new FormControl(past_due_amt_nov_2020),
          past_due_amt_dec_2020: new FormControl(past_due_amt_dec_2020),
          past_due_amt_jan_2021: new FormControl(past_due_amt_jan_2021),
          past_due_amt_feb_2021: new FormControl(past_due_amt_feb_2021),
          past_due_amt_mar_2021: new FormControl(past_due_amt_mar_2021),
          past_due_amt_apr_2021: new FormControl(past_due_amt_apr_2021),
          previously_received_funds: new FormControl(previously_received_funds),
          // past_due_amt_may_2021: new FormControl(past_due_amt_may_2021),
          // month_type_april_2020: new FormControl(month_type_april_2020),
          // month_type_may_2020: new FormControl(month_type_may_2020),
          // month_type_june_2020: new FormControl(month_type_june_2020),
          // month_type_july_2020: new FormControl(month_type_july_2020),
          // month_type_august_2020: new FormControl(month_type_august_2020),
          // month_type_september_2020: new FormControl(month_type_september_2020),
          // month_type_october_2020: new FormControl(month_type_october_2020),
          // month_type_november_2020: new FormControl(month_type_november_2020),
          // month_type_december_2020: new FormControl(month_type_december_2020),
          // month_type_january_2021: new FormControl(month_type_january_2021),
          // month_type_february_2021: new FormControl(month_type_february_2021),
          // month_type_march_2021: new FormControl(month_type_march_2021),
          // month_type_april_2021: new FormControl(month_type_april_2021),
          // month_type_may_2021: new FormControl(month_type_may_2021),
        },
        {
          validators: [this.startEndDateValidator],
        }
      );
      formDataArray.push(controls);
    }

    return new FormGroup({
      expenses: formDataArray,
    });
  }
  setupExpensesAddForm(applicationId: string) {
    //expenseNumber, expenses: Array<any>, applicationExpenses: Array<any>,
    return new FormGroup(
      {
        id: new FormControl(0),
        related_applications: new FormControl(applicationId),
        expense_type: new FormControl("", Validators.required),
        //account_number: new FormControl(''),
        in_arrears: new FormControl("", Validators.required),
        amount_monthly: new FormControl(0),
        lease_start_date: new FormControl(""),
        lease_end_date: new FormControl(""),
        months_in_arrears: new FormControl(0),
        total_arrears: new FormControl(0),
        //name_on_account: new FormControl(''),
        //provider: new FormControl(''),
        //provider_zip_code: new FormControl('')
        document_file: new FormControl(""),
        document_file_url: new FormControl(""),
        document_name: new FormControl(""),
        past_due_amt_apr_2020: new FormControl(0),
        past_due_amt_may_2020: new FormControl(0),
        past_due_amt_jun_2020: new FormControl(0),
        past_due_amt_jul_2020: new FormControl(0),
        past_due_amt_aug_2020: new FormControl(0),
        past_due_amt_sep_2020: new FormControl(0),
        past_due_amt_oct_2020: new FormControl(0),
        past_due_amt_nov_2020: new FormControl(0),
        past_due_amt_dec_2020: new FormControl(0),
        past_due_amt_jan_2021: new FormControl(0),
        past_due_amt_feb_2021: new FormControl(0),
        past_due_amt_mar_2021: new FormControl(0),
        past_due_amt_apr_2021: new FormControl(0),
        previously_received_funds: new FormControl(0),
        // past_due_amt_may_2021: new FormControl(0),
        // month_type_april_2020: new FormControl(0),
        // month_type_may_2020: new FormControl(0),
        // month_type_june_2020: new FormControl(0),
        // month_type_july_2020: new FormControl(0),
        // month_type_august_2020: new FormControl(0),
        // month_type_september_2020: new FormControl(0),
        // month_type_october_2020: new FormControl(0),
        // month_type_november_2020: new FormControl(0),
        // month_type_december_2020: new FormControl(0),
        // month_type_january_2021: new FormControl(0),
        // month_type_february_2021: new FormControl(0),
        // month_type_march_2021: new FormControl(0),
        // month_type_april_2021: new FormControl(0),
        // month_type_may_2021: new FormControl(0),
      },
      {
        validators: [this.startEndDateValidator],
      }
    );
  }
  setupExpensesDeleteForm(
    id: any,
    expense: Array<any>,
    applicationExpenses: Array<any>,
    applicationId: string
  ) {
    const expenseId = id;
    const formDataArray = new FormArray([]);
    var found = false;

    for (const iterator of expense) {
      let existingExp: any = null;

      for (const innerIterator of applicationExpenses) {
        if (id === innerIterator.id) {
          found = true;
          existingExp = Object.assign({}, innerIterator);
          break;
        }
        if (found) {
          break;
        }
      }

      const expense_type = existingExp ? existingExp.expense_type : "";
      const amount_monthly = existingExp ? existingExp.amount_monthly : "";
      // const provider = existingExp ? existingExp.provider : '';

      const controls = new FormGroup({
        id: new FormControl(expenseId),
        expense_type: new FormControl(expense_type, Validators.required),
        amount_monthly: new FormControl(amount_monthly),
        // provider: new FormControl(provider),
      });
      formDataArray.push(controls);
    }

    return new FormGroup({
      expense: formDataArray,
    });
  }
  // setupPastDueAmountsAddForm(months: Array<any>, applicationId: string) {

  //   const formDataArray = new FormArray([]);
  //   var found = false;

  //   for (const iterator of months) {

  //     // let existingPDA: any = null;

  //     // for (const innerIterator of applicationPastDueAmounts) {
  //     //   if (pastDueAmountNumber.toString() === innerIterator.id) {
  //     //     found = true;
  //     //     existingPDA = Object.assign({}, innerIterator);
  //     //     break;
  //     //   }
  //     //   if (found) {
  //     //     break;
  //     //   }
  //     // }

  //     //const household_number = existingPDA ? existingPDA.household_number : '';
  //     // const month = existingPDA ? existingPDA.month : '';
  //     // const past_due_amount = existingPDA ? existingPDA.past_due_amount : 0;
  //     // const used = existingPDA ? true : false;
  //     // const id = existingPDA ? existingPDA.id : null;

  //     const controls = new FormGroup({
  //       id: new FormControl(0),
  //       //household_number: new FormControl(pastDueAmountNumber),
  //       related_applications: new FormControl(applicationId),
  //       month: new FormControl(iterator),
  //       past_due_amount: new FormControl(0),
  //       month_type: new FormControl(0),
  //       used: new FormControl('')
  //     })
  //     formDataArray.push(controls);
  //   }

  //   return new FormGroup({
  //     past_due_amounts: formDataArray
  //   })
  // }

  setupRentalDocumentsForm(obj: any = {}): FormGroup {
    return new FormGroup({
      file_lease: new FormControl(""),
      file_name_lease: new FormControl(""),
      file_past_due_rent: new FormControl(""),
      file_name_past_due_rent: new FormControl(""),
      file_eviction_notice: new FormControl(""),
      file_name_eviction_notice: new FormControl(""),
      file_past_due_utility: new FormControl(""),
      file_name_past_due_utility: new FormControl(""),
      file_other: new FormControl(""),
      file_name_other: new FormControl(""),
    });
  }
  setupContactForm(obj: any = {}): FormGroup {
    return new FormGroup({
      contact_first_name: new FormControl(
        obj.contact_first_name || "",
        Validators.required
      ),
      contact_last_name: new FormControl(
        obj.contact_last_name || "",
        Validators.required
      ),
      contact_mi: new FormControl(obj.contact_mi || ""),
      contact_email: new FormControl(obj.contact_email, [
        Validators.required,
        Validators.pattern(Constants.EMAIL_PATTERN),
      ]),
      contact_phone: new FormControl(
        obj.contact_phone || "",
        Validators.required
      ),
    });
  }
  setupAddressesForm(obj: any = {}): FormGroup {
    return new FormGroup({
      physical_street: new FormControl(obj.physical_street || ""),
      physical_street_2: new FormControl(obj.physical_street_2 || ""),
      physical_city: new FormControl(obj.physical_city || ""),
      physical_state: new FormControl(obj.physical_state || ""),
      physical_zip_code: new FormControl(obj.physical_zip_code || ""),
      physical_longitude: new FormControl(obj.physical_longitude || ""),
      physical_latitude: new FormControl(obj.physical_latitude || ""),
      parish: new FormControl(obj.parish || 0),
      number_of_bedrooms: new FormControl(
        obj.number_of_bedrooms || "-1",
        Validators.required
      ),
      unit_size: new FormControl(
        obj.unit_size || "Not Selected",
        Validators.required
      ),
    });
  }
  setupLandlordForm(obj: any = {}): FormGroup {
    return new FormGroup({
      landlord_first_name: new FormControl(
        obj.landlord_first_name || "",
        Validators.required
      ),
      landlord_last_name: new FormControl(
        obj.landlord_last_name || "",
        Validators.required
      ),
      landlord_email: new FormControl(obj.landlord_email, [
        Validators.required,
        Validators.pattern(Constants.EMAIL_PATTERN),
      ]),
      landlord_phone: new FormControl(
        obj.landlord_phone || "",
        Validators.required
      ),
      property_name: new FormControl(obj.property_name || ""),
      property_type: new FormControl(obj.property_type || 0),
      confirmation_number: new FormControl(obj.confirmation_number || ""),
    });
  }

  setupRenterAcknowledgementsForm(obj: any = {}): FormGroup {
    const userData = this.projectSpecificService.getUserData();
    const certify = Boolean(obj.certify && obj.certify === "True");
    const certify_2 = Boolean(obj.certify_2 && obj.certify_2 === "True");
    const certify_3 = Boolean(obj.certify_3 && obj.certify_3 === "True");
    const certify_4 = Boolean(obj.certify_4 && obj.certify_4 === "True");
    const certify_5 = Boolean(obj.certify_5 && obj.certify_5 === "True");
    const certify_6 = Boolean(obj.certify_6 && obj.certify_6 === "True");
    const certify_7 = Boolean(obj.certify_7 && obj.certify_7 === "True");
    const ack = Boolean(obj.ack && obj.ack === "True");
    const ack_2 = Boolean(obj.ack_2 && obj.ack_2 === "True");
    const ack_3 = Boolean(obj.ack_3 && obj.ack_3 === "True");
    const ack_4 = Boolean(obj.ack_4 && obj.ack_4 === "True");

    return new FormGroup({
      certifier_name: new FormControl(
        obj.certifier_name || "",
        Validators.required
      ),
      file_drivers_license: new FormControl(""),
      file_drivers_license_name: new FormControl(""),
      certified_by: new FormControl(userData.userName),
      certified_dt: new FormControl(new Date()),
      ack: new FormControl(ack, Validators.required),
      ack_2: new FormControl(ack_2, Validators.required),
      ack_3: new FormControl(ack_3, Validators.required),
      ack_4: new FormControl(ack_4, Validators.required),
      certify: new FormControl(certify, Validators.required),
      certify_2: new FormControl(certify_2, Validators.required),
      certify_3: new FormControl(certify_3, Validators.required),
      certify_4: new FormControl(certify_4, Validators.required),
      certify_5: new FormControl(certify_5, Validators.required),
      certify_6: new FormControl(certify_6, Validators.required),
      certify_7: new FormControl(certify_7, Validators.required),
    });
  }
}
