<div class="facility-card">
  <div class="info col-12">
    <div class="facility-number col-4">
      <span class="font-18"
        >{{ "facility.NUMBER" | translate }}:
        <span class="bold-text">{{ getFacilityNumberText() }}</span></span
      >
      <span class="font-14">
        {{ "facility.NAME" | translate }}:
        <span class="bold-text font-14">{{
          facilityDetails.facility_name
        }}</span>
      </span>
      <span class="font-14">
        {{ "facility.ADDRESS_1" | translate }}:
        <span class="bold-text font-14">{{
          facilityDetails.physical_address_1
        }}</span>
      </span>
      <span class="font-14">
        {{ "facility.ADDRESS_2" | translate }}:
        <span class="bold-text font-14">{{
          facilityDetails.physical_address_2
        }}</span>
      </span>
      <hr />
    </div>
    <div class="application-owner col-4">
      <span class="font-18"
        >{{ "facility.APPLICATION_OWNER" | translate }}:
        <span class="bold-text">{{
          facilityDetails.name
            ? facilityDetails.name
            : facilityDetails.ownername
        }}</span></span
      >

      <span
        *ngIf="this.email === this.getFacilityEmail()"
        (click)="inviteUser()"
        class="font-14 invite-user"
        ><span class="text-color-blue"
          >+ {{ "facility.INVITE_USER" | translate }}</span
        ></span
      >
    </div>
    <div class="authorized-users font-18 col-4">
      <div>{{ "facility.AUTHORIZED_USER" | translate }}:</div>
      <div class="ml-2 font-14">
        <ng-container *ngIf="facilityDetails.authEmails == undefined">
          <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>
        <ng-container>
          <ng-container *ngFor="let facility of facilityDetails.authEmails">
            <div>
              {{ facility.email }}
              <ng-container *ngIf="facility.primary === 'Yes'"
                >(Primary)</ng-container
              >
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="steps row col-12">
    <div class="facility-info col-4">
      <span class="font-18"
        >{{ "common.STEP" | translate }} 1:
        {{ "facility.FACILITY_INFORMATION" | translate }}</span
      >
      <button
        *ngIf="!isFacilitySaved()"
        class="btn btn-success w-50"
        [routerLink]="getFacilityVerificationLink()"
      >
        {{ "common.GET_STARTED" | translate }}
      </button>
      <button
        *ngIf="isFacilitySaved()"
        class="btn view-button w-75"
        [routerLink]="getFacilityVerificationLink()"
      >
        {{ "facility.VIEW_FACILITY_INFORMATION" | translate }}
      </button>
      <span class="font-14 mt-2"
        >{{ "common.STATUS" | translate }}:
        {{ facilityDetails.facility_status }}</span
      >
    </div>
    <div class="grant-application col-8">
      <span class="font-18"
        >{{ "common.STEP" | translate }} 2:
        {{ "facility.GRANT_APPLICATION" | translate }}</span
      >
      <div class="facitiy-type col-12 p-0 m-0">
        <ng-container *ngIf="facilityDetails.startup_validation !== 'True'">
          <div class="qualify-improvement-grant col">
            <span class="font-14">{{
              "facility.QUALITY_IMPROVEMENT_GRANT" | translate
            }}</span>

            <!-- changed when turned it off -->
            <button
              *ngIf="!isWithdrawn('Quality Improvement')"
              class="btn btn-sm w-50"
              (click)="openApplication('QUALITY_IMPROVEMENT')"
              [ngClass]="{
                'btn-success': isFacilitySaved(),
                'btn-secondary': !isFacilitySaved()
              }"
              [disabled]="!isFacilitySaved()"
            >
              {{
                (isApplicationSaved("Quality Improvement")
                  ? "actions.REPORT"
                  : "actions.APPLY"
                ) | translate
              }}
            </button>
            <span class="font-14"
              >{{ "common.STATUS" | translate }}:
              {{ getApplicationStatus("Quality Improvement") }}</span
            >
            <span
              class="font-14 mt-1"
              *ngIf="isStatusPaid('Quality Improvement')"
              >{{ "common.WARRANT_NUMBER" | translate }}:
              {{ getWarrantNumber("Quality Improvement") }}</span
            >
          </div>
          <div class="operational-payments-grant col">
            <span class="font-14">{{
              "facility.OPERATIONAL_PAYMENTS_GRANT" | translate
            }}</span>

            <!-- changed when turned it off -->
            <button
              *ngIf="!isWithdrawn('Operational Payments')"
              class="btn btn-sm w-50"
              (click)="openApplication('OPERATIONAL_PAYMENTS')"
              [ngClass]="{
                'btn-success': isFacilitySaved(),
                'btn-secondary': !isFacilitySaved()
              }"
              [disabled]="!isFacilitySaved()"
            >
              {{
                (isApplicationSaved("Operational Payments")
                  ? "actions.REPORT"
                  : "actions.APPLY"
                ) | translate
              }}
            </button>
            <span class="font-14"
              >{{ "common.STATUS" | translate }}:
              {{ getApplicationStatus("Operational Payments") }}</span
            >
            <span
              class="font-14 mt-1"
              *ngIf="isStatusPaid('Operational Payments')"
              >{{ "common.WARRANT_NUMBER" | translate }}:
              {{ getWarrantNumber("Operational Payments") }}</span
            >
          </div>
          <!-- COMMENT OUT Second Round FOR NOW - NES 20220617 -->
          <div class="operational-payments-grant col">
            <span class="font-14">{{
              "facility.SUSTAINABILITY_GRANT" | translate
            }}</span>
            <button
              *ngIf="!isWithdrawn('Sustainability')"
              class="btn btn-sm w-50"
              (click)="openApplication('SUSTAINABILITY')"
              [ngClass]="{
                'btn-success': isFacilitySaved(),
                'btn-secondary': !isFacilitySaved()
              }"
              [disabled]="!isFacilitySaved()"
            >
              {{
                (isApplicationSaved("Sustainability")
                  ? "actions.REPORT"
                  : "actions.APPLY"
                ) | translate
              }}
            </button>
            <span class="font-14"
              >{{ "common.STATUS" | translate }}:
              {{ getApplicationStatus("Sustainability") }}</span
            >
            <span class="font-14 mt-1" *ngIf="isStatusPaid('Sustainability')"
              >{{ "common.WARRANT_NUMBER" | translate }}:
              {{ getWarrantNumber("Sustainability") }}</span
            >
          </div>
          <div class="child-care-expansion-grant col">
            <span class="font-14">{{
              "facility.CHILD_EXPANSION_GRANT" | translate
            }}</span>
            <button
              *ngIf="!isWithdrawn('Child Care Expansion')"
              class="btn btn-sm w-50"
              (click)="openApplication('CHILD_CARE_EXPANSION')"
              [ngClass]="{
                'btn-success': isFacilitySaved(),
                'btn-secondary': !isFacilitySaved()
              }"
              [disabled]="!isFacilitySaved()"
            >
              {{
                (isApplicationSaved("Child Care Expansion")
                  ? "actions.REPORT"
                  : "actions.APPLY"
                ) | translate
              }}
            </button>
            <span class="font-14"
              >{{ "common.STATUS" | translate }}:
              {{ getApplicationStatus("Child Care Expansion") }}</span
            >
            <span
              class="font-14 mt-1"
              *ngIf="isStatusPaid('Child Care Expansion')"
              >{{ "common.WARRANT_NUMBER" | translate }}:
              {{ getWarrantNumber("Child Care Expansion") }}</span
            >
          </div>
        </ng-container>
        <ng-container *ngIf="facilityDetails.startup_validation === 'True'">
          <div class="operational-payments-grant col">
            <span class="font-14">{{
              "facility.STARTUP_GRANT" | translate
            }}</span>
            <button
              *ngIf="!isWithdrawn('Startup Grant')"
              class="btn btn-sm w-50"
              (click)="openApplication('STARTUP_GRANT')"
              [ngClass]="{
                'btn-success': isFacilitySaved(),
                'btn-secondary': !isFacilitySaved()
              }"
              [disabled]="!isFacilitySaved()"
            >
              {{
                (isApplicationSaved("Startup Grant")
                  ? "actions.REPORT"
                  : "actions.APPLY"
                ) | translate
              }}
            </button>
            <span class="font-14"
              >{{ "common.STATUS" | translate }}:
              {{ getApplicationStatus("Startup Grant") }}</span
            >
            <span class="font-14 mt-1" *ngIf="isStatusPaid('Startup Grant')"
              >{{ "common.WARRANT_NUMBER" | translate }}:
              {{ getWarrantNumber("Startup Grant") }}</span
            >
          </div>
        </ng-container>
        <div class="operational-payments-grant col">
          <span class="font-14">{{ "facility.BONUS_GRANT" | translate }}</span>
          <button
            *ngIf="!isWithdrawn('Maintenance Grant')"
            class="btn btn-sm w-50"
            (click)="openApplication('BONUS_GRANT')"
            [ngClass]="{
              'btn-success': isFacilitySaved(),
              'btn-secondary': !isFacilitySaved()
            }"
            [disabled]="!isFacilitySaved()"
          >
            {{
              (isApplicationSaved("Maintenance Grant")
                ? "actions.REPORT"
                : "actions.APPLY"
              ) | translate
            }}
          </button>
          <span class="font-14"
            >{{ "common.STATUS" | translate }}:
            {{ getApplicationStatus("Maintenance Grant") }}</span
          >
          <span class="font-14 mt-1" *ngIf="isStatusPaid('Maintenance Grant')"
            >{{ "common.WARRANT_NUMBER" | translate }}:
            {{ getWarrantNumber("Maintenance Grant") }}</span
          >
        </div>
        <div class="operational-payments-grant col">
          <span class="font-14">{{ "facility.ENRICHMENT_GRANT" | translate }}</span>
          <button
            *ngIf="!isWithdrawn('Enrichment Grant')"
            class="btn btn-sm w-50"
            (click)="openApplication('ENRICHMENT_GRANT')"
            [ngClass]="{
              'btn-success': isFacilitySaved(),
              'btn-secondary': !isFacilitySaved()
            }"
            [disabled]="!isFacilitySaved()"
          >
            {{
              (isApplicationSaved("Enrichment Grant")
                ? "actions.REPORT"
                : "actions.APPLY"
              ) | translate
            }}
          </button>
          <span class="font-14"
            >{{ "common.STATUS" | translate }}:
            {{ getApplicationStatus("Enrichment Grant") }}</span
          >
          <span class="font-14 mt-1" *ngIf="isStatusPaid('Enrichment Grant')"
            >{{ "common.WARRANT_NUMBER" | translate }}:
            {{ getWarrantNumber("Enrichment Grant") }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="lds-facebook">
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
