<div class="save-confirmation">
  <div class="heading">
    <h2>{{ "common.IMPORTANT" | translate }}</h2>
    <i (click)="closeModal()" class="fa fa-times"></i>
  </div>
  <p>{{ "applications.REMAINING_BUDGET_TEXT" | translate }}</p>
  <div class="text-box">
    <textarea
      [formControl]="provided_explantion"
      placeholder="{{ 'applications.PROVIDE_EXPLANATION' | translate }}"
      name="explaintion"
      id="explaination"
      cols="47"
      rows="6"
      required
    ></textarea>
  </div>
  <div class="button-div">
    <button
      [disabled]="
        !provided_explantion.valid || provided_explantion.hasError('whitespace')
      "
      class="btn btn-success"
      (click)="onOptionSelected(true)"
    >
      {{ "common.CONTINUE" | translate }}
    </button>
    <p class="save-for-later" (click)="onOptionSelected(false)">
      {{ "common.RETURN" | translate }}
    </p>
  </div>
</div>
