export class Constants {
    public static MESSAGES = {
        APPLICATION_CREATE_SUCCESS: 'Application Created Successfully',
        APPLICATION_CREATE_ERROR: 'Error in Creating Application',
        APPLICATION_UPDATE_SUCCESS: 'Application Updated Successfully',
        APPLICATION_UPDATE_ERROR: 'Error Updating Application',
        APPLICATION_FETCH_ERROR: 'Error Fetching Application Data',
        PROPERTY_FETCH_ERROR: 'Error Fetching Property Data',
        RENTAL_FETCH_ERROR: 'Error Fetching Rental Data',
        EXPENSES_FETCH_ERROR: 'Error Fetching Expenses Data',
        EXPENSES_UPDATE_SUCCESS: 'Expenses Updated Successfully',
        EXPENSES_UPDATE_ERROR: 'Error Updating Expenses',
        EXPENSE_DELETE_SUCCESS: 'Expense Deleted Successfully',
        EXPENSE_DELETE_ERROR: 'Error Deleting Expense',
        HOUSEHOLD_FETCH_ERROR: 'Error Fetching Household Data',
        HOUSEHOLD_UPDATE_SUCCESS: 'Household Updated Successfully',
        HOUSEHOLD_UPDATE_ERROR: 'Error Updating Household',
        HOUSEHOLD_DELETE_SUCCESS: 'Household Deleted Successfully',
        HOUSEHOLD_DELETE_ERROR: 'Error Deleting Household',
        TIN_ALREADY_EXIST: 'Error: SSN / ITIN / EIN Already Registered',
        INVALID_NAICS: 'Error: Invalid NAICS Code',
        INVITATION_EMAIL_SUCCESS: 'Invitation Email Sent Successfully',
        INVITATION_EMAIL_ERROR: 'Error Sending Invite Email',
        VERIFICATION_MISMATCH: 'Verification data does not match Renter data',
        VERIFICATION_NOT_CHECKED: 'You did not certify this approval',
        CREATE_TICKET_SUCCESS: 'Request Created Successfully',
        CREATE_TICKET_ERROR: 'Error Creating Ticket',
        FILE_UPLOAD_SUCCESS: 'File Uploaded Successfully',
        FILE_UPLOAD_ERROR: 'Error Uploading File',
        PROPERTY_UPDATE_SUCCESS: 'Property Updated Successfully',
        PROPERTY_UPDATE_ERROR: 'Error Updating Property',
        FILE_DELETE_SUCCESS: 'File Deleted Successfully',
        FILE_DELETE_ERROR: 'Error Deleting File',
    };

    public static EXPENSES_MAPPING = {
        "Rent": "RENT",
        "Utility - Electricity": "ELECTRICITY",
        "Utility - Gas": "GAS",
        "Utility - Water": "WATER",
        "Utility - Phone": "PHONE",
        "Utility - Internet": "INTERNET",
        "Utility - Other": "OTHER",
    };
    public static HOUSEHOLD_MAPPING = {
        "Household 0": "#1",
        "Household 1": "#2",
        "Household 2": "#3",
        "Household 3": "#4",
        "Household 4": "#5",
        "Household 5": "#6",
        "Household 6": "#7",
        "Household 7": "#8",
        "Household 8": "#9",
        "Household 9": "#10",
    };
    public static MONTHS_MAPPING = {
        "March 2020": "March 2020",
        "April 2020": "April 2020",
        "May 2020": "May 2020",
        "June 2020": "June 2020",
        "July 2020": "July 2020",
        "August 2020": "August 2020",
        "September 2020": "September 2020",
        "October 2020": "October 2020",
        "November 2020": "November 2020",
        "December 2020": "December 2020",
        "January 2021": "January 2021",
        "February 2021": "February 2021",
        "March 2021": "March 2021",
        "April 2021": "April 2021",
    };

    public static FILE_SIZE_LIMIT = 15 * 1000000; //(1 MB = 1000000 bytes)
    public static EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static AMOUNT_PATTERN = /^\d{1,8}(\.\d{1,2})?$/;
    public static PASSWORD_PATTERN = /^(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,50}$/;

    public static TWENTY_MB_IN_BYTE = 20000000;

}

export const RaceOptions = [
    {
      label: "Asian",
      value: "Asian",
    },
    {
      label: "American Indian/Alaska Native",
      value: "American Indian/Alaska Native",
    },
    {
      label: "Black/African American",
      value: "Black/African American",
    },
    {
      label: "Native Hawaiian/Pacific Islander",
      value: "Native Hawaiian/Pacific Islander",
    },
    {
      label: "White",
      value: "White",
    },
    {
      label: "Multiracial",
      value: "Multiracial",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];
  
  export const EthnicityOptions = [
      {
          label: "Latino",
          value: "Latino",
      }, 
      {
          label: "Not Latino",
          value: "Not Labtino",
      }
  ];
  
  export const GenderOptions = [
      {
          label: "Male",
          value: "Male",
      },
      {
          label: "Femaile",
          value: "Female",
      },
      {
          label: "Non-binary",
          value: "Non-binary",
      },
      {
          label: "Would rather not say",
          value: "Would rather not say",
      },
  ]

