<div class="facility-information">
  <p class="font-24">{{ "applications.OPERATIONAL_STATUS" | translate }}</p>
  <p class="font-14 red-text">
    {{ "common.REQUIRED_FIELD_MESSAGE" | translate }}
  </p>
  <form [formGroup]="form">
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "facility.FACILITY_TYPE_TEXT" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="facility_type"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let type of facilityOptions"
          [value]="type.Name"
        >
          {{ type.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "applications.LICENSED_BEFORE_TEXT" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="licensed_march_11"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let type of licensedBefore11Options"
          [value]="type.Name"
        >
          {{ type.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "facility.CURRENT_STATUS" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="current_status"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let status of currentStatusOptions"
          [value]="status.Name"
        >
          {{ status.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "applications.NON_TRADITIONAL_HOURS_TEXT" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="non_traditional_hours"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let option of nonTraditionHoursOptions"
          [value]="option.Name"
        >
          {{ option.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "facility.CURRENT_STATUS" | translate }}*</label
      >
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="mat-radio-group"
        formControlName="current_status"
        [disabled]="isFacilitySaved()"
      >
        <mat-radio-button
          class="mat-radio-button"
          *ngFor="let currentStatus of currentStatusOptions"
          [value]="currentStatus.Name"
        >
          {{ currentStatus.Name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "facility.ANNUAL_BUDGET" | translate }}*</label
      >
      <input type="text" class="w-25" formControlName="operating_budget" />
    </div>
  </form>
  <!-- <span class="button-div mt-2">
    <button
      class="btn btn-success"
      (click)="saveAndNext()"
      [disabled]="form.invalid"
    >
      {{ "common.SAVE_AND_CONTINUE" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      (click)="goBack()"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->
</div>
