import { ValidatorFn, ValidationErrors, AbstractControl, FormGroup } from "@angular/forms";
import moment from "moment";

export function toDecimalPoint(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) return null;
    const decimalPart = value.split('.')[1];

    if (!decimalPart) return null;

    if (decimalPart.length > 2) {
      return { 'TDPErr': true }
    }

  }

}

/**
 * @description custom validator to check that two fields match
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

/**
 * Start and end date validator
 */
export const startEndDateValidator = (group: FormGroup): {
  [key: string]: boolean
} | null => {
  if (group.controls['lease_end_date'].value && group.controls['lease_start_date'].value)
    if (moment(group.controls['lease_end_date'].value)
        .diff(moment(group.controls['lease_start_date'].value)) >= 0) 
      return null;
  return {date: true};
}