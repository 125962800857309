import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { AuthService } from "src/app/services/auth.service";
import { CacheService } from "src/app/services/cache.service";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";

@Component({
  selector: "app-facility-verification",
  templateUrl: "./facility-verification.component.html",
  styleUrls: ["./facility-verification.component.css"],
})
export class FacilityVerificationComponent implements OnInit {
  applicationData = {
    id: 1,
  };
  currentTab: number = 0;
  facilityId: number = 0;
  facilityData: any = {};
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();
  @ViewChild("tabs") tabs!: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private ignatiusService: IgnatiusService,
    private projectSpecificService: ProjectSpecificService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private cacheService: CacheService
  ) {}

  async ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe((res: any) => {
      this.facilityId = res["id"];
      this.getFacilityDetails();
    });
  }

  getRequestPayload = (): any => {
    let payload = {
      ApplicationTableId: this.projectSpecificData.facility.TableId,
      ConditionGroups: [
        {
          Type: "All",
          Conditions: [
            {
              ConditionField: {
                Key: this.projectSpecificData.facility.RecordFieldId,
              },
              OperationType: "is equal",
              Value: this.facilityId,
            },
          ],
        },
      ],
    };
    return payload;
  };

  async getFacilityDetails() {
    try {
      this.spinner.show();
      let requestPayload = this.getRequestPayload();
      this.facilityData = await this.ignatiusService
        .getQueryReportObservable(
          this.projectSpecificData.appData,
          requestPayload
        )
        .toPromise();

      this.cacheService.cacheApplications(this.facilityData);
      this.spinner.hide();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
    const permissionTypeData = this.projectSpecificService.getPermissionType();
    const userData = this.projectSpecificService.getUserData();
  }

  tabChange = ($event) => {
    this.currentTab = $event;
  };

  nextStep = () => {
    this.currentTab = this.currentTab + 1;
    window.scroll(0, 0);
  };

  back = () => {
    if (this.currentTab) this.currentTab = this.currentTab - 1;
    window.scroll(0, 0);
  };
}
