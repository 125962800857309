import { Injectable } from '@angular/core';
import { AppData } from '../models/app-data';
import {
	ProjectSpecificData as ProjectSpecificData,
	ApplicationData,
	FacilityData,
	ExpenseData,
	FacilityUsers,
  	GrandTotal
} from '../models/project-specific-data';

@Injectable()
export class ProjectSpecificService {
	constructor() { }

	getProjectSpecificData(): ProjectSpecificData {
		let projectSpecificData = JSON.parse(localStorage.getItem('dfaData'));
		projectSpecificData.appData = this.parseAppData(projectSpecificData.appData);
		return projectSpecificData;
	}

	getPermissions(tableName: string): boolean {
		const permissionData = JSON.parse(localStorage.getItem('permissionData'))
		if (!permissionData || !permissionData.length) return false;
		const tablePermission = permissionData.find(elem => elem.Name === tableName);
		if (!tablePermission || !tablePermission.Create) return false;
		return Boolean(tablePermission.Create);
	}

	getPermissionType() {
		const permissioTypeData = JSON.parse(localStorage.getItem('permissionData'))
		if (!permissioTypeData) return {};
		return permissioTypeData;
	}

	getRoleData() {
		const roleData = JSON.parse(localStorage.getItem('roleData'))
		if (!roleData) return {};
		return roleData;
	}

	getUserData() {
		const roleData = JSON.parse(localStorage.getItem('userData'))
		if (!roleData) return {};
		return roleData;
	}

	private parseAppData(appData: AppData) {
		return new AppData(
			appData.Name,
			appData.DbName,
			appData.Tables,
			appData.Users
		);

	}

	getUserList() {
		let projectSpecificData = JSON.parse(localStorage.getItem('dfaData'));
		projectSpecificData.appData = this.parseAppData(projectSpecificData.appData);
		return projectSpecificData.appData.Users;
	}

	createProjectSpecificData(appData: AppData) {
		let projectSpecificData = new ProjectSpecificData();
		appData = this.parseAppData(appData);

		for (const table of appData.Tables) {

			switch (table.Name) {

				case 'Application':
					projectSpecificData.applicationData = new ApplicationData();
					projectSpecificData.applicationData.TableId = table.Key;
					projectSpecificData.applicationData.ApplicationFinalReportId = appData.getAppItemId(table.Reports, "Application Final");
					projectSpecificData.applicationData.FormulasReportId = appData.getAppItemId(table.Reports, "Formulas");
					projectSpecificData.applicationData.ApplicationsByTypeReportId = appData.getAppItemId(table.Reports, "Applications by Type");
					projectSpecificData.applicationData.QuickBaseSyncReportId = appData.getAppItemId(table.Reports, "QuickBase Sync");
					projectSpecificData.applicationData.AmountByGrantTypeReportId = appData.getAppItemId(table.Reports, "Amount by Grant Type");
					projectSpecificData.applicationData.RecordFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.applicationData.ApplicationTypeFieldId = appData.getAppItemId(table.Fields, 'Application Type');
					projectSpecificData.applicationData.OperationalGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Operational Grant Amount');
					projectSpecificData.applicationData.QualityInfantPreschoolGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Quality Infant-Preschool Grant Amount');
					projectSpecificData.applicationData.QualityTotalGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Quality Total Grant Amount');
					projectSpecificData.applicationData.ExpansionFCHCapacityFieldId = appData.getAppItemId(table.Fields, 'Expansion FCH Capacity');
					projectSpecificData.applicationData.ExpansionFCHGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Expansion FCH Grant Amount');
					projectSpecificData.applicationData.ExpansionInfantCapacityFieldId = appData.getAppItemId(table.Fields, 'Expansion Infant Capacity');
					projectSpecificData.applicationData.ExpansionInfantGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Expansion Infant Grant Amount');
					projectSpecificData.applicationData.ExpansionToddlerCapacityFieldId = appData.getAppItemId(table.Fields, 'Expansion Toddler Capacity');
					projectSpecificData.applicationData.ExpansionToddlerGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Expansion Toddler Grant Amount');
					projectSpecificData.applicationData.ExpansionSACapacityFieldId = appData.getAppItemId(table.Fields, 'Expansion SA Capacity');
					projectSpecificData.applicationData.ExpansionSAGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Expansion SA Grant Amount');
					projectSpecificData.applicationData.ExpansionStaffSalaryCapacityFieldId = appData.getAppItemId(table.Fields, 'Expansion Staff Salary Capacity');
					projectSpecificData.applicationData.ExpansionSalaryStaffGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Expansion Salary Staff Grant Amount');
					projectSpecificData.applicationData.TotalExpansionGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Total Expansion Grant Amount');
					projectSpecificData.applicationData.TotalSubgrantAmountFieldId = appData.getAppItemId(table.Fields, 'Total Subgrant Amount');
					projectSpecificData.applicationData.StatusFieldId = appData.getAppItemId(table.Fields, 'Status');
					projectSpecificData.applicationData.SignatureFieldId = appData.getAppItemId(table.Fields, 'Signature');
					projectSpecificData.applicationData.GovernmentIDFieldId = appData.getAppItemId(table.Fields, 'Government ID');
					projectSpecificData.applicationData.RelatedFacilityFieldId = appData.getAppItemId(table.Fields, 'Related Facility');
					projectSpecificData.applicationData.FacilityNameFieldId = appData.getAppItemId(table.Fields, 'Facility - Facility Name');
					projectSpecificData.applicationData.FacilityPhysicalAddress1FieldId = appData.getAppItemId(table.Fields, 'Facility - Physical Address 1');
					projectSpecificData.applicationData.UsernameAppFieldId = appData.getAppItemId(table.Fields, 'Username App');
					projectSpecificData.applicationData.UsernameTitleFieldId = appData.getAppItemId(table.Fields, 'Username Title');
					projectSpecificData.applicationData.FacilityQualityEnrollmentCountFieldId = appData.getAppItemId(table.Fields, 'Facility - Quality Enrollment Count');
					projectSpecificData.applicationData.FacilityITPCountFieldId = appData.getAppItemId(table.Fields, 'Facility - ITP Count');
					projectSpecificData.applicationData.FacilitySchoolAgedCountFieldId = appData.getAppItemId(table.Fields, 'Facility - School Aged Count');
					projectSpecificData.applicationData.FacilityTypeFieldId = appData.getAppItemId(table.Fields, 'Facility - Facility Type');
					projectSpecificData.applicationData.QualitySchoolAgeGrantAmountFieldId = appData.getAppItemId(table.Fields, 'Quality School Age Grant Amount');
					projectSpecificData.applicationData.FacilityInfantCountFieldId = appData.getAppItemId(table.Fields, 'Facility - Infant Count');
					projectSpecificData.applicationData.SignatureDateFieldId = appData.getAppItemId(table.Fields, 'Signature Date');
					projectSpecificData.applicationData.FacilityNumberFieldId = appData.getAppItemId(table.Fields, 'Facility - Facility Number');
					projectSpecificData.applicationData.Question2FieldId = appData.getAppItemId(table.Fields, 'Question2');
          projectSpecificData.applicationData.BetterBeginningsBonusQuestionFieldId = appData.getAppItemId(table.Fields,'Better Beginnings Bonus Question');

					break;
				case "Expenses":
					projectSpecificData.expenseData = new ExpenseData();
					projectSpecificData.expenseData.TableId = table.Key;
					projectSpecificData.expenseData.ExpensesReportId = appData.getAppItemId(table.Reports, "Expenses");
					projectSpecificData.expenseData.QuickBaseSyncReportId = appData.getAppItemId(table.Reports, "QuickBase Sync");
					projectSpecificData.expenseData.RecordFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.expenseData.DateCreatedFieldId = appData.getAppItemId(table.Fields, 'Date Created');
					projectSpecificData.expenseData.DateModifiedFieldId = appData.getAppItemId(table.Fields, 'Date Modified');
					projectSpecificData.expenseData.CreatedByFieldId = appData.getAppItemId(table.Fields, 'Created By');
					projectSpecificData.expenseData.ModifiedByFieldId = appData.getAppItemId(table.Fields, 'Modified By');
					projectSpecificData.expenseData.PersonnelCostsFieldId = appData.getAppItemId(table.Fields, 'Personnel Costs');
					projectSpecificData.expenseData.RentMortgageFieldId = appData.getAppItemId(table.Fields, 'Rent Mortgage');
					projectSpecificData.expenseData.PPESuppliesFieldId = appData.getAppItemId(table.Fields, 'PPE Supplies');
					projectSpecificData.expenseData.MentalHealthSupportFieldId = appData.getAppItemId(table.Fields, 'Mental Health Support');
					projectSpecificData.expenseData.RelatedApplicationFieldId = appData.getAppItemId(table.Fields, 'Related Application');
					projectSpecificData.expenseData.ApplicationRecordIdFieldId = appData.getAppItemId(table.Fields, 'Application - Record Id');
					projectSpecificData.expenseData.ExpenditureMarch11FieldId = appData.getAppItemId(table.Fields, 'Expenditure March11');
					projectSpecificData.expenseData.FringeBenefitsFieldId = appData.getAppItemId(table.Fields, 'Fringe Benefits');
					projectSpecificData.expenseData.BonusesFieldId = appData.getAppItemId(table.Fields, 'Bonuses');
					projectSpecificData.expenseData.SubstitutesFieldId = appData.getAppItemId(table.Fields, 'Substitutes');
					projectSpecificData.expenseData.MinorRenovationsFieldId = appData.getAppItemId(table.Fields, 'Minor Renovations');
					projectSpecificData.expenseData.UtilitiesFieldId = appData.getAppItemId(table.Fields, 'Utilities');
					projectSpecificData.expenseData.FacilityMaintenanceFieldId = appData.getAppItemId(table.Fields, 'Facility Maintenance');
					projectSpecificData.expenseData.InsuranceFieldId = appData.getAppItemId(table.Fields, 'Insurance');
					projectSpecificData.expenseData.AnnualAuditFieldId = appData.getAppItemId(table.Fields, 'Annual Audit');
					projectSpecificData.expenseData.OfficeSuppliesFieldId = appData.getAppItemId(table.Fields, 'Office Supplies');
					projectSpecificData.expenseData.FoodSuppliesFieldId = appData.getAppItemId(table.Fields, 'Food Supplies');
					projectSpecificData.expenseData.FurnitureFieldId = appData.getAppItemId(table.Fields, 'Furniture');
					projectSpecificData.expenseData.CurriculumResourcesFieldId = appData.getAppItemId(table.Fields, 'Curriculum Resources');
					projectSpecificData.expenseData.ParentInvolvementFieldId = appData.getAppItemId(table.Fields, 'Parent Involvement');
					projectSpecificData.expenseData.TransportationFieldId = appData.getAppItemId(table.Fields, 'Transportation');
					projectSpecificData.expenseData.ProfessionalDevelopmentFieldId = appData.getAppItemId(table.Fields, 'Professional Development');
					projectSpecificData.expenseData.ScreeningsFieldId = appData.getAppItemId(table.Fields, 'Screenings');
					projectSpecificData.expenseData.TechnologyFieldId = appData.getAppItemId(table.Fields, 'Technology');
					projectSpecificData.expenseData.SecuritySystemsFieldId = appData.getAppItemId(table.Fields, 'Security Systems');
					projectSpecificData.expenseData.OtherREFieldId = appData.getAppItemId(table.Fields, 'Other RE');
					projectSpecificData.expenseData.NewStaffFieldId = appData.getAppItemId(table.Fields, 'New Staff');
					projectSpecificData.expenseData.SPSixFieldId = appData.getAppItemId(table.Fields, 'SP Six');
					projectSpecificData.expenseData.FBSixFieldId = appData.getAppItemId(table.Fields, 'FB Six');
					projectSpecificData.expenseData.ExplanationBoxFieldId = appData.getAppItemId(table.Fields, 'Explanation Box');
					projectSpecificData.expenseData.ApplicationFacilityNumberFacilityFieldId = appData.getAppItemId(table.Fields, 'Application - Facility Number (Facility)');
					projectSpecificData.expenseData.ApplicationTotalSubgrantAmountFieldId = appData.getAppItemId(table.Fields, 'Application - Total Subgrant Amount');
					projectSpecificData.expenseData.RemainingGrantFieldId = appData.getAppItemId(table.Fields, 'Remaining Grant');
					break;
				case "Facility":
					projectSpecificData.facility = new FacilityData();
					projectSpecificData.facility.TableId = table.Key;
					projectSpecificData.facility.FacilityReportId = appData.getAppItemId(table.Reports, "Facility");
					projectSpecificData.facility.ListAllReportId = appData.getAppItemId(table.Reports, "List All");
					projectSpecificData.facility.QuickBaseSyncReportId = appData.getAppItemId(table.Reports, "QuickBase Sync");
					projectSpecificData.facility.RecordFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.facility.FacilityNameFieldId = appData.getAppItemId(table.Fields, 'Facility Name');
					projectSpecificData.facility.PhysicalAddress1FieldId = appData.getAppItemId(table.Fields, 'Physical Address 1');
					projectSpecificData.facility.PhysicalAddress2FieldId = appData.getAppItemId(table.Fields, 'Physical Address 2');
					projectSpecificData.facility.PhysicalCityFieldId = appData.getAppItemId(table.Fields, 'Physical City');
					projectSpecificData.facility.PhysicalStateFieldId = appData.getAppItemId(table.Fields, 'Physical State');
					projectSpecificData.facility.PhysicalZipcodeFieldId = appData.getAppItemId(table.Fields, 'Physical Zipcode');
					projectSpecificData.facility.InfantCountFieldId = appData.getAppItemId(table.Fields, 'Infant Count');
					projectSpecificData.facility.ToddlerCountFieldId = appData.getAppItemId(table.Fields, 'Toddler Count');
					projectSpecificData.facility.PreschoolCountFieldId = appData.getAppItemId(table.Fields, 'Preschool Count');
					projectSpecificData.facility.SchoolAgedCountFieldId = appData.getAppItemId(table.Fields, 'School Aged Count');
					projectSpecificData.facility.EarlyHeadStartFieldId = appData.getAppItemId(table.Fields, 'Early Head Start');
					projectSpecificData.facility.HeadStartFieldId = appData.getAppItemId(table.Fields, 'Head Start');
					projectSpecificData.facility.ArkansasBetterChanceFieldId = appData.getAppItemId(table.Fields, 'Arkansas Better Chance');
					projectSpecificData.facility.MedicaidEIDTFieldId = appData.getAppItemId(table.Fields, 'Medicaid EIDT');
					projectSpecificData.facility.InfantPreCovidFieldId = appData.getAppItemId(table.Fields, 'Infant PreCovid');
					projectSpecificData.facility.ToddlerPreCovidFieldId = appData.getAppItemId(table.Fields, 'Toddler PreCovid');
					projectSpecificData.facility.PreschoolPreCovidFieldId = appData.getAppItemId(table.Fields, 'Preschool PreCovid');
					projectSpecificData.facility.SchoolAgedPreCovidFieldId = appData.getAppItemId(table.Fields, 'School Aged PreCovid');
					projectSpecificData.facility.LicensedMarch11FieldId = appData.getAppItemId(table.Fields, 'Licensed March 11');
					projectSpecificData.facility.CurrentStatusFieldId = appData.getAppItemId(table.Fields, 'Current Status');
					projectSpecificData.facility.NonTraditionalHoursFieldId = appData.getAppItemId(table.Fields, 'Non Traditional Hours');
					projectSpecificData.facility.SpecialNeedsFieldId = appData.getAppItemId(table.Fields, 'Special Needs');
					projectSpecificData.facility.FacilityNumberFieldId = appData.getAppItemId(table.Fields, 'Facility Number');
					projectSpecificData.facility.RelatedApplicantFieldId = appData.getAppItemId(table.Fields, 'Related Applicant');
					projectSpecificData.facility.FacilityEmailFieldId = appData.getAppItemId(table.Fields, 'Facility Email');
					projectSpecificData.facility.PhoneFieldId = appData.getAppItemId(table.Fields, 'Phone');
					projectSpecificData.facility.TaxpayerIDFieldId = appData.getAppItemId(table.Fields, 'Taxpayer ID');
					projectSpecificData.facility.RaceFieldId = appData.getAppItemId(table.Fields, 'Race');
					projectSpecificData.facility.EthnicityFieldId = appData.getAppItemId(table.Fields, 'Ethnicity');
					projectSpecificData.facility.GenderFieldId = appData.getAppItemId(table.Fields, 'Gender');
					projectSpecificData.facility.MailingAddressFieldId = appData.getAppItemId(table.Fields, 'Mailing Address');
					projectSpecificData.facility.MailingAddress2FieldId = appData.getAppItemId(table.Fields, 'Mailing Address 2');
					projectSpecificData.facility.CityFieldId = appData.getAppItemId(table.Fields, 'City');
					projectSpecificData.facility.StateFieldId = appData.getAppItemId(table.Fields, 'State');
					projectSpecificData.facility.ZipcodeFieldId = appData.getAppItemId(table.Fields, 'Zipcode');
					projectSpecificData.facility.NameFieldId = appData.getAppItemId(table.Fields, 'Name');
					projectSpecificData.facility.LegalBusinessNameFieldId = appData.getAppItemId(table.Fields, 'Legal Business Name');
					projectSpecificData.facility.LicenseNumberFieldId = appData.getAppItemId(table.Fields, 'License Number');
					projectSpecificData.facility.OperatingBudgetFieldId = appData.getAppItemId(table.Fields, 'Operating Budget');
					projectSpecificData.facility.FacilityStatusFieldId = appData.getAppItemId(table.Fields, 'Facility Status');
					projectSpecificData.facility.FacilityTypeFieldId = appData.getAppItemId(table.Fields, 'Facility Type');
					projectSpecificData.facility.VerifiedFieldId = appData.getAppItemId(table.Fields, 'Verified');
					projectSpecificData.facility.PPFacilityCapacityFieldId = appData.getAppItemId(table.Fields, 'PP Facility Capacity');
					projectSpecificData.facility.PPInfantToddlerFieldId = appData.getAppItemId(table.Fields, 'PP Infant Toddler');
					projectSpecificData.facility.PPPreschoolFieldId = appData.getAppItemId(table.Fields, 'PP Preschool');
					projectSpecificData.facility.PPSchoolAgeFieldId = appData.getAppItemId(table.Fields, 'PP School Age');
					projectSpecificData.facility.PPLicensedCCFHFieldId = appData.getAppItemId(table.Fields, 'PP Licensed CCFH');
					projectSpecificData.facility.PPRegisteredCCFHFieldId = appData.getAppItemId(table.Fields, 'PP Registered CCFH');
					projectSpecificData.facility.PPOutSchoolFieldId = appData.getAppItemId(table.Fields, 'PP Out School');
					projectSpecificData.facility.RegisteredCCFHFieldId = appData.getAppItemId(table.Fields, 'Registered CCFH');
					projectSpecificData.facility.LicensedCCFHFieldId = appData.getAppItemId(table.Fields, 'Licensed CCFH');
					projectSpecificData.facility.OutSchoolCountFieldId = appData.getAppItemId(table.Fields, 'Out School Count');
					projectSpecificData.facility.QualityEnrollmentCountFieldId = appData.getAppItemId(table.Fields, 'Quality Enrollment Count');
					projectSpecificData.facility.ITPCountFieldId = appData.getAppItemId(table.Fields, 'ITP Count');
					projectSpecificData.facility.PhysicalCountyFieldId = appData.getAppItemId(table.Fields, 'PhysicalCounty');
					projectSpecificData.facility.OwnerNameFieldId = appData.getAppItemId(table.Fields, 'OwnerName');
					projectSpecificData.facility.DateOpenedFieldId = appData.getAppItemId(table.Fields, 'Date Opened');
					projectSpecificData.facility.IsOpenFieldId = appData.getAppItemId(table.Fields, 'IsOpen');
					projectSpecificData.facility.HeadStartyesnoFieldId = appData.getAppItemId(table.Fields, 'HeadStartyesno');
					break;
				case "Facility Users":
					projectSpecificData.facilityUsers = new FacilityUsers();
					projectSpecificData.facilityUsers.TableId = table.Key;
					projectSpecificData.facilityUsers.FacilityUsersReportId = appData.getAppItemId(table.Reports, "Facility Users Report");
					projectSpecificData.facilityUsers.QBSyncReportId = appData.getAppItemId(table.Reports, "QB Sync");
					projectSpecificData.facilityUsers.RecordIdFieldId = appData.getAppItemId(table.Fields, 'Record Id');
					projectSpecificData.facilityUsers.DateCreatedFieldId = appData.getAppItemId(table.Fields, 'Date Created');
					projectSpecificData.facilityUsers.DateModifiedFieldId = appData.getAppItemId(table.Fields, 'Date Modified');
					projectSpecificData.facilityUsers.CreatedByFieldId = appData.getAppItemId(table.Fields, 'Created By');
					projectSpecificData.facilityUsers.ModifiedByFieldId = appData.getAppItemId(table.Fields, 'Modified By');
					projectSpecificData.facilityUsers.EmailFieldId = appData.getAppItemId(table.Fields, 'Email');
					projectSpecificData.facilityUsers.RelatedFacilityFieldId = appData.getAppItemId(table.Fields, 'Related Facility');
					projectSpecificData.facilityUsers.FirstNameFieldId = appData.getAppItemId(table.Fields, 'First Name');
					projectSpecificData.facilityUsers.LastNameFieldId = appData.getAppItemId(table.Fields, 'Last Name');
					projectSpecificData.facilityUsers.CityFieldId = appData.getAppItemId(table.Fields, 'City');
					projectSpecificData.facilityUsers.ZipcodeFieldId = appData.getAppItemId(table.Fields, 'Zipcode');
					projectSpecificData.facilityUsers.PhoneFieldId = appData.getAppItemId(table.Fields, 'Phone');
					projectSpecificData.facilityUsers.FacilityFacilityNameFieldId = appData.getAppItemId(table.Fields, 'Facility - Facility Name');
					projectSpecificData.facilityUsers.FacilityFacilityTypeFieldId = appData.getAppItemId(table.Fields, 'Facility - Facility Type');
					projectSpecificData.facilityUsers.FacilityFacilityNumberFieldId = appData.getAppItemId(table.Fields, 'Facility - Facility Number');
					projectSpecificData.facilityUsers.FLNameFieldId = appData.getAppItemId(table.Fields, 'FL Name');
					projectSpecificData.facilityUsers.PrimaryUserFieldId = appData.getAppItemId(table.Fields, 'Primary User');
				case "Grand Total":
					projectSpecificData.grandTotal = new GrandTotal();
					projectSpecificData.grandTotal.TableId = table.Key;
					projectSpecificData.grandTotal.GrandTotalReportId = appData.getAppItemId(table.Reports, "ListAll");
					projectSpecificData.grandTotal.GrandTotalMaxFundAmountFieldId = appData.getAppItemId(table.Fields, "Max Fund Amount");
					projectSpecificData.grandTotal.GrandTotalTotalFundedFieldId = appData.getAppItemId(table.Fields, "Total Funded");
					break;
				default:
					break;
		}

		}

		projectSpecificData.appData = JSON.parse(JSON.stringify(appData));
		// projectSpecificData.appData.Tables.forEach(t => t.Fields = null);


		// Saving in local storage //
		localStorage.setItem('dfaData', JSON.stringify(projectSpecificData));
	}
}
