<div class="facility-verification">
  <!-- MAT TABS START -->

  <div class="card pt-3" #tabs>
    <mat-tab-group
      (selectedIndexChange)="tabChange($event)"
      [selectedIndex]="currentTab"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="d-flex align-items-center">
            <div class="tab-label-container">
              <span>
                <ng-container *ngIf="applicationData.id">1</ng-container>
                <i
                  *ngIf="!applicationData.id"
                  class="fa fa-check-circle text-success"
                ></i>
              </span>
            </div>
            <span class="ml-2 font-16">
              {{ "common.VERIFICATION" | translate }}
              </span>
          </div>
        </ng-template>
        <div class="m-3">
          <ng-container>
            <app-verification
              (nextStep)="nextStep()"
              [facilityDetails]="facilityData[0]"
            ></app-verification>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab [disabled]="currentTab < 1">
        <ng-template mat-tab-label>
          <div class="d-flex align-items-center">
            <div class="tab-label-container">
              <span>
                <ng-container *ngIf="applicationData.id">2</ng-container>
                <i
                  *ngIf="!applicationData.id"
                  class="fa fa-check-circle text-success"
                ></i>
              </span>
            </div>
            <span class="ml-2 font-16">
              {{ "common.INFORMATION" | translate }}
              </span>
          </div>
        </ng-template>
        <div class="m-3">
          <ng-container>
            <app-facility-information
              [facilityDetails]="facilityData[0]"
              (back)="back()"
              (next)="nextStep()"
            ></app-facility-information>
          </ng-container>
        </div>
      </mat-tab>

      <mat-tab [disabled]="currentTab < 2">
        <ng-template mat-tab-label>
          <div class="d-flex align-items-center">
            <div class="tab-label-container">
              <span>
                <ng-container>3</ng-container>
                <i
                  *ngIf="!applicationData.id"
                  class="fa fa-check-circle text-success"
                ></i>
              </span>
            </div>
            <span class="ml-2 font-16">
              {{ "applications.CHILD_COUNT" | translate }}
      </span>
          </div>
        </ng-template>
        <div class="m-3">
          <ng-container>
            <app-facility-child-count
              (back)="back()"
              [facilityDetails]="facilityData[0]"
            ></app-facility-child-count>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
