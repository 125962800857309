<li class="nav-item ">
  <a
    class="nav-link text-dark waves-effect waves-dark pro-pic dropdown"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [routerLink]="['/application', 'communication', 'help-desk']"
  >
    <i style="font-size: 2em" class="fa fa-envelope"></i>
  </a>
  <!-- <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
    <a
      class="dropdown-item"
      routerLinkActive="active"
      [routerLink]="['/application', 'communication', 'help-desk']"
    >
      <p class="m-b-0">Help Desk Tickets</p></a
    >
    <a
      class="dropdown-item"
      routerLinkActive="active"
      [routerLink]="['/application', 'communication', 'case-manager']"
    >
      <p class="m-b-0">Messages from Case Manager</p></a
    >
  </div> -->
</li>
