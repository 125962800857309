import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  private baseURL = `${environment.apiUrl}/api`;
  private FILE_VIEW = "/file";
  private midURL = "/report";
  private REPORT_BY_TABLE = "/getreportbytableid?id=";

  private get getReportByTable() {
    return `${this.baseURL}${this.midURL}${this.REPORT_BY_TABLE}`;
  }

  private get getFileViewUrl() {
    return `${this.baseURL}${this.FILE_VIEW}`;
  }
  constructor(private http: HttpClient) {}

  /**
   * get file
   */
  getFile = (
    tableId: number,
    recordId: number,
    fieldId: number
  ): Observable<any> =>
    this.http.get<any>(
      `${this.getFileViewUrl}?t=${tableId}&a=${recordId}&f=${fieldId}`,
      {}
    );

  /**
   * Get reports
   */
  getReports = (
    tableId: number,
    inForms: boolean,
    formId: number | null
  ): Observable<Array<any>> =>
    this.http.get<Array<any>>(
      `${this.getReportByTable}${tableId}&inForms=${inForms}${
        formId ? `&formId=${formId}` : ""
      }`,
      {}
    );
}
