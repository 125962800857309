<div class="invite-user">
  <div class="header">
    <div class="row1">
      <p class="font-24">{{ "invite-user.INVITE_USER" | translate }}</p>
      <p class="close-btn" (click)="closeModal()">X</p>
    </div>
  </div>
  <div class="description">
    <p class="font-14">
      {{ "invite-user.SUB_HEADING" | translate }}
    </p>
  </div>
  <form class="invite-form" [formGroup]="inviteUser">
    <div class="form-control-div">
      <label> {{ "invite-user.EMAIL" | translate }} </label>
      <input formControlName="Email" class="form-control" />
    </div>
    <div class="form-control-div mt-3">
      <label>{{ "invite-user.NAME" | translate }}</label>
      <input formControlName="Name" class="form-control" />
    </div>
    <span class="form-control-div">
      <input type="checkbox" formControlName="TandC" />
      <label style="display: unset" class="ml-3">{{ "invite-user.AGREE_TERMS" | translate }}</label>
    </span>
    <br />
    <span class="form-control-div checkbox mt-3">
      <input type="checkbox" formControlName="Checkbox2" />
      <label style="display: unset" class="ml-3">{{ "invite-user.AGREE_TERMS_2" | translate }}</label>
    </span>
  </form>
  <button
    class="btn btn-success"
    (click)="onInviteUser()"
    [disabled]="
      inviteUser.invalid ||
      !inviteUser.value.TandC ||
      !inviteUser.value.Checkbox2
    "
  >
    {{ "register.SUBMIT" | translate }}
  </button>
</div>
