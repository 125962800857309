<h1 mat-dialog-title>{{title | translate}}</h1>

<div mat-dialog-content>
    <p>{{message | translate}}</p>
</div>

<div mat-dialog-actions>
    <ng-container *ngIf='!hasSubmit'>
        <button class="btn-save" mat-raised-button (click)="onConfirm()">{{"applications.YES" | translate}}</button>    
        <button mat-button (click)="onDismiss()">{{"applications.NO" | translate}}</button>
    </ng-container>
    <ng-container *ngIf='hasSubmit'>
        <button class="btn-save" mat-raised-button (click)="onConfirm()">{{"applications.SUBMIT" | translate}}</button>    
        <button mat-button (click)="onDismiss()">{{"applications.CLOSE" | translate}}</button>
    </ng-container>
</div>