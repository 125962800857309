import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinner } from "ngx-spinner/lib/ngx-spinner.enum";
import { ToastrService } from "ngx-toastr";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { AuthService } from "src/app/services/auth.service";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-invite-user",
  templateUrl: "./invite-user.component.html",
  styleUrls: ["./invite-user.component.css"],
})
export class InviteUserComponent implements OnInit {
  userList: Array<any> = this.projectSpecificService.getUserList();
  roleData: any = null;
  @Input("facilityId") facilityId = 0;
  @Output("onInviteUserSuccess") onInviteUserSuccess = new EventEmitter();
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();

  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private projectSpecificService: ProjectSpecificService,
    private ignatiusService: IgnatiusService,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private toastr: ToastrService
  ) {}

  inviteUser = this.fb.group({
    Email: ["", [Validators.required, Validators.email]],
    Name: [""],
    TandC: [false, Validators.required],
    Checkbox2: [false, Validators.required],
  });

  ngOnInit() {
    this.ignatiusService.getUserRoles().subscribe((res: any) => {
      this.roleData = res;
    });
  }

  isBothChecked = (): boolean => {
    return this.inviteUser.value.TandC && this.inviteUser.value.Checkbox2;
  };

  onInviteUser = async () => {
    const isUserExists = await this.isUserExists();
    this.spinner.show();
    if (!isUserExists) {
      let payload = await this.getInviteUserPayload();

      this.ignatiusService.inviteUser(payload).subscribe(
        (res: any) => {
          let facilityUserPayload = {
            ApplicationTableId: this.projectSpecificData.facilityUsers.TableId,
            ApplicationId: environment.applicationId,
            FieldsList: [
              {
                Name: "email",
                Value: this.inviteUser.value.Email,
              },
              {
                Name: "fl_name",
                Value: this.inviteUser.value.Name,
              },
              {
                Name: "related_facility",
                Value: this.facilityId,
              },
            ],
          };
          this.ignatiusService.postData(facilityUserPayload).subscribe(
            (res: any) => {
              this.onInviteUserSuccess.emit();
              this.modal.dismissAll();
              this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide();
            }
          );
        },
        (error: any) => {
          this.toastr.show(error.error);
          this.spinner.hide();
        }
      );
    } else {
      const facilityEmail = await this.emailExistsInFacility();
      if (facilityEmail) {
        this.spinner.hide();
        this.toastr.error("Email already exists");
        return;
      }
      let facilityUserPayload = {
        ApplicationTableId: this.projectSpecificData.facilityUsers.TableId,
        ApplicationId: environment.applicationId,
        FieldsList: [
          {
            Name: "email",
            Value: this.inviteUser.value.Email,
          },
          {
            Name: "fl_name",
            Value: this.inviteUser.value.Name,
          },
          {
            Name: "related_facility",
            Value: this.facilityId,
          },
        ],
      };
      this.ignatiusService
        .postData(facilityUserPayload)
        .subscribe((res: any) => {
          this.onInviteUserSuccess.emit();
          this.modal.dismissAll();
          this.spinner.hide();
        });
    }
  };

  getApplicatantRoleId = () => {
    const applicantRole = this.roleData.filter((item: any) => {
      return item.Name === "Applicant";
    });

    if (applicantRole && applicantRole.length) {
      return applicantRole[0].Key.toString();
    } else {
      return environment.applicantRoleId;
    }
  };

  getPassword = () => {
    let email = this.inviteUser.value.Email;
    let username = email.split("@")[0];
    return `Ignatius${username}!` + Math.floor(Math.random() * 10);
  };

  getInviteUserPayload = () => {
    const AppDomain = window.location.host;

    let payload = {
      Email: this.inviteUser.value.Email,
      AppDomain: AppDomain,
      NoExpire: true,
      Role: "participant",
      ApplicationId: environment.applicationId.toString(),
      ApplicationRolesId: this.getApplicatantRoleId(),
    };
    return payload;
  };

  isUserExists = async () => {
    return !(
      await this.ignatiusService
        .validEmail({
          value: this.inviteUser.value.Email,
          optional: environment.applicationId,
        })
        .toPromise()
    ).isValid;

  };

  /**
   * @returns true if email already exists in facility
   */
  emailExistsInFacility = async () => {
    try {
      const response: Array<any> = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.facilityUsers.TableId,
          ConditionGroups: [
            {
              Type: "All",
              Conditions: [
                {
                  ConditionField: {
                    Key: this.projectSpecificData.facilityUsers.EmailFieldId,
                  },
                  OperationType: "is equal",
                  Value: this.inviteUser.value.Email,
                },
              ],
            },
            {
              Type: "All",
              Conditions: [
                {
                  ConditionField: {
                    Key: this.projectSpecificData.facilityUsers
                      .RelatedFacilityFieldId,
                  },
                  OperationType: "is equal",
                  Value: this.facilityId,
                },
              ],
            },
          ],
        })
        .toPromise();
      return response.length;
    } catch (err) {}
    return null;
  };

  closeModal = (): void => {
    this.modal.dismissAll();
  };
}
