import { Routes } from '@angular/router';

import { UserListComponent } from './list/list.component';
import { UserEditAddComponent } from './edit-add/edit-add.component';

export const UserRoutes: Routes = [
  {
    path: 'user/list',
    component: UserListComponent,
    data: {
      title: 'User',
      subjectTableName: 'user-list',
      urls: [
        { title: '' }
      ]
    },
    // resolve: {
    //   componentData: DashboardResolver
    // }
  },
  {
    path: 'user/edit',
    component: UserEditAddComponent,
    data: {
      title: 'Edit User',
      subjectTableName: 'user-edit',
      urls: [
        { title: '' }
      ]
    },
    // resolve: {
    //   componentData: DashboardResolver
    // }
  },
  {
    path: 'user/add',
    component: UserEditAddComponent,
    data: {
      title: 'Add User',
      subjectTableName: 'user-add',
      urls: [
        { title: '' }
      ]
    },
    // resolve: {
    //   componentData: DashboardResolver
    // }
  }

];

export const UserComponents: any = [
  UserListComponent,
  UserEditAddComponent
];