import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const newRelic = window.document.createElement('script');

if (environment.production) {
  enableProdMode();
  newRelic.src = '/assets/scripts/newrelic-prod.js';
} else {
  newRelic.src = '/assets/scripts/newrelic-dev.js';
}

window.document.head.appendChild(newRelic);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
 