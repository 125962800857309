import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || "").trim().length === 0;
  return !isWhitespace ? null : { whitespace: true };
}
@Component({
  selector: "app-buget-confirmation-popup",
  templateUrl: "./buget-confirmation-popup.component.html",
  styleUrls: ["./buget-confirmation-popup.component.css"],
})
export class BugetConfirmationPopupComponent implements OnInit {
  @Output("optionSelected") optionSelected = new EventEmitter();
  @Input() explation: any;
  provided_explantion = new FormControl(null, [
    Validators.required,
    noWhitespaceValidator,
  ]);
  constructor(private modal: NgbModal) {}

  ngOnInit() {
    this.provided_explantion.setValue(this.explation);
  }

  onOptionSelected = (isSubmit: boolean) => {
    if (this.provided_explantion.valid) {
      this.optionSelected.emit({
        isSubmit,
        value: this.provided_explantion.value,
      });
      this.closeModal();
    }
  };

  closeModal = () => {
    this.modal.dismissAll();
  };
}
