import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DashboardService {

  constructor() {
    this.language = new BehaviorSubject('en');
  }

  language: BehaviorSubject<string>;

  changeLanguage(language: string) {
    this.language.next(language);
  }

}

