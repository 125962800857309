<form
  *ngIf="application"
  [formGroup]="form"
  (ngSubmit)="openConfirmationPopUp()"
>
  <div class="d-flex justify-content-between">
    <p class="font-24">{{ tableTitle | translate }}</p>
    <div>
      {{ totalAmountTitle | translate }}
      <input
        *ngIf="
          this.applicationType !== 'Sustainability' &&
          this.applicationType !== 'Startup Grant' &&
          this.applicationType !== 'Maintenance Grant' &&
          this.applicationType !== 'Enrichment Grant'
        "
        class="formcontrol"
        type="text"
        [attr.disabled]="true"
        formControlName="total_subgrant_amount"
        mask="separator"
        thousandSeparator=","
      />
      <input
        *ngIf="this.applicationType === 'Sustainability'"
        class="formcontrol"
        type="text"
        [attr.disabled]="true"
        formControlName="total_sustainability_grant_amount"
        mask="separator"
        thousandSeparator=","
      />
      <input
        *ngIf="this.applicationType === 'Startup Grant'"
        class="formcontrol"
        type="text"
        [attr.disabled]="true"
        formControlName="total_startup_grant_amount"
        mask="separator"
        thousandSeparator=","
      />
      <input
        *ngIf="this.applicationType === 'Maintenance Grant'"
        class="formcontrol"
        type="text"
        [attr.disabled]="true"
        formControlName="total_bonus_grant_amount"
        mask="separator"
        thousandSeparator=","
      />
      <input
        *ngIf="this.applicationType === 'Enrichment Grant'"
        class="formcontrol"
        type="text"
        [attr.disabled]="true"
        formControlName="total_enrichment_grant_amount"
        mask="separator"
        thousandSeparator=","
      />
    </div>
  </div>
  <table class="table table-bordered table-sm">
    <thead>
      <tr>
        <th>{{ "applications.BUDGET_LINE_ITEMS" | translate }}</th>
        <th>{{ "applications.GRANT_ALLOCATION" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <td>
          {{ row.title | translate }}
          <img
            *ngIf="row.info"
            src="assets/images/rating/info.png"
            matTooltip="{{ row.info | translate }}"
          />
        </td>
        <td>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              type="text"
              mask="separator"
              thousandSeparator=","
              class="form-control"
              formControlName="{{ row.formControlName }}"
            />
          </div>
        </td>
      </tr>
      <tr *ngIf="+form.controls.other_re.value">
        <td>
          <textarea
            class="w-100"
            placeholder="Explanation"
            formControlName="explanation_box"
          ></textarea>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>
  <div class="reamining-grant-amount">
    <p>
      {{ "budget.REMAINING_GRANT_AMOUNT" | translate }}
    </p>
    <input
      type="text"
      [attr.disabled]="true"
      class="form-control w-50 mb-2"
      formControlName="remaining_grant_amount"
      [value]="form?.get('remaining_grant_amount')?.value | currency:'USD'"
    />
  </div>
  <div *ngIf="!isChildCareExpansion">
    <p>{{ "budget.PLAN_TO_USE_FUNDS" | translate }}*</p>
    <mat-radio-group
      class="mat-radio-group"
      formControlName="expenditure_march11"
    >
      <mat-radio-button class="mat-radio-button" value="Yes">
        Yes
      </mat-radio-button>
      <mat-radio-button class="mat-radio-button" value="No">
        No
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="d-flex justify-content-between">
    <div>
      <div *ngIf="isRemain_and_total_equal" class="error-text">
        {{ "common.IS_REMAIN_AND_TOTAL_EQUAAL_ERROR" | translate }}
      </div>
      <!-- <span class="button-div mt-2 d-flex">
        <button
          *ngIf="
            application.status !== 'Submitted' &&
            application.status !== 'Denied' &&
            application.status !== 'Approved' &&
            application.status !== 'Ineligible'
          "
          class="btn btn-success"
          type="submit"
          [disabled]="form.invalid || isExplanationValid()"
        >
          {{ "common.SAVE_AND_CONTINUE" | translate }}
        </button>
        <button
          *ngIf="
            application.status === 'Submitted' ||
            application.status === 'Denied' ||
            application.status === 'Approved' ||
            application.status === 'Ineligible'
          "
          class="btn btn-success"
          type="button"
          (click)="moveToNext()"
        >
          {{ "actions.NEXT" | translate }}
        </button>
        <p
          style="color: rgb(19, 96, 197); cursor: pointer"
          class="ml-3 mt-3"
          (click)="goBack()"
        >
          <u>{{ "facility.GO_BACK" | translate }}</u>
        </p>
      </span> -->
    </div>
  </div>
</form>
