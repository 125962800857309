import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { IgnatiusService } from "src/app/services/ignatius.service";

@Component({
  selector: "app-sustainability-calculator",
  templateUrl: "./sustainability-calculator.component.html",
  styleUrls: ["./sustainability-calculator.component.css"],
})
export class SustainabilityCalculatorComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  @Input("facilityDetails") facilityDetails: any = {};
  @Input("applicationDetails") applicationDetails: any = {};

  form: FormGroup = this.fb.group({
    facility_type: [""],
    capacity: [""],
    grant_amount: [""],
    ccdf_bonus_amount: [""],
    better_beginnings_bonus_amount: [""],
    sustainability_grant_amount: [""],
  });
  constructor(private fb: FormBuilder,private ignatiusService:IgnatiusService) {}

  ngOnInit() {}

  isApplicationSubmitted = (): boolean => {
    if (
      this.applicationDetails &&
      (this.applicationDetails.status === "Submitted" ||
        this.applicationDetails.status === "Approved" ||
        this.applicationDetails.status === "Denied" ||
        this.applicationDetails.status === "Ineligible" ||
        this.applicationDetails.status === "Paid") ||
        this.ignatiusService.isMaxFunded
    )
      return true;
    else return false;
  };

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityDetails && changes.facilityDetails.currentValue)
      this.facilityDetails = changes.facilityDetails.currentValue;
    if (changes.applicationDetails && changes.applicationDetails.currentValue)
      this.applicationDetails = changes.applicationDetails.currentValue;
    if (this.applicationDetails) {
      this.form.controls["facility_type"].setValue(
        this.facilityDetails.facility_type
      );
      this.form.controls["capacity"].setValue(
        this.facilityDetails.pp_facility_capacity
      );
      this.form.controls["grant_amount"].setValue(this.grantAmount);
      this.form.controls["ccdf_bonus_amount"].setValue(
        this.applicationDetails.ccdf_checkbox === "True" ? "10000.00" : "0.00"
      );
      this.form.controls["sustainability_grant_amount"].setValue(
        this.applicationDetails.sustainability_grant_amount
      );
      this.form.controls["better_beginnings_bonus_amount"].setValue(
        this.applicationDetails.better_beginnings_bonus_amount
      );
      this.form.updateValueAndValidity();
    }
  }

  get grantAmount() {
    if (this.applicationDetails.ccdf_checkbox === "True") {
      return (
        10000 +
        Number(this.applicationDetails.sustainability_grant_amount) +
        Number(this.applicationDetails.better_beginnings_bonus_amount)
      );
    } else {
      return (
        Number(this.applicationDetails.sustainability_grant_amount) +
        Number(this.applicationDetails.better_beginnings_bonus_amount)
      );
    }
  }

  goToNextStep = () => {
    this.next.emit();
  };

  goBack = () => {
    this.back.emit();
  };
}
