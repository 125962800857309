import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ChildCareExpansionCalcService {
  constructor() {}

  getFamilyCareHomeAmount = (value: number): number => {
    if (value < 5) return 0;
    else if (value < 10) return 10000;
    else return 20000;
  };

  getInfantAmount = (value: number): number => {
    if (value < 5) return 0;
    else if (value < 10) return 15000;
    else if (value < 15) return 30000;
    else if (value < 20) return 45000;
    else if (value < 25) return 60000;
    else if (value < 30) return 75000;
    else return 90000;
  };

  getToddlerAmount = (value: number): number => {
    if (value < 8) return 0;
    else if (value < 16) return 12500;
    else if (value < 24) return 25000;
    else if (value < 32) return 37500;
    else if (value < 40) return 50000;
    else if (value < 48) return 62500;
    else return 75000;
  };

  getSchoolAgedAmount = (value: number): number => {
    if (value < 18) return 0;
    else if (value < 36) return 5000;
    else if (value < 54) return 10000;
    else if (value < 72) return 15000;
    else if (value < 90) return 20000;
    else if (value < 108) return 25000;
    else if (value < 126) return 30000;
    else if (value < 144) return 35000;
    else return 40000;
  };

  getStaffSalaryAmount = (value: number): number => {
    return value * 15000;
  };
}
