import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { IgnatiusService } from "src/app/services/ignatius.service";

@Component({
  selector: "app-startup-grant-calculator",
  templateUrl: "./startup-grant-calculator.component.html",
  styleUrls: ["./startup-grant-calculator.component.css"],
})
export class StartupGrantCalculatorComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  @Input("facilityDetails") facilityDetails: any = {};
  @Input("applicationDetails") applicationDetails: any = {};

  form: FormGroup = this.fb.group({
    total_startup_grant_amount: [""],
  });
  constructor(private fb: FormBuilder,private ignatiusService:IgnatiusService) {}

  ngOnInit() {}

  isApplicationSubmitted = (): boolean => {
    if (
      this.applicationDetails &&
      (this.applicationDetails.status === "Submitted" ||
        this.applicationDetails.status === "Approved" ||
        this.applicationDetails.status === "Denied" ||
        this.applicationDetails.status === "Ineligible" ||
        this.applicationDetails.status === "Paid") ||
        this.ignatiusService.isMaxFunded
    )
      return true;
    else return false;
  };

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityDetails && changes.facilityDetails.currentValue)
      this.facilityDetails = changes.facilityDetails.currentValue;
    if (changes.applicationDetails && changes.applicationDetails.currentValue)
      this.applicationDetails = changes.applicationDetails.currentValue;
    if (this.applicationDetails) {
      this.form.controls["total_startup_grant_amount"].setValue(
        this.totalStartupGrantAmount
      );
      this.form.updateValueAndValidity();
    }
  }

  get totalStartupGrantAmount() {
    return this.facilityDetails.total_startup_grant_amount;
  }

  goToNextStep = () => {
    this.next.emit();
  };

  goBack = () => {
    this.back.emit();
  };
}
