import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinner } from "ngx-spinner/lib/ngx-spinner.enum";
import { ToastrService } from "ngx-toastr";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { AuthService } from "src/app/services/auth.service";
import { CacheService } from "src/app/services/cache.service";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  facilityData: Array<any> = [];
  dtOptions: DataTables.Settings = {};
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();
  isAdmin: boolean = this.authService.checkAdmin();
  username = this.authService.user.getValue().userName;
  relatedFacilityIds: Array<any> = [];
  isMaxFunded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private ignatiusService: IgnatiusService,
    private projectSpecificService: ProjectSpecificService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private cacheService: CacheService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
    };
    await this.getGrandTotalData();
    await this.getFacilities();
  }

  getRequestPayload = (): any => {
    let payload = {
      ApplicationTableId: this.projectSpecificData.facility.TableId,
    };
    if (this.isAdmin) {
      return payload;
    } else {
      let ConditionArray = [
        {
          ConditionField: {
            Key: this.projectSpecificData.facility.FacilityEmailFieldId,
          },
          OperationType: "is equal",
          Value: this.username,
        },
      ];

      for (let i = 0; i < this.relatedFacilityIds.length; i = i + 1) {
        ConditionArray.push({
          ConditionField: {
            Key: this.projectSpecificData.facility.RecordFieldId,
          },
          OperationType: "is equal",
          Value: this.relatedFacilityIds[i].related_facility,
        });
      }
      payload["ConditionGroups"] = [
        {
          Type: "All",
          Conditions: ConditionArray,
        },
      ];
      return payload;
    }
  };

  async getFacilities() {
    try {
      this.spinner.show();
      let relatedFacilityPayload = this.getRelatedFacilityPayload();
      if (!this.isAdmin) {
        this.relatedFacilityIds = await this.ignatiusService
          .getQueryReportObservable(
            this.projectSpecificData.appData,
            relatedFacilityPayload
          )
          .toPromise();
      }

      let requestPayload = await this.getRequestPayload();
      this.facilityData = await this.ignatiusService
        .getQueryReportObservable(
          this.projectSpecificData.appData,
          requestPayload
        )
        .toPromise();
      this.cacheService.cacheApplications(this.facilityData);
      this.getApplications(this.facilityData);
      this.getAuthorizedUserList(this.facilityData);
      this.spinner.hide();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
    const permissionTypeData = this.projectSpecificService.getPermissionType();
    const userData = this.projectSpecificService.getUserData();
  }

  getRelatedFacilityPayload = () => {
    let payload = {
      ApplicationTableId: this.projectSpecificData.facilityUsers.TableId,
      ConditionGroups: [
        {
          Type: "All",
          Conditions: [
            {
              ConditionField: {
                Key: this.projectSpecificData.facilityUsers.EmailFieldId,
              },
              OperationType: "is equal",
              Value: this.username,
            },
          ],
        },
      ],
    };
    return payload;
  };

  /**
   * Fetch authorized users list
   * @param facilityResponse API response of list shown in homescreen
   */
  getAuthorizedUserList = async (facilityResponse: Array<any>) => {
    try {
      const response: Array<any> = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.facilityUsers.TableId,
          ConditionGroups: [
            {
              Type: "All",
              Conditions: facilityResponse.map((facility) => ({
                ConditionField: {
                  Key: this.projectSpecificData.facilityUsers
                    .RelatedFacilityFieldId,
                },
                OperationType: "is equal",
                Value: facility.id,
              })),
            },
          ],
        })
        .toPromise();

      let emailMap = {};

      for (let o of response)
        if (o.email && o.email !== this.username) {
          if (emailMap[o.related_facility])
            emailMap[o.related_facility].push({
              email: o.email,
              primary: o.primary_user,
            });
          else
            emailMap[o.related_facility] = [
              {
                email: o.email,
                primary: o.primary_user,
              },
            ];
        }

      this.facilityData = this.facilityData.map((facility) => ({
        ...facility,
        authEmails: emailMap[facility.id] || [],
      }));
    } catch (err) {}
  };

  /**
   * Fetch application list
   * @param facilityResponse API response of list shown in homescreen
   */
  getApplications = async (facilityResponse: Array<any>) => {
    try {
      const response: Array<any> = await this.ignatiusService
        .getQueryReportObservable(this.projectSpecificData.appData, {
          ApplicationTableId: this.projectSpecificData.applicationData.TableId,
          ConditionGroups: [
            {
              Type: "All",
              Conditions: facilityResponse.map((facility) => ({
                ConditionField: {
                  Key: this.projectSpecificData.applicationData
                    .RelatedFacilityFieldId,
                },
                OperationType: "is equal",
                Value: facility.id,
              })),
            },
          ],
        })
        .toPromise();

      let applicationMap = {};

      // if (o.email && o.email !== this.username) {
      for (let o of response)
        if (applicationMap[o.related_facility])
          applicationMap[o.related_facility].push(o);
        else applicationMap[o.related_facility] = [o];
      // }

      this.facilityData = this.facilityData.map((facility) => ({
        ...facility,
        applications: applicationMap[facility.id] || [],
      }));
    } catch (err) {}
  };

  async getGrandTotalData() {
    let payload = {
      ApplicationTableId: this.projectSpecificData.grandTotal.TableId,
    };
    let data: any = await this.ignatiusService
      .getQueryReportObservable(this.projectSpecificData.appData, payload)
      .toPromise();
    if (data && data.length > 0) {
      this.ignatiusService.isMaxFunded = +data[0].total_funded >= +data[0].max_fund_amount;
    }
  }
}
