<div class="child-count-step">
  <p class="font-24">
    {{ "facility.QUALITY_IMPROVEMENT_GRANT" | translate }}
  </p>
  
  <p class="font-18">
    {{ "applications.GRANT_CALCULATOR_TEXT_1" | translate }}
  </p>
  <ul>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_1" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_2" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_4" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_5" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_6" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_7" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_8" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_9" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_10" | translate }}
    </li>
    <li class="font-14">
      {{ "applications.GRANT_CALCULATOR_BULLET_POINT_11" | translate }}
    </li>
  </ul>
  <hr />
  <p class="font-24">
    {{ "applications.QUALITY_PAYMENT_CALCULATOR" | translate }}
  </p>
  <p class="font-14 red-text">
    {{ "common.REQUIRED_FIELD_MESSAGE" | translate }}
  </p>
  <form [formGroup]="form">
    <div class="form-control-div">
      <label class="font-18"
        >{{ "facility.FACILITY_ENROLLMENT" | translate }}*</label
      >
      <input
        type="number"
        class="w-25 form-control"
        formControlName="quality_enrollment_count"
        [attr.disabled]="true"
      />
    </div>
    <p class="font-18 mt-2 mb-2">
      {{ "facility.GRANT_CALC_TEXT1" | translate }}
    </p>
    <div class="row col-12">
      <div class="form-control-div col-5 mr-5">
        <label class="font-18"
          >{{ "applications.INFANT_TOODLER_PRESCHOOL" | translate }}*</label
        >
        <input
          type="number"
          class="form-control"
          formControlName="infant_count"
          [attr.disabled]="true"
        />
      </div>
      <div class="form-control-div col-5">
        <label class="font-18"
          >{{ "applications.GRANT_AMOUNT" | translate }}*</label
        >
        <input
          type="number"
          class="form-control"
          formControlName="quality_infant_preschool_grant_amount"
          [attr.disabled]="true"
        />
      </div>
    </div>
    <div class="row col-12">
      <div class="form-control-div col-5 mr-5">
        <label class="font-18">{{ "facility.SCHOOL_AGE" | translate }}*</label>
        <input
          type="number"
          class="form-control"
          formControlName="school_aged_count"
          [attr.disabled]="true"
        />
      </div>
      <div class="form-control-div col-5 w-25">
        <label class="font-18"
          >{{ "applications.GRANT_AMOUNT" | translate }}*</label
        >
        <input
          type="number"
          class="form-control"
          formControlName="quality_school_age_grant_amount"
          [attr.disabled]="true"
        />
      </div>
    </div>
    <div class="row col-12">
      <div class="form-control-div col-5">
        <label class="font-18"
          >{{ "applications.TOTAL_QUALITY_GRANT_AMOUNT" | translate }}*</label
        >
        <input
          type="number"
          class="form-control"
          formControlName="quality_total_grant_amount"
          [attr.disabled]="true"
        />
      </div>
    </div>
  </form>
  <!-- <span class="button-div mt-2">
    <button
      *ngIf="!isApplicationSubmitted()"
      class="btn btn-success"
      [disabled]="form.invalid"
      (click)="goToNextStep()"
    >
      {{ "common.SAVE_AND_CONTINUE" | translate }}
    </button>
    <button
      *ngIf="isApplicationSubmitted()"
      class="btn btn-success"
      (click)="goToNextStep()"
    >
      {{ "actions.NEXT" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      (click)="goBack()"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->

</div>
