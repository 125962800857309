<div class="facility-information">
  <p class="font-24">
    {{ "applications.PROPOSED_BUDGET_HEADING" | translate }}
  </p>
  <p class="font-14 red-text">
    {{ "common.REQUIRED_FIELD_MESSAGE" | translate }}
  </p>
  <form [formGroup]="form">
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "applications.TOTAL_QUALITY_SUBGRANT_AMOUNT" | translate }}*</label
      >
      <input
        type="text"
        mask="separator"
        thousandSeparator=","
        class="w-25 form-control"
        disabled
        formControlName="subgrant_amount"
      />
    </div>
    <hr />
    <p class="font-18">
      {{ "applications.PROPOSED_BUDGET_SUBHEADING_2" | translate }}
    </p>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.New_STAFF_BONUS" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="form-control"
          formControlName="new_staff"
        />
      </div>
    </div>
    <!-- <div class="form-control-div">
        <label id="example-radio-group-label" class="font-18"
          >{{ "budget." | translate }}*</label
        >
        <input
          type="text"
        mask="separator"
        thousandSeparator=","
          class="w-25 form-control"
          
          formControlName=""
        />
      </div> -->
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.SUBSTITUTES" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="substitutes"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.MINOR_RENOVATIONS" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="minor_renovations"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.FURNITURE_AND_EQUIPMENT" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="furniture"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.CURRICULUM_RESOURCES_SUPPLIES" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="curriculum_resources"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.PARENT_INVOLVEMENT" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="parent_involvement"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.PROFESSIONAL_DEVELOPMENT" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="professional_development"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.SCREENINGS_ASSESSMENT" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="screenings"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.TECHNOLOGY" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="technology"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.NEW_SECURITY_SYSTEMS" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="security_systems"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.MENTAL_HEALTH_SERVICES" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="mental_health_support"
        />
      </div>
    </div>
    <div class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "budget.OTHER" | translate }}*</label
      >
      <div class="input-group mb-3 w-25">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-25 form-control"
          formControlName="other_re"
        />
      </div>
    </div>
    <div *ngIf="this.form.value.other_re > 0" class="form-control-div">
      <label id="example-radio-group-label" class="font-18"
        >{{ "applications.PLEASE_EXPLAIN" | translate }}*</label
      >
      <textarea
        class="w-50 form-control"
        formControlName="explanation_box"
      ></textarea>
    </div>
    <div class="total-grant-div row col-12">
      <div class="form-control-div col-6">
        <label id="example-radio-group-label" class="font-18"
          >{{ "budget.TOTAL_GRANT_AMOUNT_BUDGETED" | translate }}*</label
        >
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-50 form-control"
          formControlName="total_alloted_grant_amount"
          [value]="getCurrentTotalExpense()"
          disabled
        />
      </div>
      <div class="form-control-div col-6">
        <label id="example-radio-group-label" class="font-18"
          >{{ "budget.TOTAL_GRANT_AMOUNT_REMAINING" | translate }}*</label
        >
        <input
          type="text"
          mask="separator"
          thousandSeparator=","
          class="w-50 form-control"
          formControlName="remaining_grant_amount"
          [value]="this.form.value.subgrant_amount - getCurrentTotalExpense()"
          disabled
        />
      </div>
    </div>
  </form>
  <!-- <span class="button-div mt-2">
    <button
      *ngIf="!isApplicationSubmitted()"
      class="btn btn-success"
      (click)="saveExpense()"
      [disabled]="
        form.invalid ||
        this.form.value.subgrant_amount - getCurrentTotalExpense() !== 0
      "
    >
      {{ "common.SAVE_AND_CONTINUE" | translate }}
    </button>
    <button
      *ngIf="isApplicationSubmitted()"
      class="btn btn-success"
      (click)="goToNextStep()"
    >
      {{ "actions.NEXT" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      (click)="goBack()"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->
</div>
