import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { FormActionData, Where } from "src/app/models/form-action-data";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { environment } from "src/environments/environment";

const routeApplicationTypeMapping = {
  qualityimprovement: "Quality Improvement",
  operationalpayments: "Operational Payments",
  sustainability: "Sustainability",
  childcareexpansion: "Child Care Expansion",
  startupgrant: "Startup Grant",
  bonusgrant: "Maintenance Grant",
  enrichmentgrant: "Enrichment Grant"
};
@Component({
  selector: "app-application-options",
  templateUrl: "./application-options.component.html",
  styleUrls: ["./application-options.component.css"],
})
export class ApplicationOptionsComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  @Input("facilityDetails") facilityDetails: any = {};
  @Input("applicationDetails") applicationDetails: any = {};

  applicationTypeOptions: Array<any> = [];
  betterBeginningsBonusQOptions: Array<any> = [];
  applicationType: string = "";
  form: FormGroup = this.fb.group({
    status: ["", Validators.required],
    // ccdf_bonus_question: [""],
    ccdf_checkbox: [false],
    voucher_participant: [""],
    // better_beginnings_bonus_question: [""],
  });
  facilityId: number = 0;
  yesNoOptions: Array<any> = [
    { Name: "No", Value: "No" },
    { Name: "Yes", Value: "Yes" },
  ];
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();

  constructor(
    private fb: FormBuilder,
    private ignatiusService: IgnatiusService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private projectSpecificService: ProjectSpecificService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(async (res: any) => {
      this.facilityId = res.id;
      const modifiedType = res.type.replaceAll("-", "").toLocaleLowerCase();
      this.applicationType = await routeApplicationTypeMapping[modifiedType];
    });
    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.applicationData.ApplicationTypeFieldId.toString()
      )
      .subscribe((res: any) => {
        if (this.applicationType === "Startup Grant") {
          this.applicationTypeOptions = res?.filter(
            (el: any) => el.Name === "Startup Grant"
          );
        } else if (this.applicationType === "Maintenance Grant") {
          this.applicationTypeOptions = res?.filter(
            (el: any) => el.Name === "Maintenance Grant"
          );
        } else if (this.applicationType === "Enrichment Grant") {
          this.applicationTypeOptions = res?.filter(
            (el: any) => el.Name === "Enrichment Grant"
          );
        } else {
          this.applicationTypeOptions = res;
        }
        if (this.applicationType) {
          this.form.controls["status"].setValue(this.applicationType),
            this.form.controls["status"].disable();
        }
      });
    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.applicationData.BetterBeginningsBonusQuestionFieldId.toString()
      )
      .subscribe((res: any) => {
        this.betterBeginningsBonusQOptions = res;
      });
  }

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  goBack = () => {
    this.back.emit();
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityDetails && changes.facilityDetails.currentValue) {
      this.facilityDetails = changes.facilityDetails.currentValue;
      this.form.controls["ccdf_checkbox"].setValue(
        this.facilityDetails.ccdf_checkbox &&
          this.facilityDetails.ccdf_checkbox === "True"
      );
      this.form.controls["ccdf_checkbox"].updateValueAndValidity();
      this.form.controls["voucher_participant"].setValue(
        this.facilityDetails.voucher_participant
      );
      this.form.controls["voucher_participant"].updateValueAndValidity();
    }
    if (changes.applicationDetails && changes.applicationDetails.currentValue) {
      this.applicationDetails = changes.applicationDetails.currentValue;
      // this.form.controls["ccdf_bonus_question"].setValue(
      //   this.applicationDetails.ccdf_bonus_question
      // );
      // this.form.controls["ccdf_bonus_question"].updateValueAndValidity();
      this.form.controls["ccdf_checkbox"].setValue(
        this.applicationDetails.ccdf_checkbox &&
          this.applicationDetails.ccdf_checkbox === "True"
      );
      this.form.controls["ccdf_checkbox"].updateValueAndValidity();
      this.form.controls["voucher_participant"].setValue(
        this.applicationDetails.voucher_participant
      );
      this.form.controls["voucher_participant"].updateValueAndValidity();
      // this.form.controls["better_beginnings_bonus_question"].setValue(
      //   this.applicationDetails.better_beginnings_bonus_question
      // );
      // this.form.controls[
      //   "better_beginnings_bonus_question"
      // ].updateValueAndValidity();
    }
  }

  getPayload = () => {
    if (!this.applicationType) {
      this.applicationType = this.form.controls.status.value;
    }
    let payload = {
      ApplicationTableId: this.projectSpecificData.applicationData.TableId,
      ApplicationId: environment.applicationId,
      FieldsList: [
        {
          Name: "application_type",
          Value: this.applicationType,
        },
        {
          Name: "related_facility",
          Value: this.facilityId,
        },
        {
          Name: "status",
          Value: "In Progress",
        },
        // {
        //   Name: "ccdf_bonus_question",
        //   value: this.form.controls["ccdf_bonus_question"].value,
        // },
        {
          Name: "ccdf_checkbox",
          Value: this.form.controls["ccdf_checkbox"].value,
        },
        // {
        //   Name: "voucher_participant",
        //   value: this.form.controls["voucher_participant"].value,
        // },
        // {
        //   Name: "better_beginnings_bonus_question",
        //   value: this.form.controls["better_beginnings_bonus_question"].value,
        // },
      ],
    };

    return payload;
  };

  getUpdatePayload = () => {
    if (!this.applicationType) {
      this.applicationType = this.form.controls.status.value;
    }

    let payload: FormActionData = {
      reportId: null,
      applicationTableId: this.projectSpecificData.applicationData.TableId,
      where: new Where(this.applicationDetails.id),
      fieldsList: [
        {
          name: "application_type",
          value: this.applicationType,
          base64EncodedFile: "",
        },
        {
          name: "related_facility",
          value: this.facilityId.toString(),
          base64EncodedFile: "",
        },
        {
          name: "status",
          value: "In Progress",
          base64EncodedFile: "",
        },
        // {
        //   name: "ccdf_bonus_question",
        //   value: this.form.controls["ccdf_bonus_question"].value,
        //   base64EncodedFile: "",
        // },
        {
          name: "ccdf_checkbox",
          value: this.form.controls["ccdf_checkbox"].value,
          base64EncodedFile: "",
        },
        // {
        //   name: "voucher_participant",
        //   value: this.form.controls["voucher_participant"].value,
        //   base64EncodedFile: "",
        // },
        // {
        //   name: "better_beginnings_bonus_question",
        //   value: this.form.controls["better_beginnings_bonus_question"].value,
        //   base64EncodedFile: "",
        // },
      ],
    };

    return payload;
  };

  createApplication = () => {
    this.spinner.show();
    let payload = this.getPayload();
    this.ignatiusService.postData(payload).subscribe(
      (res: any) => {
        this.next.emit(true);
      },
      (error: any) => {
        this.spinner.hide();
      }
    );
  };

  updateApplication = () => {
    this.spinner.show();
    let payload = this.getUpdatePayload();
    this.ignatiusService.putData(payload).subscribe(
      (res: any) => {
        this.next.emit(true);
      },
      (error: any) => {
        this.spinner.hide();
      }
    );
  };

  saveAndNext = () => {
    // if (
    //   this.applicationType === "Sustainability" &&
    //   (!this.form.controls["ccdf_bonus_question"].value ||
    //     !this.form.controls["better_beginnings_bonus_question"].value)
    // ) {
    //   return;
    // }
    if (
      !this.applicationDetails &&
      this.applicationType === "Enrichment Grant" 
    ) {
      this.createApplication();
    } else if (
      this.applicationType === "Enrichment Grant" &&
      this.applicationDetails.status === "In Progress"
    ) {
      this.updateApplication();
    } else {
      this.next.emit();
    }
  };

  isFormInvalid() {
    if (this.form.invalid) return true;
    // if (
    //   this.applicationType === "Sustainability" &&
    //   (!this.form.controls["ccdf_bonus_question"].value ||
    //     !this.form.controls["better_beginnings_bonus_question"].value)
    // )
    //   return true;
    return false;
  }
}
