<div class="child-count-step">
  <p class="font-24">
    {{ "facility.CHILD_CARE_EXPANSION" | translate }}
  </p>
  <p class="font-18" [ngClass]="{'hiddenElement':isChildCareExpansion}">
    {{ "facility.CHILD_CARE_CAL_SUBHEAD" | translate }}
  </p>
  <p class="font-18" [ngClass]="{'hiddenElement':!isChildCareExpansion}">
    {{ "facility.CHILD_CARE_CAL_SUBHEAD_2" | translate }}
  </p>
  <span class="font-18 text-danger" [ngClass]="{'hiddenElement':!isChildCareExpansion}">
    {{ "facility.CHILD_CARE_CAL_SUBHEAD_NOTE_1" | translate }}
    <u class="font-weight-bold">
      {{ "facility.CHILD_CARE_CAL_SUBHEAD_NOTE_2" | translate }}</u
    >
  </span>
  <span class="font-18 text-danger" [ngClass]="{'hiddenElement':!isChildCareExpansion}">
    {{ "facility.CHILD_CARE_CAL_SUBHEAD_NOTE_3" | translate }}
  </span>
  <ul [ngClass]="{'hiddenElement':isChildCareExpansion}">
    <li class="font-14">
      {{ "facility.CHILD_CARE_CAL_TEXT1" | translate }}
    </li>
    <li class="font-14">
      {{ "facility.CHILD_CARE_CAL_TEXT2" | translate }}
    </li>
    <li class="font-14">
      {{ "facility.CHILD_CARE_CAL_TEXT3" | translate }}
    </li>
    <li class="font-14">
      {{ "facility.CHILD_CARE_CAL_TEXT4" | translate }}
    </li>
    <li class="font-14">
      {{ "facility.CHILD_CARE_CAL_TEXT5" | translate }}
    </li>
    <li class="font-14">
      {{ "facility.CHILD_CARE_CAL_TEXT6" | translate }}
    </li>
  </ul>
  <hr />
  <p class="font-24">
    {{ "facility.CHILD_CARE_CALC" | translate }}
  </p>
  <form [formGroup]="form">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th>{{ "facility.CARE_TYPE" | translate }}</th>
          <th>{{ "facility.ADDITIONAL_CAPACITY" | translate }}</th>
          <th>{{ "common.AMOUNT" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ "facility.FAMILY_CARE_HOME" | translate }}
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              formControlName="expansion_fch_capacity"
              onkeydown="if(event.key==='.'){event.preventDefault();}"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
              [attr.disabled]="disableFamilyCare ? 'true' : null"
            />
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              [value]="familyCareHomeAmount()"
              disabled
            />
          </td>
        </tr>
        <tr>
          <td>
            {{ "facility.INFANT" | translate }}
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              formControlName="expansion_infant_capacity"
              onkeydown="if(event.key==='.'){event.preventDefault();}"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
              [attr.disabled]="disableInfant ? 'true' : null"
            />
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              [value]="infantAmount()"
              disabled
            />
          </td>
        </tr>
        <tr>
          <td>
            {{ "facility.TODDLER" | translate }}
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              formControlName="expansion_toddler_capacity"
              onkeydown="if(event.key==='.'){event.preventDefault();}"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
              [attr.disabled]="disableTodler ? 'true' : null"
            />
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              [value]="toddlerAmount()"
              disabled
            />
          </td>
        </tr>
        <tr>
          <td>
            {{ "facility.SCHOOL_AGE" | translate }}
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              formControlName="expansion_sa_capacity"
              onkeydown="if(event.key==='.'){event.preventDefault();}"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
              [attr.disabled]="disableSchoolAge ? 'true' : null"
            />
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              [value]="schoolAgedAmount()"
              disabled
            />
          </td>
        </tr>
        <tr>
          <td>
            {{ "facility.STAFF_SALARY" | translate }}
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              formControlName="expansion_staff_salary_capacity"
              onkeydown="if(event.key==='.'){event.preventDefault();}"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
              [attr.disabled]="disableStaffSalary ? 'true' : null"
            />
          </td>
          <td>
            <input
              type="number"
              class="form-control"
              [value]="staffSalaryAmount()"
              disabled
            />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <p
      class="red-text"
      *ngIf="this.form.controls.expansion_staff_salary_capacity.invalid"
    >
      {{ "facility.STAFF_CAPACITY_EXCEED_TEXT" | translate }}
    </p> -->
    <div class="form-control-div">
      <label class="font-18"
        >{{ "budget.TOTAL_GRANT_AMOUNT" | translate }}*</label
      >
      <input
        type="text"
        class="w-25 form-control"
        [value]="getTotalGrantAmount()"
        disabled
      />
    </div>
    <div class="container" *ngIf="isChildCareExpansion">
      <div class="form-control-div">
        <div class="d-flex">
          <label class="font-18"
            >{{ "facility.SERVICES_NEEDED" | translate }}*</label
          >
        </div>
        <mat-form-field class="example-full-width" appearance="fill">
          <textarea
            matInput
            placeholder="..."
            formControlName="question1"
          ></textarea>
        </mat-form-field>
        <span class="d-flex justify-content-end" style="margin-top: -20px"
          >(Maximum Character Count 350)</span
        >
      </div>
      <div class="form-control-div">
        <label class="font-18"
          >{{ "facility.BEGINNINGS_QUALITY_LEVEL" | translate }}*</label
        >
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="question2"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of beginningsQualityOptions"
            [value]="type.Name"
          >
            {{ type.Name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-control-div">
        <label class="font-18"
          >{{ "facility.CCDF_SUBSIDY_VOUCHER" | translate }}*</label
        >
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="question3"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of yesNoOptions"
            [value]="type.Name"
          >
            {{ type.Name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-control-div">
        <label class="font-18"
          >{{ "facility.OFFERING_NIGHT_CARE" | translate }}*</label
        >
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="question4"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of yesNoOptions"
            [value]="type.Name"
          >
            {{ type.Name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-control-div">
        <label class="font-18"
          >{{ "facility.NIGHTS_PER_WEEK" | translate }}*</label
        >
        <input
          type="text"
          class="w-25 form-control"
          formControlName="question5"
          mask="0"
        />
      </div>
      <div class="form-control-div">
        <label class="font-18"
          >{{ "facility.WEEKEND_CHILDCARE" | translate }}*</label
        >
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="question6"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of yesNoOptions"
            [value]="type.Name"
          >
            {{ type.Name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-control-div">
        <label class="font-18"
          >{{ "facility.WEEKENDS_PER_MONTH" | translate }}*</label
        >
        <input
          type="text"
          class="w-25 form-control"
          formControlName="question7"
          mask="0"
        />
      </div>
      <div class="form-control-div">
        <label class="font-18">{{ "facility.CCLS_REVIEW" | translate }}*</label>
        <label class="font-18">{{
          "facility.CCLS_REVIEW_NOTE" | translate
        }}</label>
        <br />
        <!-- <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="question8"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of yesNoOptions"
            [value]="type.name"
          >
            {{ type.name }}
          </mat-radio-button>
        </mat-radio-group> -->
        <div class="row">
          <input
            type="file"
            class="col-4"
            formControlName="q8_attachment"
            (change)="handleUpload($event, 'q8_attachment')"
          />
          <i
            class="fa fa-times"
            (click)="clearSelectedFile('q8_attachment')"
          ></i>
        </div>
        <div *ngIf="q8Url" class="row col-12">
          <a
            class="col-6"
            [href]="ignatiusService.getFileRouteLink(q8Url)"
            target="_blank"
            >{{ q8Name }}</a
          >
        </div>
      </div>
      <div class="form-control-div">
        <label class="font-18">{{ "facility.FIRE_REVIEW" | translate }}*</label>
        <!-- <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="question9"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of yesNoOptions"
            [value]="type.name"
          >
            {{ type.name }}
          </mat-radio-button>
        </mat-radio-group> -->
        <div class="row">
          <input
            type="file"
            class="col-4"
            formControlName="q9_attachment"
            (change)="handleUpload($event, 'q9_attachment')"
          />
          <i
            class="fa fa-times"
            (click)="clearSelectedFile('q9_attachment')"
          ></i>
        </div>
        <div *ngIf="q9Url" class="row col-12">
          <a
            class="col-6"
            [href]="ignatiusService.getFileRouteLink(q9Url)"
            target="_blank"
            >{{ q9Name }}</a
          >
        </div>
      </div>
      <!-- <div class="form-control-div">
        <label class="font-18"
          >{{ "facility.HEALTH_REVIEW" | translate }}*</label
        >
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="mat-radio-group"
          formControlName="question10"
        >
          <mat-radio-button
            class="mat-radio-button"
            *ngFor="let type of yesNoOptions"
            [value]="type.name"
          >
            {{ type.name }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="row">
          <input
            type="file"
            class="col-4"
            (change)="handleUpload($event, 'q10_attachment')"
          />
          <i
            class="fa fa-times"
            (click)="clearSelectedFile('q10_attachment')"
          ></i>
        </div>
        <div *ngIf="q10Url" class="row col-12">
          <a class="col-6" [href]="q10Url" target="_blank">{{ q10Name }}</a>
        </div>
      </div> -->
      <span>{{ "facility.DOCUMENT_REQUIRED_NOTE" | translate }}</span
      ><br /><br />
      <span class="text-danger">{{
        "facility.ERROR_FETCHING_APPLICATION" | translate
      }}</span>
    </div>
  </form>

  <!-- <span class="button-div mt-2">
    <button
      *ngIf="!isApplicationSubmitted()"
      class="btn btn-success"
      [disabled]="form.invalid || isInvalidChildCare()"
      (click)="saveFacilityDetails()"
    >
      {{ "common.SAVE_AND_CONTINUE" | translate }}
    </button>
    <button
      *ngIf="isApplicationSubmitted()"
      class="btn btn-success"
      (click)="nextTab()"
    >
      {{ "actions.NEXT" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      (click)="goBack()"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->
</div>
