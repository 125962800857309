<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">{{pageInfo?.title}}</h4>
            <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <ng-template ngFor
                            let-url
                            [ngForOf]="pageInfo?.urls"
                            let-last="last">
                            <li class="breadcrumb-item"
                                *ngIf="!last"
                                [routerLink]="url.url">
                                <a>{{url.title}}</a>
                            </li>
                            <li class="breadcrumb-item active"
                                *ngIf="last">{{url.title}}</li>
                        </ng-template>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->