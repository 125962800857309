import {
  Component,
  AfterViewInit,
  EventEmitter,
  Output,
  OnInit,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { DashboardService } from "src/app/modules/dashboards/dashboard.service";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup } from "@angular/forms";
import { PopupModel } from "src/app/modules/dashboards/models/popup";
import { ApplicationForms } from "src/app/modules/dashboards/components/applications/forms";
import { CommonService } from "src/app/modules/dashboards/services";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { CacheService } from "src/app/services/cache.service";

declare var $: any;

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
  providers: [ApplicationForms, CommonService],
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  modalMessage = "";
  currentPassword: String;
  newPassword: String;
  confirmPassword: String;
  username = this.authService.user.getValue().userName;
  newMsg: boolean = true;
  rights: any;
  roleNameArr: any;
  showApplicants: boolean = false;
  applicant: string;
  apps: Object[] = [];
  selApplicant: string;
  userSessionData: any = {};
  projectSpecificData = this.projectSpecificService.getProjectSpecificData();
  applicantType: string = "";
  inviteRenterFormGroup: FormGroup;
  modelConfig: PopupModel;
  get inviteRenterFormControl() {
    return this.inviteRenterFormGroup.controls;
  }
  applicationData: any = {};

  ngOnInit() {
    const permissionTypeData = this.projectSpecificService.getPermissionType();
    if (JSON.stringify(permissionTypeData).toString().includes("Landlord"))
      this.applicantType = "landlord";
    else this.applicantType = "renter";
    this.cacheService.applications.subscribe((data) => {
      this.applicationData = data;
    });
    // this.reportService.getQueryReportObservable(
    //   this.projectSpecificData.appData,
    //   {
    //     "ApplicationTableId": this.projectSpecificData.applicationsData.TableId
    //   }).subscribe((data) => {
    //     this.applicationData = data;
    //   });
  }

  onChange(event) {
    this.selApplicant = event;
    this.applicant = event.applicant_name;
    localStorage.setItem("relApplicant", event.id);
    //todo need to reload components with new applicant
    window.location.reload();
  }

  enableSavePassword() {
    return (
      this.confirmPassword == this.newPassword &&
      this.confirmPassword &&
      this.newPassword &&
      this.currentPassword
    );
  }

  keyDownSubmitPassword(event) {
    if (event.keyCode == 13 && this.enableSavePassword()) {
      this.submitPassword();
    }
  }

  submitPassword() {
    this.authService
      .changePassword(this.currentPassword, this.confirmPassword)
      .subscribe(
        () => {
          this.toastr.success(
            this.translate.instant("login.PASSWORD_UPDATED"),
            this.translate.instant("applications.SUCCESS")
          );
          this.modalService.dismissAll("reason to dismiss");
        },
        (error) => {
          this.modalMessage = error.error;
        }
      );
  }

  openModal(content) {
    this.modalMessage = "";
    this.modalService.open(content, {
      centered: true,
      size: "lg",
      ariaLabelledBy: "modal-basic-title",
    });
  }

  logout() {
    this.authService.logout();
  }

  changeLanguage(value: string) {
    this.dashboardService.changeLanguage(value);
  }

  /**
   * Invite renter functions
   */

  openInviteRenter(content: any, appId: string) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const email = userData ? userData.userName : null;
    this.inviteRenterFormGroup = this.appForms.setupInviteRenterForm(
      email,
      appId
    );
    this.modelConfig = new PopupModel();
    this.modelConfig.settings.windowClass = "lg";
    this.modalService.open(content, this.modelConfig.settings);
  }

  onInviteRenterSubmit() {
    if (!this.inviteRenterFormGroup.valid) {
      this.common.validateAllFormFields(this.inviteRenterFormGroup);
      return;
    }
    // if (!this.inviteRenterFormControl.verification.value) {
    //   this.toastr.error(Constants.MESSAGES.VERIFICATION_NOT_CHECKED, 'Error');
    //   return;
    // }
    // this.insertInviteRenter(this.inviteRenterFormGroup.value);
  }

  // private async insertInviteRenter(reqData) {
  //   try {
  //     this.modelConfig.busy = true;
  //     const tableId = this.projectSpecificData.renterInvitationsData.TableId;
  //     const recordFAD = this.common.createPostData(tableId, reqData);
  //     await this.reportService.postData(recordFAD).toPromise();
  //     // await this.getApplicationData();

  //     this.updateInviteRenter(true);
  //   } catch (error) {
  //     this.updateInviteRenter(false);
  //   }
  //   this.modelConfig.busy = false;
  // }

  private updateInviteRenter(statue: boolean) {
    if (statue) {
      this.toastr.success(
        this.translate.instant("MESSAGES.CREATE_TICKET_SUCCESS"),
        this.translate.instant("applications.SUCCESS")
      );
      this.modalService.dismissAll();
    } else {
      this.toastr.error(
        this.translate.instant("MESSAGES.INVITATION_EMAIL_ERROR"),
        this.translate.instant("applications.ERROR")
      );
    }
    this.modelConfig.busy = false;
  }

  async closeModel() {
    const dialogData: { title: string; message: string } = {
      title: "applications.CONFIRM_DISCARD",
      message: `applications.CONFIRM_DISCARD_MESSAGE`,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "500px",
      data: dialogData,
    });

    const confirmation = await dialogRef.afterClosed().toPromise();
    if (!confirmation) return;

    this.modalService.dismissAll();
  }

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private reportService: IgnatiusService,
    private linkService: ProjectSpecificService,
    private dashboardService: DashboardService,
    private translate: TranslateService,
    private projectSpecificService: ProjectSpecificService,
    private appForms: ApplicationForms,
    private common: CommonService,
    private dialog: MatDialog,
    private cacheService: CacheService
  ) {}
}
