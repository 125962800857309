<div class="child-count-step">
  <p class="font-24">
    {{ "facility.OPERATIONAL_PAYMENTS" | translate }}
  </p>
  <p class="font-18">
    {{ "facility.OP_PAYMENTS_SUBHEADING" | translate }}
  </p>
  <ul>
    <li class="font-14">
      {{ "facility.OPERATIONAL_CALC_TEXT1" | translate }}
    </li>
    <li class="font-14">
      {{ "facility.OPERATIONAL_CALC_TEXT2" | translate }}
    </li>
    <li class="font-14">
      {{ "facility.OPERATIONAL_CALC_TEXT3" | translate }}
    </li>
    <li class="font-14">
      {{ "facility.OPERATIONAL_CALC_TEXT4" | translate }}
    </li>
  </ul>
  <hr />
  <p class="font-24">
    {{ "facility.OP_PAYMENT_CAL" | translate }}
  </p>
  <p class="font-14 red-text">
    {{ "applications.PRE_POPULATED_TEXT" | translate }}
  </p>
  <form [formGroup]="form">
    <div class="form-control-div">
      <label class="font-18">{{ "facility.TYPE" | translate }}</label>
      <input
        type="text"
        class="w-50 form-control"
        formControlName="facility_type"
        disabled
      />
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.CAPACITY" | translate }}</label>
      <input
        type="text"
        class="w-50 form-control"
        formControlName="capacity"
        disabled
      />
    </div>
    <hr />
    <div class="form-control-div">
      <label class="font-18">{{ "facility.GRANT_AMOUNT" | translate }}</label>
      <input
        type="text"
        class="w-50 form-control"
        formControlName="grant_amount"
        disabled
      />
    </div>
  </form>
  <!-- <span class="button-div mt-2">
    <button *ngIf="!isApplicationSubmitted()" class="btn btn-success" [disabled]="form.invalid" (click)="goToNextStep()">
      {{ "common.SAVE_AND_CONTINUE" | translate }}
    </button>
    <button *ngIf="isApplicationSubmitted()" class="btn btn-success"  (click)="goToNextStep()">
      {{ "actions.NEXT" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      (click)="goBack()"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->
</div>
