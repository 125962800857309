import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { AuthService } from "src/app/services/auth.service";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationSaveConfirmationComponent } from "../application-save-confirmation/application-save-confirmation.component";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import moment from "moment";
import "moment-timezone";
@Component({
  selector: "app-certifications",
  templateUrl: "./certifications.component.html",
  styleUrls: ["./certifications.component.css"],
})
export class CertificationsComponent implements OnInit, OnChanges {
  @Output("back") back = new EventEmitter();
  @Input("facilityDetails") facilityDetails: any = {};
  @Input("applicationDetails") applicationDetails: any = {};
  @Input("change") change = new Subject();
  @Input("isPrimaryUser") isPrimaryUser: boolean = false;

  base64Certificate: string = "";
  base64AuthorityCertificate: string = "";
  govtIdFileName: string = "";
  authorityCertificateFileName: string = "";
  govtIdUrl = "";
  govtIdName = "";
  certificationAttachmentUrl = "";
  certificateName = "";
  editGovtId = false;
  editCertificate = false;
  showErrorMessage: boolean = false;
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();

  form: FormGroup = this.fb.group({
    signature: ["", Validators.required],
    signature_date: ["", Validators.required],
    government_id: [""],
    certification_attachment: [""],
  });
  isChildCareExpansion: boolean = false;
  isSustainability: boolean = false;
  isStartupGrant: boolean = false;
  isBonusGrant: boolean = false;
  isEnrichmentGrant: boolean = false;
  constructor(
    private fb: FormBuilder,
    protected ignatiusService: IgnatiusService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private modal: NgbModal,
    private projectSpecificService: ProjectSpecificService
  ) {}

  ngOnInit() {
    this.isChildCareExpansion = window.location.href.includes(
      "child-care-expansion"
    );
    this.isSustainability = window.location.href.includes("sustainability");
    this.isStartupGrant = window.location.href.includes("startup-grant");
    this.isBonusGrant = window.location.href.includes("bonus-grant");
    this.isEnrichmentGrant = window.location.href.includes("enrichment-grant");
  }

  getToday(): string {
    return new Date().toISOString().split("T")[0];
  }

  getRequiredDateFormat(dateString: string): string {
    if (!dateString) return "";
    let dateArray = new Date(dateString).toLocaleDateString().split("/");

    let date = dateArray[1];
    let month = dateArray[0];
    let year = dateArray[2];
    if (dateArray[1].length == 1) {
      date = `0${date}`;
    }
    if (dateArray[0].length == 1) {
      month = `0${month}`;
    }
    return `${year}-${month}-${date}`;
  }

  isApplicationSubmitted = (): boolean => {
    if (
      this.applicationDetails &&
      (this.applicationDetails.status === "Submitted" ||
        this.applicationDetails.status === "Approved" ||
        this.applicationDetails.status === "Denied" ||
        this.applicationDetails.status === "Ineligible" ||
        this.applicationDetails.status === "Paid") ||
        this.ignatiusService.isMaxFunded
    )
      return true;
    else return false;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityDetails && changes.facilityDetails.currentValue)
      this.facilityDetails = changes.facilityDetails.currentValue;
    if (changes.applicationDetails && changes.applicationDetails.currentValue) {
      this.applicationDetails = changes.applicationDetails.currentValue;
    }

    if (changes.isPrimaryUser && changes.isPrimaryUser.currentValue) {
      this.isPrimaryUser = changes.isPrimaryUser.currentValue;
    }

    if (this.applicationDetails) {
      this.form.patchValue({
        signature: this.applicationDetails.signature,
        signature_date:
          this.applicationDetails.signature_date.length > 0
            ? moment(this.applicationDetails.signature_date).format(
                "MM-DD-YYYY"
              )
            : moment(new Date()).format("MM-DD-YYYY"),
      });
      this.govtIdUrl = this.applicationDetails.government_id_url;
      this.govtIdName = this.applicationDetails.government_id;
      this.certificationAttachmentUrl =
        this.applicationDetails.certification_attachment_url;
      this.certificateName = this.applicationDetails.certification_attachment;

      if (this.isApplicationSubmitted()) {
        this.disableAllFields();
      }
    }

    if (
      changes.facilityDetails &&
      changes.facilityDetails.currentValue &&
      this.facilityDetails
    ) {
      if (
        this.authService.user.getValue().userName.toLowerCase() !==
        this.facilityDetails.facility_email.toLowerCase()
      ) {
        if (this.isPrimaryUser === false) {
          this.form.disable();
          this.showErrorMessage = true;
        }
      } else {
        this.form.enable();
        this.showErrorMessage = false;
      }
    }
  }

  disableAllFields = () => {
    this.form.controls["signature"].disable();
    this.form.controls["signature_date"].disable();
    this.form.controls["government_id"].disable();
    this.form.controls["certification_attachment"].disable();
  };

  goBack = () => {
    this.back.emit();
  };

  changeGovtId = (controlName: string) => {
    if (controlName === "government_id") this.editGovtId = true;
    else if (controlName === "certification_attachment")
      this.editCertificate = true;
  };

  getPayload = () => {
    let payload = [];
    payload.push(new FieldListItem("signature", this.form.value.signature, ""));
    payload.push(
      new FieldListItem("signature_date", this.form.value.signature_date, "")
    );
    if (this.base64Certificate)
      payload.push(
        new FieldListItem(
          "government_id",
          this.govtIdFileName,
          this.base64Certificate
        )
      );
    if (this.base64AuthorityCertificate)
      payload.push(
        new FieldListItem(
          "certification_attachment",
          this.authorityCertificateFileName,
          this.base64AuthorityCertificate
        )
      );
    return payload;
  };

  handleUpload(event, docType: string) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    if (docType === "government_id") {
      let base64String = "";
      reader.onload = () => {
        base64String = reader.result.toString().split(",")[1];
        this.base64Certificate = base64String;
        this.govtIdFileName = file.name;
      };
    } else if (docType === "certification_attachment") {
      let base64String = "";
      reader.onload = () => {
        base64String = reader.result.toString().split(",")[1];
        this.base64AuthorityCertificate = base64String;
        this.authorityCertificateFileName = file.name;
      };
    }
  }

  clearSelectedFile = (controlName: string) => {
    this.form.controls[controlName].setValue("");
    if (controlName === "government_id") {
      this.base64Certificate = "";
      this.govtIdFileName = "";
    } else if (controlName === "certification_attachment") {
      this.base64AuthorityCertificate = "";
      this.authorityCertificateFileName = "";
    }
  };

  isFileSelected = (controlName: string): boolean => {
    if (this.form.value[controlName]) return true;
    else return false;
  };

  openConfirmationPopUp = (): void => {
    const modal = this.modal.open(ApplicationSaveConfirmationComponent, {
      windowClass: "modal-custom",
      centered: true,
    });

    modal.componentInstance.optionSelected.subscribe((isSubmit) => {
      if (isSubmit) this.saveApplication();
      else {
        this.modal.dismissAll();
      }
    });
  };

  saveApplication = () => {
    this.spinner.show();
    let payload = this.getPayload();
    payload.push(new FieldListItem("status", "Submitted", ""));
    let redId = new Where(this.applicationDetails.id);
    let reqBody = new FormActionData(
      "",
      this.projectSpecificData.applicationData.TableId,
      redId,
      payload
    );
    this.ignatiusService.putData(reqBody).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.modal.dismissAll();
        this.router.navigate(["/"]);
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error(
          this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
          this.translate.instant("applications.ERROR")
        );
      }
    );
  };

  isAnyFileFieldMissing = (): boolean => {
    if (this.govtIdUrl === "" || this.certificationAttachmentUrl === "")
      return true;
    else return false;
  };

  isSubmitButtonDisabled = (): boolean => {
    // if (!this.isAnyFileFieldMissing()) return this.form.invalid;
    // else {
    //   return (
    //     this.form.invalid ||
    //     !this.form.value["government_id"] ||
    //     !this.form.value["certification_attachment"]
    //   );
    // }

    if (
      moment(new Date()).tz("America/Regina") >
        moment.utc("2022-06-18 06:00:00").tz("America/Regina") &&
      !this.isChildCareExpansion &&
      !this.isSustainability &&
      !this.isStartupGrant &&
      !this.isBonusGrant &&
      !this.isEnrichmentGrant
    ) {
      return true;

    }
    if (
      this.form.value["signature"] &&
      (this.govtIdUrl || this.form.value["government_id"]) &&
      (this.certificationAttachmentUrl ||
        this.form.value["certification_attachment"])
    ) {
      return false;
    } else return true;
  };

  saveForLater = () => {
    this.spinner.show();
    let payload = this.getPayload();
    let redId = new Where(this.applicationDetails.id);
    let reqBody = new FormActionData(
      "",
      this.projectSpecificData.applicationData.TableId,
      redId,
      payload
    );
    this.ignatiusService.putData(reqBody).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.router.navigate(["/"]);
      },
      (error: any) => {
        this.spinner.hide();
        this.toastr.error(
          this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
          this.translate.instant("applications.ERROR")
        );
      }
    );
  };
}
