import { AppData } from "./app-data";

export class ProjectSpecificData {
  appId: string;
  appData: AppData;
  applicationData: ApplicationData;
  expenseData: ExpenseData;
  facility: FacilityData;
  facilityUsers: FacilityUsers;
  grandTotal: GrandTotal;
}

export class ApplicationData {
  TableId: string;
  ApplicationFinalReportId: string;
  FormulasReportId: string;
  ApplicationsByTypeReportId: string;
  QuickBaseSyncReportId: string;
  AmountByGrantTypeReportId: string;
  RecordFieldId: string;
  ApplicationTypeFieldId: string;
  OperationalGrantAmountFieldId: string;
  QualityInfantPreschoolGrantAmountFieldId: string;
  QualityTotalGrantAmountFieldId: string;
  ExpansionFCHCapacityFieldId: string;
  ExpansionFCHGrantAmountFieldId: string;
  ExpansionInfantCapacityFieldId: string;
  ExpansionInfantGrantAmountFieldId: string;
  ExpansionToddlerCapacityFieldId: string;
  ExpansionToddlerGrantAmountFieldId: string;
  ExpansionSACapacityFieldId: string;
  ExpansionSAGrantAmountFieldId: string;
  ExpansionStaffSalaryCapacityFieldId: string;
  ExpansionSalaryStaffGrantAmountFieldId: string;
  TotalExpansionGrantAmountFieldId: string;
  TotalSubgrantAmountFieldId: string;
  StatusFieldId: string;
  SignatureFieldId: string;
  GovernmentIDFieldId: string;
  RelatedFacilityFieldId: string;
  FacilityNameFieldId: string;
  FacilityPhysicalAddress1FieldId: string;
  UsernameAppFieldId: string;
  UsernameTitleFieldId: string;
  FacilityQualityEnrollmentCountFieldId: string;
  FacilityITPCountFieldId: string;
  FacilitySchoolAgedCountFieldId: string;
  FacilityTypeFieldId: string;
  QualitySchoolAgeGrantAmountFieldId: string;
  FacilityInfantCountFieldId: string;
  SignatureDateFieldId: string;
  FacilityNumberFieldId: string;
  Question2FieldId: string;
  BetterBeginningsBonusQuestionFieldId: string;
}

export class ExpenseData {
  TableId: string;
  ExpensesReportId: string;
  QuickBaseSyncReportId: string;
  RecordFieldId: string;
  DateCreatedFieldId: string;
  DateModifiedFieldId: string;
  CreatedByFieldId: string;
  ModifiedByFieldId: string;
  PersonnelCostsFieldId: string;
  RentMortgageFieldId: string;
  PPESuppliesFieldId: string;
  MentalHealthSupportFieldId: string;
  RelatedApplicationFieldId: string;
  ApplicationRecordIdFieldId: string;
  ExpenditureMarch11FieldId: string;
  FringeBenefitsFieldId: string;
  BonusesFieldId: string;
  SubstitutesFieldId: string;
  MinorRenovationsFieldId: string;
  UtilitiesFieldId: string;
  FacilityMaintenanceFieldId: string;
  InsuranceFieldId: string;
  AnnualAuditFieldId: string;
  OfficeSuppliesFieldId: string;
  FoodSuppliesFieldId: string;
  FurnitureFieldId: string;
  CurriculumResourcesFieldId: string;
  ParentInvolvementFieldId: string;
  TransportationFieldId: string;
  ProfessionalDevelopmentFieldId: string;
  ScreeningsFieldId: string;
  TechnologyFieldId: string;
  SecuritySystemsFieldId: string;
  OtherREFieldId: string;
  NewStaffFieldId: string;
  SPSixFieldId: string;
  FBSixFieldId: string;
  ExplanationBoxFieldId: string;
  ApplicationFacilityNumberFacilityFieldId: string;
  ApplicationTotalSubgrantAmountFieldId: string;
  RemainingGrantFieldId: string;
}

export class FacilityData {
  TableId: string;
  FacilityReportId: string;
  ListAllReportId: string;
  QuickBaseSyncReportId: string;
  RecordFieldId: string;
  FacilityNameFieldId: string;
  PhysicalAddress1FieldId: string;
  PhysicalAddress2FieldId: string;
  PhysicalCityFieldId: string;
  PhysicalStateFieldId: string;
  PhysicalZipcodeFieldId: string;
  InfantCountFieldId: string;
  ToddlerCountFieldId: string;
  PreschoolCountFieldId: string;
  SchoolAgedCountFieldId: string;
  EarlyHeadStartFieldId: string;
  HeadStartFieldId: string;
  ArkansasBetterChanceFieldId: string;
  MedicaidEIDTFieldId: string;
  InfantPreCovidFieldId: string;
  ToddlerPreCovidFieldId: string;
  PreschoolPreCovidFieldId: string;
  SchoolAgedPreCovidFieldId: string;
  LicensedMarch11FieldId: string;
  CurrentStatusFieldId: string;
  NonTraditionalHoursFieldId: string;
  SpecialNeedsFieldId: string;
  FacilityNumberFieldId: string;
  RelatedApplicantFieldId: string;
  FacilityEmailFieldId: string;
  PhoneFieldId: string;
  TaxpayerIDFieldId: string;
  RaceFieldId: string;
  EthnicityFieldId: string;
  GenderFieldId: string;
  MailingAddressFieldId: string;
  MailingAddress2FieldId: string;
  CityFieldId: string;
  StateFieldId: string;
  ZipcodeFieldId: string;
  NameFieldId: string;
  LegalBusinessNameFieldId: string;
  LicenseNumberFieldId: string;
  OperatingBudgetFieldId: string;
  FacilityStatusFieldId: string;
  FacilityTypeFieldId: string;
  VerifiedFieldId: string;
  PPFacilityCapacityFieldId: string;
  PPInfantToddlerFieldId: string;
  PPPreschoolFieldId: string;
  PPSchoolAgeFieldId: string;
  PPLicensedCCFHFieldId: string;
  PPRegisteredCCFHFieldId: string;
  PPOutSchoolFieldId: string;
  RegisteredCCFHFieldId: string;
  LicensedCCFHFieldId: string;
  OutSchoolCountFieldId: string;
  QualityEnrollmentCountFieldId: string;
  ITPCountFieldId: string;
  PhysicalCountyFieldId: string;
  OwnerNameFieldId: string;
  DateOpenedFieldId: string;
  IsOpenFieldId: string;
  HeadStartyesnoFieldId: string;
}

export class FacilityUsers {
  TableId: string;
  FacilityUsersReportId: string;
  QBSyncReportId: string;
  RecordIdFieldId: string;
  DateCreatedFieldId: string;
  DateModifiedFieldId: string;
  CreatedByFieldId: string;
  ModifiedByFieldId: string;
  EmailFieldId: string;
  RelatedFacilityFieldId: string;
  FirstNameFieldId: string;
  LastNameFieldId: string;
  CityFieldId: string;
  ZipcodeFieldId: string;
  PhoneFieldId: string;
  FacilityFacilityNameFieldId: string;
  FacilityFacilityTypeFieldId: string;
  FacilityFacilityNumberFieldId: string;
  FLNameFieldId: string;
  PrimaryUserFieldId: string;
}

export class GrandTotal {
  TableId: string;
  GrandTotalReportId: string;
  GrandTotalTotalFundedFieldId: string;
  GrandTotalMaxFundAmountFieldId: string;
}
