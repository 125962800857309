import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import "moment-timezone";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/services/auth.service";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { InviteUserComponent } from "../invite-user/invite-user.component";

@Component({
  selector: "app-facitiy-card",
  templateUrl: "./facitiy-card.component.html",
  styleUrls: ["./facitiy-card.component.css"],
})
export class FacitiyCardComponent implements OnInit {
  @Input("facilityDetails") facilityDetails = null;
  @Output("onInviteUserSuccess") onInviteUserSuccess = new EventEmitter();
  email: string = "";
  isMaxFunded = false;

  constructor(
    private modal: NgbModal,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router,
    private ignatiusService: IgnatiusService
  ) {}

  ngOnInit() {
    this.email = this.authService.user.getValue().userName.toLowerCase();
    this.isMaxFunded = this.ignatiusService.isMaxFunded;
  }

  inviteUser = (): void => {
    const modal = this.modal.open(InviteUserComponent, {
      windowClass: "modal-custom",
      centered: true,
    });

    modal.componentInstance.facilityId = this.facilityDetails.id;
    modal.componentInstance.onInviteUserSuccess.subscribe(() => {
      this.onInviteUserSuccess.emit();
    });
  };

  isFacilitySaved = (): boolean => {
    if (this.facilityDetails.facility_status === "Saved") {
      return true;
    } else {
      return false;
    }
  };

  getFacilityVerificationLink = (): string => {
    localStorage.setItem(
      "startup_validation",
      this.facilityDetails.startup_validation
    );
    return `/facility-verification/${this.facilityDetails.id}`;
  };

  getFacilityNumberText = (): string => {
    if (this.isFacilitySaved()) {
      return this.facilityDetails.facility_number;
    } else {
      return "";
    }
  };

  getFacilityEmail = (): string => {
    if (this.facilityDetails)
      return this.facilityDetails.facility_email.toLowerCase();
    else return "";
  };

  getApplicationStatus = (applicationType: string): string => {
    if (!this.facilityDetails) return "";
    if (!this.facilityDetails.applications) return "";
    const application = this.facilityDetails.applications.filter(
      (item: any) => {
        return item.application_type == applicationType;
      }
    );

    if (applicationType === "Enrichment Grant" && this.facilityDetails.total_enrichment_grant_amount <= 0) {
      return ("Ineligible");
    }

    if (application[0] && application[0].status) {
      return application[0].status;
    } else {
      return "None";
    }
  };

  isApplicationSaved = (applicationType: string): boolean => {
    const applicationStatus = this.getApplicationStatus(applicationType);
    if (
      applicationStatus === "Submitted" ||
      applicationStatus === "Approved" ||
      applicationStatus === "Denied" ||
      applicationStatus === "Ineligible" ||
      applicationStatus === "Paid"
    )
      return true;
    if (
      moment(new Date()).tz("America/Regina") >
        moment.utc("2022-06-18 06:00:00").tz("America/Regina") &&
      (applicationType === "Quality Improvement" ||
        applicationType === "Operational Payments")
    )
      return true;
    if (this.isMaxFunded) return true;
    else return false;
  };

  getApplicationRoute = (appType: string): string => {
    switch (appType) {
      case "QUALITY_IMPROVEMENT":
        return `facility/${this.facilityDetails.id}/application/report/quality-improvement`;
      case "OPERATIONAL_PAYMENTS":
        return `facility/${this.facilityDetails.id}/application/report/operational-payments`;
      case "SUSTAINABILITY":
        return `facility/${this.facilityDetails.id}/application/report/sustainability`;
      case "CHILD_CARE_EXPANSION":
        return `facility/${this.facilityDetails.id}/application/report/child-care-expansion`;
      case "STARTUP_GRANT":
        return `facility/${this.facilityDetails.id}/application/report/startup-grant`;
      case "BONUS_GRANT":
        return `facility/${this.facilityDetails.id}/application/report/bonus-grant`;
      case "ENRICHMENT_GRANT":
        return `facility/${this.facilityDetails.id}/application/report/enrichment-grant`;
      default:
        return "";
    }
  };

  openApplication = (appType: string): void => {
    this.router.navigate([this.getApplicationRoute(appType)]);
  };

  isStatusPaid = (applicationType: string) => {
    const applicationStatus = this.getApplicationStatus(applicationType);
    return applicationStatus === "Paid";
  };

  getWarrantNumber = (applicationType: string) => {
    if (!this.facilityDetails) return "";
    if (!this.facilityDetails.applications) return "";
    const application = this.facilityDetails.applications.filter(
      (item: any) => {
        return item.application_type == applicationType;
      }
    );

    if (application[0] && application[0].warrant_number) {
      return application[0].warrant_number;
    } else {
      return "None";
    }
  };

  isWithdrawn = (applicationType: string) => {
    const applicationStatus = this.getApplicationStatus(applicationType);
    // alert(applicationType);
    // alert(this.facilityDetails.total_enrichment_grant_amount)
    if (applicationType === "Enrichment Grant"){
      if (this.facilityDetails.total_enrichment_grant_amount != 0) {
        return (false);
      }
      else
      {
        return (true);
      }
    }
    return (
      applicationStatus === "Withdrawn" || applicationStatus === "Ineligible"
    );
  };
}
