import { Routes } from "@angular/router";

import { ApplicationAddComponent } from "./add/add.component";
import { ApplicationReportComponent } from "./report/report.component";
// import { ApplicationEditLandlordComponent } from './edit_landlord/edit_landlord.component';
// import { ApplicationEditRenterComponent } from './edit_renter/edit_renter.component';
import { DashboardResolver } from "src/app/modules/dashboards/dashboard.resolver";
// import { DetermineEligibilityComponent } from './eligibility/determine-eligibility.component';
import { HasUnsavedChangesGuard } from "../../guards/unsaved-changes.guard";
import { HomeComponent } from "../home/home.component";
import { FacilityVerificationComponent } from "../facility-verification/facility-verification.component";
import { FileViewComponent } from "src/app/shared/components/file-view/file-view.component";
// import { HelpDeskComponent } from './communication/help-desk/help-desk.component';
// import { CaseManagerComponent } from './communication/case-manager/case-manager.component';
// import { PendingRentersComponent } from './pending-renters/pending-renters.component';

export const ApplicationRoutes: Routes = [
  {
    path: "facility/:id/application/add/:type",
    component: ApplicationAddComponent,
  },
  {
    path: "facility/:id/application/report/:type",
    component: ApplicationReportComponent,
  },
  {
    path: "facility-verification/:id",
    component: FacilityVerificationComponent,
  },
  {
    path: "",
    component: HomeComponent,
    data: {
      title: "Application",
      subjectTableName: "application-list",
      urls: [{ title: "" }],
    },
  },
  {
    path: "application/:tableId/file-view/:recordId/:fieldId",
    component: FileViewComponent,
  },
];

export const ApplicationComponents: any = [ApplicationAddComponent];
