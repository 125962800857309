import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-application-save-confirmation',
  templateUrl: './application-save-confirmation.component.html',
  styleUrls: ['./application-save-confirmation.component.css']
})
export class ApplicationSaveConfirmationComponent implements OnInit {
  @Output("optionSelected") optionSelected = new EventEmitter();

  constructor(private modal: NgbModal) {}

  ngOnInit() {}

  onOptionSelected = (isSubmit: boolean) => {
    this.optionSelected.emit(isSubmit);
  };

  closeModal = () => {
    this.modal.dismissAll();
  };

}
