<div class="child-count-step">
  <p class="font-24">{{ "facility.CHILD_COUNT" | translate }}</p>
  <p class="font-14 red-text">
    {{ "common.REQUIRED_FIELD_MESSAGE" | translate }}
  </p>
  <form [formGroup]="form">
    <p class="font-18 mt-2 mb-2">
      {{ "facility.CURRENT_ENROLEMENT_AGE" | translate }}
    </p>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.INFANT" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="infant_count"
      />
      <p
        *ngIf="this.form.controls.infant_count.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "facility.INVALID_AGE_TEXT" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.TODDLER" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="toddler_count"
      />
      <p
        *ngIf="this.form.controls.toddler_count.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "facility.INVALID_AGE_TEXT" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.PRESCHOOL" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="preschool_count"
      />
      <p
        *ngIf="this.form.controls.preschool_count.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "facility.INVALID_AGE_TEXT" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.SCHOOL_AGE" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="school_aged_count"
      />
      <p
        *ngIf="this.form.controls.school_aged_count.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "facility.INVALID_AGE_TEXT" | translate }}
      </p>
    </div>
    <hr />
    <p class="font-18 mt-2 mb-2">
      {{ "facility.ALREADY_FUNDED_TEXT" | translate }}
    </p>
    <div class="form-control-div">
      <label class="font-18"
        >{{ "facility.EARLY_HEAD_START" | translate }}*</label
      >
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="early_head_start"
      />
      <p
        *ngIf="this.form.controls.early_head_start.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "common.INVALID" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.HEAD_START" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="head_start"
      />
      <p
        *ngIf="this.form.controls.head_start.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "common.INVALID" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18"
        >{{ "facility.ARKANSAS_BETTER_CHANCE" | translate }}*</label
      >
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="arkansas_better_chance"
      />
      <p
        *ngIf="this.form.controls.arkansas_better_chance.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "common.INVALID" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.MEDICAID" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="medicaid_eidt"
      />
      <p
        *ngIf="this.form.controls.medicaid_eidt.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "common.INVALID" | translate }}
      </p>
    </div>
    <hr />
    <p class="font-18 mt-2 mb-2">
      {{ "facility.PRE_COVID_ENROLEMENT_AGE_TEXT" | translate }}
    </p>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.INFANT" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="infant_precovid"
      />
      <p
        *ngIf="this.form.controls.infant_precovid.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "facility.INVALID_AGE_TEXT" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.TODDLER" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="toddler_precovid"
      />
      <p
        *ngIf="this.form.controls.toddler_precovid.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "facility.INVALID_AGE_TEXT" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.PRESCHOOL" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="preschool_precovid"
      />
      <p
        *ngIf="this.form.controls.preschool_precovid.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "facility.INVALID_AGE_TEXT" | translate }}
      </p>
    </div>
    <div class="form-control-div">
      <label class="font-18">{{ "facility.SCHOOL_AGE" | translate }}*</label>
      <input
        type="text"
        mask="separator.0"
        thousandSeparator=","
        class="w-50 form-control"
        formControlName="school_aged_precovid"
      />
      <p
        *ngIf="this.form.controls.school_aged_precovid.invalid"
        class="mt-1 red-text font-14"
      >
        {{ "facility.INVALID_AGE_TEXT" | translate }}
      </p>
    </div>
  </form>
  <p *ngIf="countErrorMessage" class="red-text">{{ countErrorMessage }}</p>
  <!-- <span class="button-div mt-2">
    <button
      *ngIf="!isFacilitySaved()"
      class="btn btn-success"
      (click)="openConfirmationPopUp()"
      [disabled]="form.invalid"
    >
      {{ "applications.SAVE" | translate }}
    </button>
    <button *ngIf="isFacilitySaved()" class="btn btn-success" routerLink="/">
      {{ "actions.OK" | translate }}
    </button>
    <p
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3"
      (click)="goBack()"
    >
      <u>{{ "facility.GO_BACK" | translate }}</u>
    </p>
  </span> -->
</div>
