import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { ProjectSpecificService } from "src/app/services/project-specific.service";

@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.css"],
})
export class VerificationComponent implements OnInit, OnChanges {
  @Input("facilityDetails") facilityDetails = null;
  form: FormGroup = this.fb.group({
    FacilityNumber: ["", Validators.required],
  });
  notMatched: boolean = false;
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();
  startup_validation: any = null;
  @Output() nextStep = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private projectSpecificService: ProjectSpecificService
  ) {}

  ngOnInit() {
    this.startup_validation = localStorage.getItem("startup_validation");
  }

  checkFacilityNumber = (): void => {
    if (
      this.form.value.FacilityNumber != this.facilityDetails.facility_number
    ) {
      this.notMatched = true;
    } else {
      this.nextStep.emit();
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    this.facilityDetails = changes.facilityDetails.currentValue;
  }
}
