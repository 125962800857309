<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<div class="d-flex top-header">
  <div
    class="float-center d-flex align-items-center m-0 h-100 pl-5 pr-5 pt-3 pb-3 logo-div"
    style="list-style-type: none"
  >
    <a routerLink="/">
      <img
        src="/assets/images/AR-DEPT-HUMAN-SERVICES_COLOR_RGB 1.png"
        style="width: 140px"
        class="d-inline-block align-top"
        alt=""
      />
    </a>
  </div>
  <div class="main-title d-flex align-items-center">
    <div class="ml-4 mt-5">
        {{ "applications.TITLE_2" | translate }}
      </div>
  </div>

  <!-- <div class="col-md-6">
        <ul class="float-center mr-auto" style="list-style-type: none">
            <li class="d-md-block">
                <div class="text-center text-dark header-text">
                    <br><h3>{{ 'applications.TITLE_1' | translate }}<br>{{ 'applications.TITLE_2' | translate }}</h3>
                </div>
            </li>
        </ul>
    </div> -->
</div>
<div
  class="d-flex justify-content-between p-1 align-items-center"
  style="border-top: 1px solid #b4b4b4ba"
>
  <div>
    <div
      class="nav-item dropdown d-inline-block"
      style="height: 40px"
      ngbDropdown
    >
      <a
        ngbDropdownToggle
        class="
          nav-link
          text-dark
          waves-effect waves-dark
          pro-pic
          dropdown
          p-0
          pl-2
          mr-2
        "
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i style="font-size: 2em" class="mdi mdi-account-circle"></i>
        <!-- <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31"> -->
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <div
          class="
            d-flex
            no-block
            align-items-center
            p-15
            bg-white
            text-muted
            m-b-10
          "
        >
          <div class="m-l-10">
            <p class="m-b-0">{{ username }}</p>
          </div>
        </div>
        <!-- <div class="dropdown-divider"></div>
                <a (click)="openModal(modalcontent)"
                    class="dropdown-item">
                    <i class="ti-lock m-r-5 m-l-5"></i> Change Password</a> -->
        <div class="dropdown-divider"></div>
        <a (click)="logout()" class="dropdown-item" href="javascript: void(0);">
          <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a
        >
      </div>
    </div>
    <a
      class="btn"
      [routerLinkActive]="['active']"
      [routerLink]="['/']"
      >{{ "applications.FACILITIES" | translate }}</a
    >
    <!-- <a
      class="btn"
      [routerLinkActive]="['active']"
      [routerLink]="['/application', 'add']"
      >{{ "applications.CREATE_APPLICATION" | translate }}</a
    > -->
    <!-- <app-create-ticket [template]="addTicket"></app-create-ticket> -->
    <!-- <a class="btn" [routerLinkActive]="['active']" [routerLink]="['/application', 'communication', 'help-desk']">{{"applications.COMMUNICATIONS" | translate}}</a> -->
    <!-- <ng-container *ngIf="applicantType === 'landlord'">
            <a class="btn" [routerLinkActive]="['active']" [routerLink]="['/application', 'pending-renters']">{{"applications.PENDING_RENTERS" | translate}}</a>
            <a class="btn" (click)="openInviteRenter(inviteRenter)">{{"applications.INVITE_RENTER" | translate}}</a>
        </ng-container> -->
  </div>
  <div class="d-flex align-items-center">
    <!-- <app-info-pop
      class="mr-2"
      [classes]="'fa fa-info-circle nav-info-icon'"
      [visible]="true"
      [template]="info"
    ></app-info-pop> -->
    <select
      class="form-control form-control-sm"
      style="
        width: 120px;
        padding-left: 12px;
        background: #0d3e92;
        color: white;
        font-weight: 500;
        border-radius: 3px;
        border: none;
      "
      (change)="changeLanguage($event.target.value)"
    >
      <option value="en">English</option>
      <option value="es">Español</option>
    </select>
  </div>
</div>
<ng-template #modalcontent let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title">Change Password</h4>
  </div>
  <div class="modal-body" (keydown)="keyDownSubmitPassword($event)">
    <div *ngIf="modalMessage" class="alert alert-danger">
      {{ modalMessage }}
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group row">
          <div class="col-12">
            <input
              [(ngModel)]="currentPassword"
              class="form-control form-control-md"
              type="password"
              required=""
              placeholder="Current Password"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <input
              [(ngModel)]="newPassword"
              class="form-control form-control-md"
              type="password"
              required=""
              placeholder="Password"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-12">
            <input
              [(ngModel)]="confirmPassword"
              class="form-control form-control-md"
              type="password"
              required=""
              placeholder="Confirm Password"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      [disabled]="!enableSavePassword()"
      type="submit"
      class="btn btn-success1 float-left"
      (click)="submitPassword()"
    >
      <i class="fa fa-check"></i>
      Save
    </button>
    <button type="button" class="btn btn-dark" (click)="modal.close()">
      Cancel
    </button>
  </div>
</ng-template>

<!-- <ng-template #info>
  <div class="text-left">
    <label
      ><b>{{ "applications.NEED_HELP" | translate }}</b></label
    >
    <p>{{ "applications.NEED_HELP_TEXT" | translate }}</p>
  </div>
</ng-template> -->

<ng-template #addTicket>
  <a class="btn">{{ "applications.CREATE_TICKET" | translate }}</a>
</ng-template>

<!------------------------ Invite Renter start ------------------------------------>

<ng-template #inviteRenter let-modal>
  <div class="modal-header bg-light">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "applications.INVITE_RENTER" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <form
      [formGroup]="inviteRenterFormGroup"
      (ngSubmit)="onInviteRenterSubmit()"
    >
      <div class="row col-md-12 mb-2">
        <div class="col-md-4">
          <label class="control-label">
            {{ "applications.APPLICATION" | translate }}
          </label>
        </div>
        <div class="col-md-7">
          <select class="form-control" formControlName="related_applications">
            <option value="" disabled>
              - {{ "applications.SELECT" | translate }} -
            </option>
            <option *ngFor="let app of applicationData" [value]="app.id">
              {{ app.contact_last_name }}, {{ app.contact_first_name }}
              {{ app.contact_mi }}
            </option>
          </select>
          <small
            *ngIf="
              inviteRenterFormControl.renter_email.touched &&
              inviteRenterFormControl.renter_email.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="
              inviteRenterFormControl.renter_email.touched &&
              inviteRenterFormControl.renter_email.errors?.pattern
            "
            class="text-danger"
          >
            {{ "errors.INVALID_EMAIL" | translate }}
          </small>
        </div>
      </div>

      <div class="row col-md-12">
        <div class="col-md-4">
          <label class="control-label">
            {{ "applications.RENTER_EMAIL_ADDRESS" | translate }}
          </label>
        </div>
        <div class="col-md-7">
          <input
            type="text"
            class="form-control"
            formControlName="renter_email"
          />
          <small
            *ngIf="
              inviteRenterFormControl.renter_email.touched &&
              inviteRenterFormControl.renter_email.errors?.required
            "
            class="text-danger"
          >
            {{ "errors.REQUIRED" | translate }}
          </small>
          <small
            *ngIf="
              inviteRenterFormControl.renter_email.touched &&
              inviteRenterFormControl.renter_email.errors?.pattern
            "
            class="text-danger"
          >
            {{ "errors.INVALID_EMAIL" | translate }}
          </small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <button
            [disabled]="inviteRenterFormGroup.invalid"
            *ngIf="!modelConfig.busy"
            type="submit"
            class="btn background-third-whitetext"
          >
            {{ "applications.INVITE" | translate }}
          </button>
          <button
            *ngIf="modelConfig.busy"
            type="button"
            class="btn background-third-whitetext"
          >
            <strong
              ><i class="fa fa-cog fa-spin"></i>
              {{ "applications.PLEASE_WAIT" | translate }}</strong
            >
          </button>
          <button type="button" (click)="closeModel()" class="btn btn-dark">
            {{ "applications.CANCEL" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!------------------------ Invite Renter end ------------------------------------>
