import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    id: 1,
    path: '',
    title: 'Application',
    icon: 'fa fa-folder',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  }
];
