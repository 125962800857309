<p *ngIf="showErrorMessage" class="text-danger">
  {{ "applications.APP_OWNER_MUST_SIGN" | translate }}
</p>
<div class="child-count-step">
  <p class="font-24">{{ "applications.CERTIFICATIONS" | translate }}</p>
  <p class="font-14">
    {{ "certification.LONG_TEXT_1" | translate }}
  </p>
  <p class="font-14">
    {{ "certification.LONG_TEXT_2" | translate }}
  </p>
  <p class="font-14">
    {{ "certification.LONG_TEXT_3" | translate }}
  </p>
  <p class="font-14">
    {{ "certification.LONG_TEXT_4" | translate }}
  </p>
  <p class="font-14">
    {{ "certification.SUBHEADING_1" | translate }}
  </p>
  <p class="font-14">
    {{ "certification.SUBHEADING_2" | translate }}
  </p>
  <p class="font-14">
    {{ "certification.SUBHEADING_3" | translate }}
  </p>
  <p class="font-14">
    {{ "certification.SUBHEADING_4" | translate }}
  </p>
  <p class="font-14" *ngIf="isChildCareExpansion">
    {{ "certification.SUBHEADING_5" | translate }}
  </p>
  <p class="font-14">
    {{ "certification.NOTE" | translate }}
  </p>

  <form [formGroup]="form">
    <div class="row col-12 justify-content-between">
      <div class="col-4 mr-3 form-control-div">
        <label class="font-18"
          >{{ "certification.TYPE_YOUR_NAME" | translate }}*</label
        >
        <input type="text" class="form-control" formControlName="signature" />
      </div>
      <div class="col-4 form-control-div">
        <label class="font-18">{{ "common.DATE" | translate }}*</label>
        <input
          type="text"
          class="form-control"
          formControlName="signature_date"
          readonly
        />
      </div>
    </div>
    <div *ngIf="!govtIdUrl || editGovtId" class="form-control-div">
      <label class="font-18"
        >{{ "certification.ATTACH_GOVT_ID" | translate }}*</label
      >

      <div class="row">
        <input
          type="file"
          class="col-4"
          formControlName="government_id"
          (change)="handleUpload($event, 'government_id')"
        />
        <i
          *ngIf="!isApplicationSubmitted()"
          class="fa fa-times ml-2"
          (click)="clearSelectedFile('government_id')"
        ></i>
      </div>
    </div>
    <div class="row">
      <p>
        <span class="font-14">{{
          "certification.RECOMMENDED_GID" | translate
        }}</span
        ><span class="font-14">{{
          "certification.RECOMMENDED_GID_SAMPLE" | translate
        }}</span>
      </p>
    </div>
    <div *ngIf="govtIdUrl" class="row col-12">
      <label class="font-18 col-12"
        >{{ "certification.GOVT_ID" | translate }}*</label
      >
      <a
        class="col-6"
        [href]="ignatiusService.getFileRouteLink(govtIdUrl)"
        target="_blank"
        >{{ govtIdName }}</a
      >
      <i
        *ngIf="!isApplicationSubmitted()"
        class="fa fa-edit"
        (click)="changeGovtId('government_id')"
      ></i>
    </div>
    <div class="form-control-div mt-3">
      <div class="d-flex">
        <p>
          <b style="font-weight: 800; color: rgb(12, 11, 11)">{{
            "certification.ADDITIONAL_CERTIFICATE_TEXT" | translate
          }}</b>
          <span class="ml-2">{{ "actions.DOWNLOAD_FORM" | translate }}</span>
          <a
            class="ml-2"
            target="_blank"
            href="https://d3dr6irtq668i9.cloudfront.net/templates/ARPA+Certificate+of+Authority+2021.pdf"
            ><i class="fa fa-download"></i
          ></a>
        </p>
      </div>
    </div>
    <div class="row">
      <p>
        <span class="font-14">{{
          "certification.RECOMMENDED_COA" | translate
        }}</span>
        <span class="font-14">{{
          "certification.RECOMMENDED_COA_SAMPLE" | translate
        }}</span>
      </p>
    </div>
    <div
      *ngIf="!certificationAttachmentUrl || editCertificate"
      class="form-control-div"
    >
      <label class="font-18"
        >{{ "certification.ATTACH_CERT_AUTHORITY" | translate }}*</label
      >
      <div class="row">
        <input
          type="file"
          class="col-4"
          formControlName="certification_attachment"
          (change)="handleUpload($event, 'certification_attachment')"
        />
        <i
          *ngIf="!isApplicationSubmitted()"
          class="fa fa-times"
          (click)="clearSelectedFile('certification_attachment')"
        ></i>
      </div>
    </div>
    <div *ngIf="certificationAttachmentUrl" class="row col-12">
      <label class="font-18 col-12">{{
        "certification.CERT_AUTHORITY" | translate
      }}</label>
      <a
        class="col-6"
        [href]="ignatiusService.getFileRouteLink(certificationAttachmentUrl)"
        target="_blank"
        >{{ certificateName }}</a
      >
      <i
        *ngIf="!isApplicationSubmitted()"
        class="fa fa-edit"
        (click)="changeGovtId('certification_attachment')"
      ></i>
    </div>
  </form>
  <!-- <span *ngIf="isApplicationSubmitted()">
    <button class="btn btn-success" routerLink="/">
      {{ "actions.OK" | translate }}
    </button>
  </span> -->
  <span *ngIf="!isApplicationSubmitted()" class="button-div mt-2 no-print">
    <button
      class="btn btn-success"
      [disabled]="isSubmitButtonDisabled()"
      (click)="openConfirmationPopUp()"
    >
      {{ "common.SUBMIT_APPLICATION" | translate }}
    </button>
    <button
      style="color: rgb(19, 96, 197); cursor: pointer"
      class="ml-3 mt-3 btn"
      (click)="saveForLater()"
    >
      <u>{{ "common.SAVE_FOR_LATER" | translate }}</u>
    </button>
  </span>
</div>
