export const qualityFundRows = [
  {
    title: "budget.New_STAFF_BONUS",
    formControlName: "new_staff",
  },
  {
    title: "budget.SUBSTITUTES",
    formControlName: "substitutes",
  },
  {
    title: "budget.MINOR_RENOVATIONS",
    formControlName: "minor_renovations",
  },
  {
    title: "budget.FURNITURE_AND_EQUIPMENT",
    formControlName: "furniture",
    info: "applications.furniture_info",
  },
  {
    title: "budget.CURRICULUM_RESOURCES_SUPPLIES",
    formControlName: "curriculum_resources",
    info: "applications.curriculum_resources_info",
  },
  {
    title: "budget.PARENT_INVOLVEMENT",
    formControlName: "parent_involvement",
  },
  {
    title: "budget.PROFESSIONAL_DEVELOPMENT",
    formControlName: "professional_development",
  },
  {
    title: "budget.SCREENINGS_ASSESSMENT",
    formControlName: "screenings",
    info: "applications.screenings_info",
  },
  {
    title: "budget.TECHNOLOGY",
    formControlName: "technology",
    info: "applications.technology_info",
  },
  {
    title: "budget.HEALTH_SAFETY_ITEMS_PPE",
    formControlName: "ppe_supplies",
    info: "applications.ppe_supplies_info",
  },
  {
    title: "budget.NEW_SECURITY_SYSTEMS",
    formControlName: "security_systems",
    info: "applications.security_systems_info",
  },
  {
    title: "budget.MENTAL_HEALTH_SERVICES",
    formControlName: "mental_health_support",
  },
  {
    title: "budget.OTHER",
    formControlName: "other_re",
  },
];

export const operationalFundRows = [
  {
    title: "budget.SALARIES_PERSONNEL",
    formControlName: "personnel_costs",
  },
  {
    title: "budget.FRINGE_BENEFITS",
    formControlName: "fringe_benefits",
  },
  {
    title: "budget.BONUSES_DURING_COVID",
    formControlName: "bonuses",
  },
  {
    title: "budget.SUBSTITUTES",
    formControlName: "substitutes",
  },
  {
    title: "budget.MINOR_RENOVATIONS",
    formControlName: "minor_renovations",
  },
  {
    title: "budget.RENT_OR_MORTGAGE",
    formControlName: "rent_mortgage",
  },
  {
    title: "budget.UTILITIES_TELEPHONE_INTERNET",
    formControlName: "utilities",
  },
  {
    title: "budget.FACILITY_MAINTENANCE_REPAIR_IMPROVEMENTS",
    formControlName: "facility_maintenance",
    info: "applications.facility_maintenance_info",
  },
  {
    title: "budget.INSURANCE",
    formControlName: "insurance",
  },
  {
    title: "budget.ANNUAL_AUDIT",
    formControlName: "annual_audit",
    info: "applications.annual_audit_info",
  },
  {
    title: "budget.OFFICE_SUPPLIES",
    formControlName: "office_supplies",
  },
  {
    title: "budget.FOOD_SERVICE_SUPPLIES",
    formControlName: "food_supplies",
    info: "applications.food_supplies_info",
  },
  {
    title: "budget.FURNITURE_AND_EQUIPMENT",
    formControlName: "furniture",
    info: "applications.furniture_info",
  },
  {
    title: "budget.CURRICULUM_RESOURCES_SUPPLIES",
    formControlName: "curriculum_resources",
    info: "applications.curriculum_resources_info",
  },
  {
    title: "budget.PARENT_INVOLVEMENT",
    formControlName: "parent_involvement",
  },
  {
    title: "budget.TRANSPORTATION",
    formControlName: "transportation",
    info: "applications.transportation_info",
  },
  {
    title: "budget.PROFESSIONAL_DEVELOPMENT",
    formControlName: "professional_development",
  },
  {
    title: "budget.SCREENINGS_ASSESSMENT",
    formControlName: "screenings",
    info: "applications.screenings_info",
  },
  {
    title: "budget.TECHNOLOGY",
    formControlName: "technology",
    info: "applications.technology_info",
  },
  {
    title: "budget.HEALTH_SAFETY_ITEMS_PPE",
    formControlName: "ppe_supplies",
    info: "applications.ppe_supplies_info",
  },
  {
    title: "budget.NEW_SECURITY_SYSTEMS",
    formControlName: "security_systems",
    info: "applications.security_systems_info",
  },
  {
    title: "budget.MENTAL_HEALTH_SERVICES",
    formControlName: "mental_health_support",
  },
  {
    title: "budget.OTHER",
    formControlName: "other_re",
  },
];

export const sustainabilityFundRows = [
  {
    title: "budget.SALARIES_PERSONNEL",
    formControlName: "personnel_costs",
  },
  {
    title: "budget.FRINGE_BENEFITS",
    formControlName: "fringe_benefits",
  },
  {
    title: "budget.BONUSES",
    formControlName: "bonuses",
  },
  {
    title: "budget.SUBSTITUTES",
    formControlName: "substitutes",
  },
  {
    title: "budget.MINOR_RENOVATIONS",
    formControlName: "minor_renovations",
  },
  {
    title: "budget.RENT_OR_MORTGAGE",
    formControlName: "rent_mortgage",
  },
  {
    title: "budget.UTILITIES_TELEPHONE_INTERNET",
    formControlName: "utilities",
  },
  {
    title: "budget.FACILITY_MAINTENANCE_REPAIR_IMPROVEMENTS",
    formControlName: "facility_maintenance",
    info: "applications.facility_maintenance_info",
  },
  {
    title: "budget.INSURANCE",
    formControlName: "insurance",
  },
  {
    title: "budget.ANNUAL_AUDIT",
    formControlName: "annual_audit",
    info: "applications.annual_audit_info",
  },
  {
    title: "budget.OFFICE_SUPPLIES",
    formControlName: "office_supplies",
  },
  {
    title: "budget.FOOD_SERVICE_SUPPLIES",
    formControlName: "food_supplies",
    info: "applications.food_supplies_info",
  },
  {
    title: "budget.FURNITURE_AND_EQUIPMENT",
    formControlName: "furniture",
    info: "applications.furniture_info",
  },
  {
    title: "budget.CURRICULUM_RESOURCES_SUPPLIES",
    formControlName: "curriculum_resources",
    info: "applications.curriculum_resources_info",
  },
  {
    title: "budget.PARENT_INVOLVEMENT",
    formControlName: "parent_involvement",
  },
  {
    title: "budget.TRANSPORTATION",
    formControlName: "transportation",
    info: "applications.transportation_info",
  },
  {
    title: "budget.PROFESSIONAL_DEVELOPMENT",
    formControlName: "professional_development",
  },
  {
    title: "budget.SCREENINGS_ASSESSMENT",
    formControlName: "screenings",
    info: "applications.screenings_info",
  },
  {
    title: "budget.TECHNOLOGY",
    formControlName: "technology",
    info: "applications.technology_info",
  },
  {
    title: "budget.HEALTH_SAFETY_ITEMS_PPE",
    formControlName: "ppe_supplies",
    info: "applications.ppe_supplies_info",
  },
  {
    title: "budget.NEW_SECURITY_SYSTEMS",
    formControlName: "security_systems",
    info: "applications.security_systems_info",
  },
  {
    title: "budget.MENTAL_HEALTH_SERVICES",
    formControlName: "mental_health_support",
  },
  {
    title: "budget.OTHER",
    formControlName: "other_re",
  },
];

export const expansionFundRows = [
  {
    title: "budget.SALARIES_PERSONNEL",
    formControlName: "sp_six",
  },
  {
    title: "budget.FRINGE_BENEFITS",
    formControlName: "fb_six",
  },
  ...operationalFundRows.slice(4),
];
