<div class="error-box">
  <div class="error-body text-center">
    <span style="font-size: 100px;font-family: cursive;"
      class="text-danger d-block">UNAUTHORIZED</span>
    <div><i class="fa fa-lock fa-8x text-danger"></i></div>
    <br>
    <h3 class="error-subtitle">You are not authorized to view this page.</h3>
    <br>
    <a [routerLink]="'/dashboard'"
      class="btn btn-danger btn-rounded waves-effect waves-light m-b-40">Back to home</a>
  </div>
</div>