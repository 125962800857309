<i
  *ngIf="visible"
  [class]="classes"
  data-toggle="tooltip"
  [title]="message | translate"
  aria-hidden="true"
>
</i>
<ng-template #popup>
  <ng-container *ngTemplateOutlet="template"></ng-container>
  <button class="btn btn-dark modal-btn" (click)="close()">{{'applications.CLOSE' | translate}}</button>
</ng-template>