import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { race } from "rxjs-compat/operator/race";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";

@Component({
  selector: "app-facility-information",
  templateUrl: "./facility-information.component.html",
  styleUrls: ["./facility-information.component.css"],
})
export class FacilityInformationComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  form: FormGroup = this.fb.group({
    race: ["", Validators.required],
    ethnicity: ["", Validators.required],
    gender: ["", Validators.required],
    current_status: ["", Validators.required],
    special_needs: ["", Validators.required],
    operating_budget: ["", [Validators.required, Validators.min(0)]],
  });

  raceOptions: Array<any> = [];
  ethnicityOptions: Array<any> = [];
  genderOptions: Array<any> = [];
  currentStatusOptions: Array<any> = [];
  specialNeedsOptions: Array<any> = [];
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();
  @Input("facilityDetails") facilityDetails: any = {};
  maxBudgetError = "";

  constructor(
    private fb: FormBuilder,
    private ignatiusService: IgnatiusService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private projectSpecificService: ProjectSpecificService
  ) {}

  ngOnInit() {
    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.facility.RaceFieldId.toString()
      )
      .subscribe((res: any) => {
        this.raceOptions = res;
      });

    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.facility.EthnicityFieldId.toString()
      )
      .subscribe((res: any) => {
        this.ethnicityOptions = res;
      });

    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.facility.GenderFieldId.toString()
      )
      .subscribe((res: any) => {
        this.genderOptions = res;
      });

    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.facility.CurrentStatusFieldId.toString()
      )
      .subscribe((res: any) => {
        this.currentStatusOptions = res;
      });

    this.ignatiusService
      .getDropdownValues(
        this.projectSpecificData.facility.SpecialNeedsFieldId.toString()
      )
      .subscribe((res: any) => {
        this.specialNeedsOptions = res;
      });
  }

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  goBack = () => {
    this.back.emit();
  };

  async saveAndNext() {
    if (this.isFacilitySaved()) {
      this.goToNextStep();
    } else {
      this.spinner.show();
      let payload = this.getPayload();
      let redId = new Where(this.facilityDetails.id);
      let reqBody = new FormActionData(
        "",
        this.projectSpecificData.facility.TableId,
        redId,
        payload
      );
      this.ignatiusService.putData(reqBody).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.goToNextStep();
        },
        (error: any) => {
          this.spinner.hide();
          this.toastr.error(
            this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
            this.translate.instant("applications.ERROR")
          );
        }
      );
    }
  }

  goToNextStep = () => {
    alert(this.next)
    this.next.emit();
  };

  getPayload = () => {
    let formData = { ...this.form.value };
    let payload = [];

    for (let i in formData) {
      payload.push(new FieldListItem(i, formData[i], ""));
    }
    return payload;
  };

  disableInputField = () => {
    this.form.controls["operating_budget"].disable();
  };

  ngOnChanges(changes: SimpleChanges) {
    this.facilityDetails = changes.facilityDetails.currentValue;
    if (this.facilityDetails) {
      this.form.setValue({
        race: this.facilityDetails.race,
        ethnicity: this.facilityDetails.ethnicity,
        gender: this.facilityDetails.gender,
        current_status: this.facilityDetails.current_status,
        special_needs: this.facilityDetails.special_needs,
        operating_budget: this.facilityDetails.operating_budget,
      });

      if (this.isFacilitySaved()) {
        this.disableInputField();
      }
    }
  }

  isAnnualOperatingBudgetDecimalValid = () => {
    const currentOperatingBudget = String(this.form.value["operating_budget"]);
    const valueArray = currentOperatingBudget.split(".");
    if (valueArray.length === 1) {
      return true;
    }
    if (valueArray.length === 2) {
      if (valueArray[1].length < 3) return true;
      else return false;
    } else {
      return false;
    }
  };
  maxOperatingBudget = () => {
    let budget = Number(this.form.value["operating_budget"]);
    if (budget > 10000000) {
      this.maxBudgetError =
        "Annual Operating Budget Cannot Exceed $10,000,000.00";
    } else {
      this.maxBudgetError = "";
    }
  };
}
