import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/modules/dashboards/services";
import { IgnatiusService } from "src/app/services/ignatius.service";
import { ProjectSpecificService } from "src/app/services/project-specific.service";
import { Constants } from "src/app/shared/constants";
import { naics } from "./naics";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ProjectSpecificData } from "src/app/models/project-specific-data";
import { Condition } from "selenium-webdriver";
import { AuthService } from "src/app/services/auth.service";

const routeApplicationTypeMapping = {
  qualityimprovement: "Quality Improvement",
  operationalpayments: "Operational Payments",
  sustainability: "Sustainability",
  childcareexpansion: "Child Care Expansion",
  startupgrant: "Startup Grant",
  bonusgrant: "Maintenance Grant",
  enrichmentgrant: "Enrichment Grant"
};
@Component({
  selector: "application-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.css"],
  // encapsulation: ViewEncapsulation.None
})
export class ApplicationAddComponent implements OnInit {
  applicationData = {
    id: 1,
  };
  currentTab: number = 0;
  relatedFacilityId: number = 0;
  applicationType: string = "";
  applicationDetails: Array<any> = [0];
  expenseDetails: Array<any> = [0];
  projectSpecificData: ProjectSpecificData =
    this.projectSpecificService.getProjectSpecificData();
  facilityDetails: any = {};
  failityUserDetails: any = [];

  tabChange(event) {
    // console.log("tabChange: event: ", event);
  }

  constructor(
    private projectSpecificService: ProjectSpecificService,
    private ignatiusService: IgnatiusService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.route.params.subscribe(async (res: any) => {
      const modifiedType = res.type.replaceAll("-", "").toLocaleLowerCase();
      this.relatedFacilityId = res.id;
      this.applicationType = await routeApplicationTypeMapping[modifiedType];
      this.failityUserDetails = await this.getFacilityUserDetails();
      this.getApplicationDetails();
    });
    await this.getFacilityDetails();
  }

  isPrimaryUser = (): boolean => {
    if (this.failityUserDetails.length) return false;
    if (
      this.failityUserDetails[0] &&
      this.failityUserDetails[0].primary_user === "No"
    )
      return false;
    else return true;
  };

  getFacilityUserDetails = (): any => {
    let payload = {
      ApplicationTableId: this.projectSpecificData.facilityUsers.TableId,
      ConditionGroups: [
        {
          Type: "All",
          Conditions: [
            {
              ConditionField: {
                Key: this.projectSpecificData.facilityUsers
                  .RelatedFacilityFieldId,
              },
              OperationType: "is equal",
              Value: this.relatedFacilityId,
            },
          ],
        },
        {
          Type: "All",
          Conditions: [
            {
              ConditionField: {
                Key: this.projectSpecificData.facilityUsers.EmailFieldId,
              },
              OperationType: "is equal",
              Value: this.authService.user.getValue().userName,
            },
          ],
        },
      ],
    };

    return this.ignatiusService
      .getQueryReportObservable(this.projectSpecificData.appData, payload)
      .toPromise();
  };

  getRequestPayload = (): any => {
    let payload = {
      ApplicationTableId: this.projectSpecificData.applicationData.TableId,
    };

    payload["ConditionGroups"] = [
      {
        Type: "All",
        Conditions: [
          {
            ConditionField: {
              Key: this.projectSpecificData.applicationData
                .ApplicationTypeFieldId,
            },
            OperationType: "is equal",
            Value: this.applicationType,
          },
        ],
      },
      {
        Type: "All",
        Conditions: [
          {
            ConditionField: {
              Key: this.projectSpecificData.applicationData
                .RelatedFacilityFieldId,
            },
            OperationType: "is equal",
            Value: this.relatedFacilityId,
          },
        ],
      },
    ];
    return payload;
  };

  getExpenseReqPayload = (appId: number) => {
    let payload = {
      ApplicationTableId: this.projectSpecificData.expenseData.TableId,
    };

    payload["ConditionGroups"] = [
      {
        Type: "All",
        Conditions: [
          {
            ConditionField: {
              Key: this.projectSpecificData.expenseData.RelatedApplicationFieldId.toString(),
            },
            OperationType: "is equal",
            Value: appId,
          },
        ],
      },
    ];
    return payload;
  };
  getApplicationDetails = async () => {
    try {
      this.spinner.show();
      let requestPayload = this.getRequestPayload();
      this.applicationDetails = await this.ignatiusService
        .getQueryReportObservable(
          this.projectSpecificData.appData,
          requestPayload
        )
        .toPromise();
      if (this.applicationDetails[0] && this.applicationDetails[0].id) {
        let expenseReqPayload = await this.getExpenseReqPayload(
          this.applicationDetails[0].id
        );
        this.expenseDetails = await this.ignatiusService
          .getQueryReportObservable(
            this.projectSpecificData.appData,
            expenseReqPayload
          )
          .toPromise();
      }
      this.spinner.hide();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
  };

  getFacilityDetails = async () => {
    try {
      this.spinner.show();
      let requestPayload = {
        ApplicationTableId: this.projectSpecificData.facility.TableId,
        ConditionGroups: [
          {
            Type: "All",
            Conditions: [
              {
                ConditionField: {
                  Key: this.projectSpecificData.facility.RecordFieldId,
                },
                OperationType: "is equal",
                Value: this.relatedFacilityId,
              },
            ],
          },
        ],
      };
      this.facilityDetails = await this.ignatiusService
        .getQueryReportObservable(
          this.projectSpecificData.appData,
          requestPayload
        )
        .toPromise();

      this.spinner.hide();
    } catch (error) {
      this.toastr.error(
        this.translate.instant("MESSAGES.APPLICATION_FETCH_ERROR"),
        this.translate.instant("applications.ERROR")
      );
      this.spinner.hide();
    }
  };

  nextStep = (reload = false) => {
    if (reload) {
      this.getApplicationDetails();
    }
    this.currentTab = this.currentTab + 1;
    window.scroll(0, 0);
  };

  goToPreviousStep = () => {
    this.currentTab = this.currentTab - 1;
    window.scroll(0, 0);
  };
}
