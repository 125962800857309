//node modules
import { Routes } from '@angular/router';
import { AdminGuard } from 'src/app/modules/dashboards/guards'

//local
import { DashboardResolver } from 'src/app/modules/dashboards/dashboard.resolver';

import {
  UnauthorisedComponent,
  UserRoutes,
  ApplicationRoutes
} from 'src/app/modules/dashboards/components';


import { DashboardComponent } from './dashboard.component';

export const DashboardRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    data: {
      title: 'Funding Overview',
      subjectTableName: 'dashboard',
      urls: [
        { title: '' }
      ]
    },
    children: [

      // ================ Defined routes on component level ===============//
      ...ApplicationRoutes,
      ...UserRoutes,

      // ================ Defined routes on component level ===============//
      {
        path: 'unauthorised',
        component: UnauthorisedComponent,
      }

    ]
  }
]
