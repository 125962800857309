import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  FieldListItem,
  FormActionData,
  Where,
} from "src/app/models/form-action-data";
import { IgnatiusService } from "src/app/services/ignatius.service";
@Component({
  selector: "app-quality-improvement-calculator",
  templateUrl: "./quality-improvement-calculator.component.html",
  styleUrls: ["./quality-improvement-calculator.component.css"],
})
export class GrantCalculatorComponent implements OnInit {
  @Output("back") back = new EventEmitter();
  @Output("next") next = new EventEmitter();
  @Input("facilityDetails") facilityDetails: any = {};
  @Input("applicationDetails") applicationDetails: any = {};

  form: FormGroup = this.fb.group({
    quality_enrollment_count: ["", Validators.required],
    infant_count: ["", Validators.required],
    quality_infant_preschool_grant_amount: ["", Validators.required],
    school_aged_count: ["", Validators.required],
    quality_school_age_grant_amount: ["", Validators.required],
    quality_total_grant_amount: ["", Validators.required],
  });
  constructor(private fb: FormBuilder,private ignatiusService:IgnatiusService) {}

  ngOnInit() {
    // console.log("facilityDetails: ", this.facilityDetails);
  }

  isApplicationSubmitted = (): boolean => {
    if (
      this.applicationDetails &&
      (this.applicationDetails.status === "Submitted" ||
        this.applicationDetails.status === "Approved" ||
        this.applicationDetails.status === "Denied" ||
        this.applicationDetails.status === "Ineligible" ||
        this.applicationDetails.status === "Paid") ||
        this.ignatiusService.isMaxFunded
    )
      return true;
    else return false;
  };

  isFacilitySaved = (): boolean => {
    if (
      this.facilityDetails &&
      this.facilityDetails.facility_status === "Saved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  getInfantToddlerPreSchoolCount = (): number => {
    if (this.facilityDetails) {
      return (
        Number(this.facilityDetails.infant_count) +
        Number(this.facilityDetails.toddler_count) +
        Number(this.facilityDetails.preschool_count)
      );
    } else {
      return 0;
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.facilityDetails && changes.facilityDetails.currentValue)
      this.facilityDetails = changes.facilityDetails.currentValue;
    if (changes.applicationDetails && changes.applicationDetails.currentValue)
      this.applicationDetails = changes.applicationDetails.currentValue;

    if (this.applicationDetails) {
      this.form.setValue({
        quality_enrollment_count:
          this.applicationDetails.quality_enrollment_count,
        infant_count: this.getInfantToddlerPreSchoolCount(),
        quality_infant_preschool_grant_amount:
          this.applicationDetails.quality_infant_preschool_grant_amount,
        school_aged_count: this.applicationDetails.school_aged_count,
        quality_school_age_grant_amount:
          this.applicationDetails.quality_school_age_grant_amount,
        quality_total_grant_amount:
          this.applicationDetails.quality_total_grant_amount,
      });
    }
  }

  goToNextStep = () => {
    this.next.emit();
  };

  goBack = () => {
    this.back.emit();
  };
}
