import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bool' })
export class BoolPipe implements PipeTransform {

  transform(value: any): string {
    if (Boolean(value) && value !== 'False') return 'Yes';
    if (!Boolean(value) || value === 'False') return 'No';
    return '-';
  }

}
