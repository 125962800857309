//node modules
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";

import { CommonModule, CurrencyPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MaterialModule } from "src/app/shared/material.module";
import { CommonService } from "src/app/modules/dashboards/services";

//local
import { DashboardRoutes } from "./dashboard.routing";

import {
  UnauthorisedComponent,
  UserComponents,
  ApplicationComponents,
} from "src/app/modules/dashboards/components";

import { DashboardComponent } from "./dashboard.component";

import { AdminGuard } from "src/app/modules/dashboards/guards";
import { DashboardResolver } from "src/app/modules/dashboards/dashboard.resolver";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { BoolPipe } from "src/app/shared/pipes/bool.pipe";
import { NgCircleProgressModule } from "ng-circle-progress";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { HasUnsavedChangesGuard } from "./guards/unsaved-changes.guard";
import { NumberDirective } from "src/app/modules/dashboards/directives/numbers-only";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import { ConfirmDialogComponent } from "src/app/shared/confirm-dialog/confirm-dialog.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
// import { HelpDeskComponent } from './components/applications/communication/help-desk/help-desk.component';
// import { CaseManagerComponent } from './components/applications/communication/case-manager/case-manager.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { CertificationsComponent } from "./components/applications/certifications/certifications.component";
import { HomeComponent } from "./components/home/home.component";
import { FacitiyCardComponent } from "./components/facitiy-card/facitiy-card.component";
import { InviteUserComponent } from "./components/invite-user/invite-user.component";
import { FacilityVerificationComponent } from "./components/facility-verification/facility-verification.component";
import { VerificationComponent } from "./components/facility-verification-steps/verification/verification.component";
import { FacilityInformationComponent } from "./components/facility-verification-steps/facility-information/facility-information.component";
import { FacilityChildCountComponent } from "./components/facility-verification-steps/facility-child-count/facility-child-count.component";
import { OperationalStatusComponent } from "./components/applications/operational-status/operational-status.component";
import { ApplicationOptionsComponent } from "./components/applications/application-options/application-options.component";
import { GrantCalculatorComponent } from "./components/applications/quality-improvement-calculator/quality-improvement-calculator.component";
import { ProposedBudgetComponent } from "./components/applications/proposed-budget/proposed-budget.component";
import { SaveConfirmationComponent } from "./components/save-confirmation/save-confirmation.component";
import { OperationalPaymentCalculatorComponent } from "./components/applications/operational-payment-calculator/operational-payment-calculator.component";
import { ChildCareExpansionCalculatorComponent } from "./components/applications/child-care-expansion-calculator/child-care-expansion-calculator.component";
import { ApplicationSaveConfirmationComponent } from "./components/applications/application-save-confirmation/application-save-confirmation.component";
import { ProposedComponent } from "./components/applications/proposed-budget/proposed/proposed.component";
import { BugetConfirmationPopupComponent } from "./components/applications/buget-confirmation-popup/buget-confirmation-popup.component";
// import { PendingRentersComponent } from './components/applications/pending-renters/pending-renters.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatExpansionModule } from "@angular/material/expansion";
import { UserEditAddComponent } from "./components/user/edit-add/edit-add.component";
import { UserListComponent } from "./components/user/list/list.component";
import { ApplicationAddComponent } from "./components/applications/add/add.component";
import { ApplicationReportComponent } from "./components/applications/report/report.component";
import { SustainabilityCalculatorComponent } from "./components/applications/sustainability-calculator/sustainability-calculator.component";
import { StartupGrantCalculatorComponent } from './components/applications/startup-grant-calculator/startup-grant-calculator.component';
import { BonusGrantCalculatorComponent } from './components/applications/bonus-grant-calculator/bonus-grant-calculator.component';
import { EnrichmentGrantCalculatorComponent } from './components/applications/enrichment-grant-calculator/enrichment-grant-calculator.component';

@NgModule({
  imports: [
    RouterModule.forChild(DashboardRoutes),
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    NgbModule,
    NgbModalModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MaterialModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    TranslateModule,
    NgCircleProgressModule.forRoot({}),
    NgxMaskModule.forRoot(),
    GooglePlaceModule,
    MatTabsModule,
    MatExpansionModule,
    NgxSpinnerModule,
    MatTooltipModule,
  ],
  declarations: [
    BoolPipe,
    NumberDirective,
    UnauthorisedComponent,
    DashboardComponent,
    ApplicationAddComponent,
    ApplicationReportComponent,
    CertificationsComponent,
    HomeComponent,
    FacitiyCardComponent,
    InviteUserComponent,
    FacilityVerificationComponent,
    VerificationComponent,
    FacilityInformationComponent,
    FacilityChildCountComponent,
    OperationalStatusComponent,
    ApplicationOptionsComponent,
    GrantCalculatorComponent,
    ProposedBudgetComponent,
    SaveConfirmationComponent,
    OperationalPaymentCalculatorComponent,
    ChildCareExpansionCalculatorComponent,
    ApplicationSaveConfirmationComponent,
    ProposedComponent,
    BugetConfirmationPopupComponent,
    ConfirmDialogComponent,
    InviteUserComponent,
    SaveConfirmationComponent,
    ApplicationSaveConfirmationComponent,
    BugetConfirmationPopupComponent,
    UserListComponent,
    UserEditAddComponent,
    SustainabilityCalculatorComponent,
    StartupGrantCalculatorComponent,
    BonusGrantCalculatorComponent,
    EnrichmentGrantCalculatorComponent,
    // HelpDeskComponent,
    // CaseManagerComponent,
    // PendingRentersComponent,
  ],
  providers: [
    DashboardResolver,
    CurrencyPipe,
    CommonService,
    AdminGuard,
    HasUnsavedChangesGuard,
  ],
})
export class DashboardModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
