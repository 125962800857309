<div class="home">
  <div class="facilities">
    <p class="font-24">{{ "facility.YOUR_FACILITY" | translate }}</p>
  </div>
  <div class="facilities">
    <div *ngFor="let facility of facilityData">
      <app-facitiy-card
        (onInviteUserSuccess)="getAuthorizedUserList(facilityData)"
        [facilityDetails]="facility"
      ></app-facitiy-card>
    </div>
  </div>
</div>
